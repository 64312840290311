import React from 'react';
import clsx from 'clsx';
import { Replica } from 'entities/tavus/replica';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { cn } from 'shared/lib';

type AvatarItemProps = {
  replica: Replica;
  onChooseReplica: (replica: Replica) => void;
  isActive?: boolean;
};

export const AvatarListItem: React.FC<AvatarItemProps> = ({
  replica,
  onChooseReplica,
  isActive
}) => {
  const handleChooseAvatar = () => {
    onChooseReplica(replica);
  };
  return (
    <div
      onClick={handleChooseAvatar}
      className={clsx(
        `ag-relative ag-group ag-h-fit ag-w-24 ag-truncate`,
        isActive ? 'ag-cursor-default' : 'ag-cursor-pointer'
      )}
    >
      {replica.thumbnail_urls && replica.thumbnail_urls.length > 0 ? (
        <img
          className={cn(
            'ag-w-24 ag-aspect-square ag-border ag-rounded',
            isActive ? 'ag-border-secondary' : 'ag-border-neutral-100',
            replica.yepic_avatar_type === 'Full Body' ||
              replica.yepic_avatar_type === 'Vertical Selfie'
              ? 'ag-object-contain'
              : 'ag-object-cover'
          )}
          src={replica.thumbnail_urls[0]}
        />
      ) : replica.thumbnail_url ? (
        <img
          className={`ag-w-24 ag-aspect-square ag-border ag-object-cover ag-rounded ${
            isActive ? 'ag-border-secondary' : 'ag-border-neutral-100'
          } `}
          src={replica.thumbnail_url}
        />
      ) : (
        <video
          preload="metadata"
          src={replica.thumbnail_video_url}
          poster={replica.thumbnail_video_url}
          className={`ag-w-24 ag-aspect-square ag-border ag-object-cover ag-rounded ${
            isActive ? 'ag-border-secondary' : 'ag-border-neutral-100'
          } `}
        />
      )}
      <span className="ag-text-neutral-600 ag-text-sm ag-inline ag-bottom-2 ag-left-2">
        {replica?.yepic_name || replica?.replica_name}
      </span>
    </div>
  );
};
