import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { MdOutlineTranslate, MdOutlineWidgets } from 'react-icons/md';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { HiCode } from 'react-icons/hi';
import { FiEdit2, FiPhoneCall, FiShare2 } from 'react-icons/fi';
import { LuTrash2 } from 'react-icons/lu';

import SelectInput from 'components/shared/SelectInput';
import Dropdown from 'components/shared/Dropdown';

import {
  AVATARS_CALL_ROUTE,
  AVATARS_EDIT_PERSONA_ROUTE,
  CONVERSATIONS_ROUTE,
  featureFlags,
  STT_LOCALES_NAMES
} from 'shared/model';
import { FilledButton, useQueryParams } from 'shared';
import { useAuthStore } from 'stores/useAuthStore';
import { creditStore } from 'entities/credit';
import {
  EIntegrationSettingsType,
  ITavusPersona,
  tavusPersonaStore
} from 'entities/tavus/persona';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { TavusDeletePersonaModal } from 'features/tavus-delete-persona';
import { preconversationStore } from 'entities/tavus/preconversation';
import { BsReverseListColumnsReverse } from 'react-icons/bs';

interface Props {
  persona: ITavusPersona;
}

const TavusPersonaWrapper: FC<
  PropsWithChildren<Props & { className?: string }>
> = ({ persona, className = '', children }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { getReplicaById, replicas } = replicaStore((state) => ({
    getReplicaById: state.getReplicaById,
    replicas: state.replicas
  }));
  const [replica, setReplica] = useState(
    getReplicaById(persona.default_replica_id)
  );

  useEffect(() => {
    setReplica(getReplicaById(persona.default_replica_id));
  }, [replicas]);

  const nav = useNavigate();

  const { togglePersonaEmbedModal, setPersonaShareModal, setCurrentPersona } =
    tavusPersonaStore((state) => ({
      togglePersonaEmbedModal: state.togglePersonaEmbedModal,
      setPersonaShareModal: state.setPersonaShareModal,
      setCurrentPersona: state.setCurrentPersona
    }));

  const setPreconversationModal = preconversationStore(
    (state) => state.setPreconversationModal
  );

  const { isAuth, toggleCreateAccModal } = useAuthStore();

  const { toggleFeatureBlockModal, isChatAvailable, productName } =
    creditStore();
  const params = useQueryParams();
  const languageParam = params.get('language');

  const handleEmbedPersona = (type: EIntegrationSettingsType) => {
    const isEmbed: boolean =
      !!productName &&
      (productName.toLowerCase() === 'plus' ||
        productName.toLowerCase() === 'premium');

    if (isAuth) {
      if (isChatAvailable) {
        togglePersonaEmbedModal(persona.id, type);
      } else {
        toggleFeatureBlockModal('embed');
      }
    } else {
      toggleCreateAccModal();
    }
  };

  const handleEditPersona = () => {
    if (isAuth) {
      if (isChatAvailable) {
        nav(AVATARS_EDIT_PERSONA_ROUTE.replace(':id', persona.id));
      } else {
        toggleFeatureBlockModal('agents');
      }
    } else {
      toggleCreateAccModal();
    }
  };

  const handleSharePersona = () => {
    if (isAuth) {
      if (isChatAvailable) {
        setPersonaShareModal(persona);
      } else {
        toggleFeatureBlockModal('agents');
      }
    } else {
      toggleCreateAccModal();
    }
  };

  const handleFillOutForm = () => {
    setCurrentPersona(persona);
    setPreconversationModal(true);
  };

  const handleGoToConversations = () => {
    if (isAuth) {
      if (isChatAvailable) {
        nav(CONVERSATIONS_ROUTE.replace(':personaId', persona.id));
      } else {
        toggleFeatureBlockModal('agents');
      }
    } else {
      toggleCreateAccModal();
    }
  };

  return (
    <>
      <div
        className={`ag-relative ag-group ag-h-72 lg:ag-h-72 3xl:ag-h-96 ag-p-3 ag-border ag-bg-white dark:ag-bg-neutral-800 dark:ag-border-neutral-800 ag-rounded-lg hover:ag-border-primary-600 ag-cursor-pointer hover:ag-cursor-auto ${className}`}
      >
        <Dropdown
          icon={
            <BiDotsHorizontalRounded className="ag-w-5 ag-h-5 !ag-text-neutral-800 !ag-border-neutral-200" />
          }
          buttonClassName="!ag-border-neutral-200 !ag-text-neutral-700 ag-bg-white ag-absolute ag-top-0 ag-right-0 ag-p-1.5 ag-rounded-md ag-flex ag-items-center ag-justify-center ag-group ag-cursor-pointer"
          optionClassName="!ag-bg-white !ag-text-neutral-700 hover:!ag-bg-neutral-200"
          options={[
            ...(persona.user_id
              ? [
                  {
                    label: 'Edit',
                    onClick: handleEditPersona,
                    icon: FiEdit2
                  }
                ]
              : []),
            ...(featureFlags.tShare
              ? [
                  {
                    label: 'Share',
                    onClick: handleSharePersona,
                    icon: FiShare2
                  }
                ]
              : []),
            ...(featureFlags.embed
              ? [
                  {
                    label: 'Embed',
                    onClick: () =>
                      handleEmbedPersona(EIntegrationSettingsType.EMBED),
                    icon: HiCode
                  },
                  {
                    label: 'Widget',
                    onClick: () =>
                      handleEmbedPersona(EIntegrationSettingsType.WIDGET),
                    icon: MdOutlineWidgets
                  }
                ]
              : []),
            ...(featureFlags.conversationConfig &&
            persona.preconversation_config
              ? [
                  {
                    label: 'Fill Out the Form',
                    onClick: handleFillOutForm,
                    icon: HiCode
                  }
                ]
              : []),
            {
              label: 'Conversations',
              onClick: handleGoToConversations,
              icon: BsReverseListColumnsReverse
            },
            ...(persona.user_id
              ? [
                  {
                    label: 'Delete',
                    onClick: () =>
                      isAuth
                        ? setDeleteModalOpen(true)
                        : toggleCreateAccModal(),
                    icon: LuTrash2
                  }
                ]
              : [])
          ]}
          className="ag-w-36 !ag-bg-white !ag-border-neutral-200"
          wrapperClassName="lg:group-hover:ag-opacity-100 lg:ag-opacity-0 ag-absolute ag-top-0 ag-right-0 ag-z-10"
        />

        <div className="ag-pb-3 ag-h-44 3xl:ag-h-[270px] ag-flex ag-items-center ag-justify-center">
          {replica ? (
            replica.thumbnail_urls && replica.thumbnail_urls.length ? (
              <img
                className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top"
                src={replica.thumbnail_urls[0]}
              />
            ) : replica.thumbnail_url ? (
              <img
                className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top"
                src={replica?.thumbnail_url}
              />
            ) : (
              <video
                className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top"
                src={replica.thumbnail_video_url}
              />
            )
          ) : (
            <div className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top ag-bg-neutral-100" />
          )}
        </div>

        {children}
      </div>
      <TavusDeletePersonaModal
        persona={persona}
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
      />
    </>
  );
};

export const TavusPersona: FC<Props> = ({ persona }) => {
  const [language, setLanguage] = useState<string>(persona.default_language);

  const { setCurrentPersona } = tavusPersonaStore();
  const { isAuth, toggleCreateAccModal } = useAuthStore();
  const { isChatAvailable, toggleFeatureBlockModal } = creditStore();
  const nav = useNavigate();
  const handlePersonaCall = () => {
    if (isAuth) {
      if (isChatAvailable) {
        setCurrentPersona(persona);
        nav(AVATARS_CALL_ROUTE + `?language=${language}`);
      } else {
        toggleFeatureBlockModal('agents');
      }
    } else {
      toggleCreateAccModal();
    }
  };

  return (
    <TavusPersonaWrapper persona={persona}>
      <div className="group-hover:ag-hidden">
        <h3 className="ag-text-sm ag-font-semibold ag-text-neutral-900 dark:ag-text-white ag-mb-1">
          {persona.persona_name}
        </h3>
        {/* <span className="ag-text-xs ag-line-clamp-3 ag-text-600 dark:ag-text-neutral-400">
          {persona.system_prompt}
        </span> */}
      </div>
      <div className="ag-hidden group-hover:ag-block">
        <SelectInput
          name="select-language"
          options={STT_LOCALES_NAMES}
          value={
            STT_LOCALES_NAMES.find((item) => item.toLowerCase() === language)!
          }
          onChange={(value) => setLanguage(value.toLowerCase())}
          optionsWrapperClassName="!ag-bg-white !ag-border-neutral-200"
          className="ag-h-7 !ag-bg-white !ag-border-neutral-200 !ag-text-neutral-700"
          wrapperClassName="!ag-bg-white !ag-text-neutral-700 !ag-border-neutral-200"
          icon={<MdOutlineTranslate className="ag-size-6 ag-p-1" />}
          optionsClassName="ag-text-neutral-700 ag-border-neutral-200 ag-text-sm ag-py-1 hover:ag-bg-neutral-200/80 dark:hover:ag-bg-[#262626]/80"
        />

        <FilledButton
          onClick={handlePersonaCall}
          className="ag-py-2 ag-w-full ag-mt-3.5"
        >
          <FiPhoneCall className="ag-w-5 ag-h-5 ag-mr-2" />
          Call me
        </FilledButton>
      </div>
    </TavusPersonaWrapper>
  );
};
