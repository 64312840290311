import {
  FEATURE_FLAG_CONVERSATION_CONFIG,
  FEATURE_FLAG_CONVERSATION_TIMER,
  FEATURE_FLAG_EMBED,
  FEATURE_FLAG_SHARE,
  FEATURE_FLAG_STOP_TALK,
  FEATURE_FLAG_T_AVATARS,
  FEATURE_FLAG_T_SHARE,
  FEATURE_FLAG_T_STOCK_VIDEO_AGENTS,
  FEATURE_FLAG_TALKING_PHOTOS
} from './config';

export const featureFlags = {
  stopTalking: FEATURE_FLAG_STOP_TALK === 'true',
  embed: FEATURE_FLAG_EMBED === 'true',
  share: FEATURE_FLAG_SHARE === 'true',
  tAvatars: FEATURE_FLAG_T_AVATARS === 'true',
  tShare: FEATURE_FLAG_T_SHARE === 'true',
  tStockAgents: FEATURE_FLAG_T_STOCK_VIDEO_AGENTS === 'true',
  conversationTimer: FEATURE_FLAG_CONVERSATION_TIMER === 'true',
  conversationConfig: FEATURE_FLAG_CONVERSATION_CONFIG === 'true',
  talkingPhotos: FEATURE_FLAG_TALKING_PHOTOS === 'true'
};
