import { IUser } from 'shared/model';
import { create } from 'zustand';
import { userApi } from '../api';

type Store = {
  user: IUser | null;
  isLoading: boolean;
};

type Action = {
  me: () => Promise<void>;
};

export const userStore = create<Store & Action>()((set) => ({
  isLoading: true,
  user: null,
  me: async () => {
    set({ isLoading: true });
    try {
      const user = await userApi.me();
      set({
        user
      });
    } catch (error) {
      set({ user: null });
      console.error('chat /me error: ', error);
    } finally {
      set({ isLoading: false });
    }
  }
}));

export const fullNameSelector = (state: Store) => {
  if (!state.user) return '';
  const { name = '', surname = '' } = state.user;
  return `${name} ${surname}`.trim();
};
