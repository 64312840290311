export const bubbleIcons = {
  first: {
    variant: 'first',
    draw: (
      bubbleBackgroundColor: string = '#C34DFF',
      bubbleIconColor: string = 'white'
    ) => (
      <svg
        width="41"
        height="40"
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20.3125" cy="20" r="20" fill={bubbleBackgroundColor} />
        <path
          d="M19.9999 9C18.0589 8.99913 16.1523 9.51211 14.4738 10.4868C12.7952 11.4615 11.4046 12.8632 10.4433 14.5494C9.48195 16.2356 8.98411 18.1462 9.00039 20.0872C9.01666 22.0281 9.54645 23.9301 10.5359 25.6L9.06093 29.658C9.00597 29.809 8.98822 29.971 9.00917 30.1304C9.03012 30.2897 9.08916 30.4416 9.18128 30.5733C9.27341 30.705 9.39592 30.8125 9.53842 30.8868C9.68093 30.9611 9.83923 30.9999 9.99993 31C10.1165 30.9997 10.2322 30.9794 10.3419 30.94L14.3999 29.464C15.8563 30.3268 17.4922 30.8418 19.1801 30.9689C20.8681 31.0961 22.5626 30.832 24.1318 30.1971C25.701 29.5623 27.1025 28.5738 28.2272 27.3088C29.3519 26.0437 30.1695 24.5361 30.6163 22.9034C31.0631 21.2707 31.1271 19.5569 30.8032 17.8954C30.4793 16.2339 29.7764 14.6696 28.7491 13.3243C27.7218 11.9789 26.3978 10.8887 24.8803 10.1387C23.3628 9.38869 21.6927 8.999 19.9999 9Z"
          fill={bubbleIconColor}
        />
      </svg>
    )
  },
  second: {
    variant: 'second',
    draw: (
      bubbleBackgroundColor: string = '#C34DFF',
      bubbleIconColor: string = 'white'
    ) => (
      <svg
        width="41"
        height="40"
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20.1875" cy="20" r="20" fill={bubbleBackgroundColor} />
        <g clipPath="url(#clip0_3028_25318)">
          <path
            d="M20.0005 8C13.3753 8 8.00049 13.368 8.00049 19.9937C8.00049 26.6257 13.3753 32 20.0005 32C26.6293 32 31.9995 26.6256 31.9995 19.9937C31.9996 13.368 26.6293 8 20.0005 8ZM20.8556 26.6708C20.5769 26.9188 20.2539 27.0435 19.888 27.0435C19.5095 27.0435 19.1792 26.9211 18.8974 26.6758C18.6151 26.4309 18.4736 26.0881 18.4736 25.6476C18.4736 25.2568 18.6105 24.928 18.8834 24.6614C19.1562 24.3949 19.491 24.2616 19.888 24.2616C20.2788 24.2616 20.6077 24.3949 20.8746 24.6614C21.1412 24.928 21.2749 25.2568 21.2749 25.6476C21.2744 26.0817 21.1348 26.4228 20.8556 26.6708ZM24.3309 18.0553C24.1167 18.4523 23.8624 18.7947 23.5674 19.0834C23.2733 19.3721 22.7444 19.8573 21.9809 20.5394C21.7704 20.7318 21.601 20.9008 21.4741 21.0462C21.3471 21.1922 21.2523 21.3254 21.1904 21.4465C21.128 21.5675 21.0802 21.6886 21.0463 21.8096C21.0124 21.9302 20.9613 22.143 20.8922 22.447C20.7748 23.0921 20.4057 23.4146 19.7854 23.4146C19.4629 23.4146 19.1919 23.3094 18.9709 23.0984C18.7509 22.8874 18.6412 22.5744 18.6412 22.1588C18.6412 21.638 18.722 21.1866 18.8833 20.805C19.0437 20.4232 19.2583 20.0885 19.5248 19.7998C19.7918 19.5112 20.1513 19.1687 20.6044 18.7717C21.0015 18.4243 21.2884 18.1623 21.465 17.9856C21.6421 17.8086 21.7907 17.6116 21.9113 17.3948C22.0329 17.1775 22.0925 16.9421 22.0925 16.6878C22.0925 16.1914 21.9087 15.7731 21.5391 15.432C21.1701 15.0909 20.6939 14.9202 20.1107 14.9202C19.4282 14.9202 18.9258 15.0923 18.6033 15.4365C18.2807 15.7807 18.0083 16.2876 17.7847 16.9575C17.5733 17.6586 17.1731 18.0091 16.5844 18.0091C16.237 18.0091 15.9439 17.8867 15.7049 17.6418C15.4664 17.397 15.3471 17.1318 15.3471 16.8464C15.3471 16.2573 15.5364 15.6601 15.9145 15.0552C16.2931 14.4503 16.8451 13.9493 17.571 13.5527C18.2965 13.1556 19.1436 12.9568 20.1107 12.9568C21.0101 12.9568 21.8038 13.1231 22.4923 13.4551C23.1808 13.7867 23.7129 14.238 24.0883 14.809C24.4632 15.3795 24.6511 15.9997 24.6511 16.6697C24.652 17.196 24.545 17.6582 24.3309 18.0553Z"
            fill={bubbleIconColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_3028_25318">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(8 8)"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }
};
