import { ITMessageDaily } from 'shared/model';

export interface IPreconversationPayload {
  name: string;
  value: string;
}
export interface IPreconversation {
  preconversation_id: string;
}
export enum EPreconversationStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  FINISHED = 'finished'
}

export interface IExternalTranscription {
  content: string;
  role: string;
}

export interface IPreconversationItem {
  uuid: string;
  persona_id: string;
  created_at: string;
  conversation_id: string | null;
  summary: string | null;
  status: EPreconversationStatus;
  payload: IPreconversationPayload[];
  transcription: ITMessageDaily[];
  external_transcripts: IExternalTranscription[];
}
