import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import Modal from '../../../components/shared/Modal';
import { BiX } from 'react-icons/bi';
import { FilledButton, OutlinedButton } from 'shared/ui';

export const CallEndModal: FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onCallEnd: () => void;
}> = ({ ...props }) => {
  const toggleModal = () => {
    props.setOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        props.onCallEnd();
        toggleModal();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Modal
      {...props}
      closeButton={
        <BiX
          onClick={() => props.setOpen(false)}
          className="ag-cursor-pointer ag-w-11 ag-h-11 ag-p-2.5 ag-absolute ag-right-2.5 ag-top-2.5"
        />
      }
      className="!ag-w-96 ag-p-6 max-sm:ag-mt-auto max-sm:ag-mb-16"
      noPadding
    >
      <h3 className="ag-mb-1 ag-text-lg ag-font-semibold ag-text-neutral-900">
        Call End
      </h3>
      <span className="ag-text-sm ag-text-neutral-600">
        Are you sure you want to end the call? Press Enter to confirm or Escape
        to cancel.
      </span>
      <div className="ag-mt-8 ag-flex ag-flex-col-reverse sm:ag-flex-row ag-items-center ag-justify-between ag-gap-3">
        <OutlinedButton
          onClick={toggleModal}
          className="ag-w-full ag-py-2.5 ag-text-base ag-font-semibold"
        >
          Cancel
        </OutlinedButton>
        <FilledButton
          className="ag-w-full ag-py-2.5 ag-bg-red-600 ag-text-base ag-font-semibold ag-gap-2"
          onClick={() => {
            props.onCallEnd();
            toggleModal();
          }}
        >
          Yes
        </FilledButton>
      </div>
    </Modal>
  );
};
