import { useEffect, useRef, useState } from 'react';
import { logger } from 'shared/lib';
import { featureFlags, MAIN_ROUTE } from 'shared/model';
import { Conversation, conversationStore } from 'entities/tavus/conversation';
import { DailyCall } from '@daily-co/daily-js';
import {
  clearSessionTime,
  getSessionTime,
  setSessionStartTime,
  updateSessionEndTime
} from 'entities/tavus/conversation/lib/sessionTIme';
import { dailyCallStore } from 'features/daily-call/model';
import {
  EPreconversationStatus,
  preconversationStore
} from 'entities/tavus/preconversation';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { useNavigate } from 'react-router';

export const TIME_LIMIT = 5 * 60;

export enum EventType {
  ECHO = 'echo',
  SYSTEM_PROMPT = 'system_prompt',
  END_CALL = 'end_call'
}

interface IEvent {
  type: EventType;
  time_point: number;
  value?: string;
}

export interface ITimerConfig {
  duration: number;
  events: IEvent[];
}

const EVENTS_MAP: Partial<Record<EventType, string>> = {
  [EventType.SYSTEM_PROMPT]: 'conversation.overwrite_llm_context',
  [EventType.ECHO]: 'conversation.echo'
};

export const useTimerConversation = (
  currentConversation: Conversation | null,
  callObject: DailyCall | null
) => {
  const timerInterval = useRef<null | NodeJS.Timeout>(null);
  const [isStarted, setIsStarted] = useState(false);
  const endConversation = conversationStore((state) => state.endConversation);
  const nav = useNavigate();
  const { startLeavingCall, messages } = dailyCallStore((state) => ({
    startLeavingCall: state.startLeavingCall,
    messages: state.messages
  }));

  const { currentPreconversationId, setCurrentPreconversationId } =
    preconversationStore((state) => ({
      currentPreconversationId: state.currentPreconversationId,
      setCurrentPreconversationId: state.setCurrentPreconversationId
    }));

  const { setCurrentPersona } = tavusPersonaStore((state) => ({
    setCurrentPersona: state.setCurrentPersona
  }));

  const stopTimer = () => {
    setIsStarted(false);
    clearSessionTime();
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
      timerInterval.current = null;
    }
  };

  const handleEndCall = () => {
    setCurrentPersona(null);
    stopTimer();
    if (currentConversation) {
      endConversation(messages).then(() => {
        const opener = window.opener;
        if (opener) {
          opener.postMessage({
            type: 'CHANGE_STATUS',
            payload: {
              status: EPreconversationStatus.FINISHED,
              messages,
              preconversationId: currentPreconversationId,
              conversationId: currentConversation.id
            }
          });
        }
        setCurrentPreconversationId(null);
      });
    }
    startLeavingCall();
    if (!window.opener) {
      nav(MAIN_ROUTE);
    }
  };

  useEffect(() => {
    callObject?.on('participant-joined', (event) => {
      if (!event.participant.local) {
        setIsStarted(true);
      }
    });
  }, [callObject]);

  useEffect(() => {
    if (
      featureFlags.conversationTimer &&
      !timerInterval.current &&
      isStarted &&
      callObject &&
      currentConversation?.timer_config
    ) {
      clearSessionTime();
      setSessionStartTime();
      logger.debug('start timer for conversation');
      let timerConfig: ITimerConfig | null = null;
      if (currentConversation?.timer_config) {
        try {
          timerConfig = JSON.parse(currentConversation?.timer_config);
        } catch (e) {
          logger.debug('error timer config', e);
        }
      }
      logger.debug('timer config', timerConfig);
      timerInterval.current = setInterval(() => {
        const time = getSessionTime();
        if (timerConfig?.events) {
          timerConfig.events.forEach((action) => {
            if (time === action.time_point) {
              logger.debug('action', action, EVENTS_MAP[action.type]);
              if (action.type === EventType.END_CALL) {
                handleEndCall();
              } else if (action.type === EventType.SYSTEM_PROMPT) {
                callObject?.sendAppMessage({
                  message_type: 'conversation',
                  event_type: EVENTS_MAP[action.type],
                  conversation_id: currentConversation?.conversation_id,
                  properties: {
                    context: action.value
                  }
                });
              } else if (action.type === EventType.ECHO) {
                callObject?.sendAppMessage({
                  message_type: 'conversation',
                  event_type: EVENTS_MAP[action.type],
                  conversation_id: currentConversation?.conversation_id,
                  properties: {
                    modality: 'text',
                    text: action.value
                  }
                });
              }
            }
          });
        }

        updateSessionEndTime();
      }, 1000);

      return () => {
        clearSessionTime();
        if (timerInterval.current) {
          clearInterval(timerInterval.current);
          timerInterval.current = null;
        }
      };
    }
  }, [isStarted, currentConversation]);

  return stopTimer;
};
