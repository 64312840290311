import { Select } from '@headlessui/react';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { ETaskState, ETaskType, ITask, taskStore } from 'entities/tavus/task';
import { useEffect, useMemo, useState } from 'react';
import { DataTable, FilledButton, Loader } from 'shared/ui';

export const TasksPage = () => {
  const { fetchAll, tasksList, tasksLoading, restartTask } = taskStore(
    (state) => ({
      fetchAll: state.fetchAll,
      tasksList: state.tasksList,
      tasksLoading: state.tasksLoading,
      restartTask: state.restartTask
    })
  );
  useEffect(() => {
    fetchAll();
  }, []);

  const [stateFilter, setStateFilter] = useState<ETaskState | null>(null);
  const [typeFilter, setTypeFilter] = useState<string | null>(null);

  const filteredTasks = useMemo(() => {
    return tasksList
      .filter((item) => {
        if (!stateFilter) {
          return item;
        }
        return item.state === stateFilter;
      })
      .filter((item) => {
        if (!typeFilter) {
          return item;
        }
        return item.type === typeFilter;
      });
  }, [tasksList, stateFilter, typeFilter]);

  const columns: ColumnDef<ITask>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID'
      },
      {
        accessorKey: 'data',
        header: 'Data'
      },
      {
        accessorKey: 'payload',
        header: 'Payload',
        cell: ({ row }) => {
          try {
            return JSON.stringify(row.original.payload);
          } catch (e) {
            return '';
          }
        }
      },
      {
        accessorKey: 'type',
        header: 'Type'
      },
      {
        accessorKey: 'state',
        header: 'State'
      },
      {
        accessorKey: 'run_at',
        header: 'Run at',
        cell: ({ row }) => (
          <div>
            {row.original.run_at
              ? format(row.original.run_at + 'Z', 'MM/dd/yyyy HH:mm')
              : ''}
          </div>
        )
      },
      {
        accessorKey: 'created_at',
        header: 'Created at',
        cell: ({ row }) => (
          <div>{format(row.original.created_at + 'Z', 'MM/dd/yyyy HH:mm')}</div>
        )
      },
      {
        id: 'restart',
        header: '',
        cell: ({ row }) => (
          <div>
            <FilledButton
              className="ag-p-2 ag-mt-3.5"
              disabled={row.original.state !== 'FAILED'}
              onClick={() => restartTask(row.original.id, row.original.type!)}
            >
              Restart
            </FilledButton>
          </div>
        )
      }
    ],
    []
  );
  return (
    <div>
      {tasksLoading ? (
        <div className="ag-w-full ag-h-screen ag-flex ag-items-center ag-justify-center -ag-pt-24">
          <Loader size={96} />
        </div>
      ) : (
        <div className="ag-p-10">
          <div className="ag-flex ag-gap-10 ag-items-end ag-mb-4">
            <FilledButton onClick={() => fetchAll()} className="ag-p-2">
              Update
            </FilledButton>
            <div>
              <div>State Filter:</div>
              <Select
                onChange={(event) => {
                  const value = event.target.value;
                  if (value === 'all') {
                    setStateFilter(null);
                    return;
                  }
                  setStateFilter(value as ETaskState);
                }}
              >
                <option value="all">All</option>
                {Object.values(ETaskState).map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </div>
            <div>
              <div>Type Filter:</div>
              <Select
                onChange={(event) => {
                  const value = event.target.value;
                  if (value === 'all') {
                    setTypeFilter(null);
                    return;
                  }
                  setTypeFilter(value as ETaskType);
                }}
              >
                <option value="all">All</option>
                {Object.values(ETaskType).map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <DataTable columns={columns} data={filteredTasks} />
        </div>
      )}
    </div>
  );
};
