import { prices } from 'components/Forms/IntegrateForm/IntegrateForm';
import { STT_LOCALES } from './config';
import { RowData } from '@tanstack/react-table';

export enum ChatRole {
  USER = 'user',
  ASSISTANT = 'assistant',
  SYSTEM = 'system'
}

export interface ITMessageBase {
  role: ChatRole;
  content: string;
  timestamp: Date;
}

export interface ITMessageLivekit extends ITMessageBase {
  emotion?: string;
  affect?: string[];
  language: typeof STT_LOCALES[number];
}

export interface ITMessageDaily extends ITMessageBase {
  participantId: string;
  userName?: string;
}

export type RenewalType = 'Non-recurring' | 'Monthly' | 'Annually';

export type TApiKeys = {
  apiKey: string | null;
  agentKey: string | null;
};

export type IMessage = {
  role: ChatRole;
  content: string;
  // url?: string;
  urls?: string[];
  chatId?: string;
  id: string;
  isLoading?: boolean;
};

export interface GuideContextI {
  step: GuideStepI | null;
  stepIndex: number | null;
  handleStartGuide: () => void;
  handleEndGuide: () => void;
  handleNextStep: () => void;
  stepsLength: number;
  isMobile: boolean;
}

export enum GuideSteps {
  AVATARS = 'Avatars',
  VOICES = 'Voices',
  STYLE = 'Style',
  ENGINE = 'Engine',
  TYPE_OR_SPEAK = 'Type or speak',
  OPEN_TOOLS_PANEL = 'Open the tools panel'
}

export type PositionT =
  | `bottom-right`
  | `bottom-center`
  | 'top-right'
  | 'right-top'
  | 'top-center';

export interface GuideStepI {
  position: PositionT;
  hash?: string;
  name: GuideSteps;
  text: string;
}

export interface ICredits {
  creditsAmount: number;
  creditsMax: number;
}

export interface IDefaultMessage {
  _id: string;
  question: string;
  answer: string;
  hidden: boolean;
  priority: number;
  tags: string[];
}

export interface IMessageResponse {
  userMessageId: string;
  message: IMessage;
  // url: string;
  urls: string[];
}
export type RatioOptions = 'full_body' | 'half_body';
export type GenderOptions = 'male' | 'female' | 'other';

export type AvatarOptions = 'talking photo' | 'avatar' | 'custom';

export interface IAvatar {
  age?: number;
  avatarStatus: string | null;
  customAvatar: boolean;
  fullTrial: boolean | null;
  gender: 'Male' | 'Female' | 'Other'; // probably could be changed to GenderOptions when will not be capitalized
  id: string;
  inputImageUrl?: string;
  memberstackId?: string;
  menuCircleImageUrl: string;
  menuImageUrl: string;
  rawImageUrl?: string;
  name: string;
  organizationId?: string | null;
  publicAvatar: boolean;
  talkingPhoto: boolean;
  talkingPhotoUpperBody?: boolean;
  userId?: string;
  idleLoopVideoUrl: string;
  hasIdleLoop?: boolean;
}

export type Country = {
  name: string;
  emoji?: string;
  unicode?: string;
  image?: string;
};

export type Language = {
  language: string;
  country: Country | null;
  isMultilingual: boolean;
};

export interface IVoice {
  audioSampleUrl: string | null;
  gender: 'male' | 'female';
  id: string;
  isNeural: boolean;
  isMultilingual: boolean;
  language: string;
  languageCode: string;
  languageCodes: string[];
  languageCodesJson: string;
  name: string;
  provider: string;
  providerVoiceId: string;
  textToSpeechApiProvider: string;
  voiceId: string;
  maxCharacterLimit: number | string;
  isPremiumVoice: boolean;
  privateVoice: boolean;
}

export interface IIntegrateFormValues {
  integration: string;
  usage: string;
  price: typeof prices[number];
}

export interface Pagination<T> {
  items: T[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

// export interface IIntegratePayload extends IIntegrateFormValues {
//   user_id
// }

declare global {
  interface Window {
    ZiggeoApi?: any;
    CY: any;
  }
}

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'number' | 'select' | 'date';
    isCollapsible?: boolean;
  }
}
