import FormError from 'components/Forms/FormError/FormError';
import Dropdown from 'components/shared/Dropdown';
import {
  EPreconversationType,
  ITavusPersonaFormik
} from 'entities/tavus/persona';
import { FieldArray, FormikProps, FormikProvider, getIn } from 'formik';
import { FC } from 'react';
import { VscChevronDown } from 'react-icons/vsc';
import { cn } from 'shared/lib';
import { FilledButton } from 'shared/ui';

interface IProps {
  formik: FormikProps<ITavusPersonaFormik>;
}

export const PreconversationEditForm: FC<IProps> = ({ formik }) => {
  return (
    <div className="ag-space-y-6 ag-text-neutral-900">
      <div className="ag-flex ag-flex-col">
        <label className="ag-mb-4" htmlFor="systemPrompt">
          <div>Context</div>
          <div className="ag-text-sm ag-text-neutral-400"></div>
        </label>
        <textarea
          className={cn(
            'ag-border ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.preconversationConfig?.context &&
              formik.touched.preconversationConfig?.context
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'ag-text-neutral-900 placeholder:ag-text-gray-400 focus:ag-border-primary-800'
          )}
          placeholder="For example, First name: {{name}}"
          name="preconversationConfig.context"
          id="context"
          rows={3}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.preconversationConfig.context}
        />
        <div className="ag-flex ag-items-center ag-justify-between">
          <div>
            {formik.errors.preconversationConfig?.context &&
              formik.touched.preconversationConfig?.context && (
                <FormError>
                  {formik.errors.preconversationConfig.context}
                </FormError>
              )}
          </div>
        </div>
      </div>
      <FormikProvider value={formik}>
        <FieldArray name="preconversationConfig.fields">
          {({ push, remove }) => (
            <>
              <div className="flex ag-items-center ag-justify-between">
                <div>The language field is reserved</div>
                <FilledButton
                  className="ag-w-56 ag-h-11 ag-font-semibold"
                  onClick={() =>
                    push({
                      name: '',
                      type: EPreconversationType.TEXT,
                      label: ''
                    })
                  }
                >
                  Add Field
                </FilledButton>
              </div>

              <table className="ag-w-full ag-border-collapse ag-border ag-border-neutral-600 table-fixed">
                <thead>
                  <tr>
                    <th className="ag-border ag-border-neutral-300 ag-px-3 ag-py-1 ag-w-32 ag-font-medium">
                      Field Name
                    </th>
                    <th className="ag-border ag-border-neutral-300 ag-px-3 ag-py-1 ag-w-32 ag-font-medium">
                      Type
                    </th>
                    <th className="ag-border ag-border-neutral-300 ag-px-3 ag-py-1 ag-w-48 ag-font-medium">
                      Label
                    </th>
                    <th className="ag-border ag-border-neutral-300 ag-px-3 ag-py-1 ag-w-20 ag-font-medium">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.preconversationConfig?.fields.map(
                    (field, index) => (
                      <tr
                        key={index}
                        className="ag-border ag-border-neutral-300 ag-align-top"
                      >
                        <td className="ag-border ag-border-neutral-300 ag-px-3 ag-py-1 w-32 ag-h-16">
                          <input
                            className={cn(
                              'ag-border ag-border-neutral-300 ag-py-1 ag-px-2 ag-rounded-md ag-text-sm ag-w-full focus:ag-border-primary-800',
                              getIn(
                                formik.errors,
                                `preconversationConfig.fields[${index}].name`
                              ) &&
                                getIn(
                                  formik.touched,
                                  `preconversationConfig.fields[${index}].name`
                                )
                                ? 'focus:ag-border-red-500'
                                : 'placeholder:ag-text-gray-400 focus:ag-border-primary-800'
                            )}
                            placeholder="Field Name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={field.name}
                            name={`preconversationConfig.fields[${index}].name`}
                          />
                          {getIn(
                            formik.errors,
                            `preconversationConfig.fields[${index}].name`
                          ) &&
                            getIn(
                              formik.touched,
                              `preconversationConfig.fields[${index}].name`
                            ) && (
                              <FormError>
                                {getIn(
                                  formik.errors,
                                  `preconversationConfig.fields[${index}].name`
                                )}
                              </FormError>
                            )}
                        </td>
                        <td className="ag-border ag-border-neutral-300 ag-px-3 ag-py-1 w-24">
                          <Dropdown
                            wrapperClassName="ag-w-full"
                            icon={<VscChevronDown className="ag-my-auto" />}
                            labelClassName="ag-line-clamp-1 ag-text-neutral-900 !ag-font-normal"
                            label={field.type}
                            buttonClassName="ag-px-3 ag-py-1 ag-w-full !ag-border-neutral-300"
                            className="ag-bg-white !ag-border-neutral-300"
                          >
                            <div>
                              {Object.values(EPreconversationType).map(
                                (type) => (
                                  <div
                                    key={type}
                                    onClick={() =>
                                      formik.setFieldValue(
                                        `preconversationConfig.fields[${index}].type`,
                                        type
                                      )
                                    }
                                    className="ag-p-2 cursor-pointer hover:ag-bg-neutral-300 px-2"
                                  >
                                    {type}
                                  </div>
                                )
                              )}
                            </div>
                          </Dropdown>
                        </td>
                        <td className="ag-border ag-border-neutral-300 ag-px-3 ag-py-1 w-48">
                          <input
                            className="ag-border ag-border-neutral-300 ag-py-1 ag-px-2 ag-rounded-md ag-text-sm ag-w-full focus:ag-border-primary-800"
                            placeholder="Label"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={field.label}
                            name={`preconversationConfig.fields[${index}].label`}
                          />
                        </td>
                        <td className="ag-border ag-border-neutral-300 ag-px-3 ag-py-1 w-20 text-center">
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className="ag-text-red-500"
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </>
          )}
        </FieldArray>
      </FormikProvider>
    </div>
  );
};
