import { featureFlags } from 'shared/model';

export const tavusSteps = [
  { name: 'Avatar', num: 1 },
  { name: 'Background', num: 2 },
  { name: 'Voice', num: 3 },
  { name: 'Persona', num: 4 },
  {
    name: 'Knowledge',
    num: 5
  },
  ...(featureFlags.conversationConfig
    ? [
        {
          name: 'Preconversation Config',
          num: 6
        }
      ]
    : [])
];
