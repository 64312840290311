export enum EPreconversationType {
  EMAIL = 'email',
  TEXT = 'text'
}
export interface IPerconversationField {
  label: string;
  name: string;
  type: EPreconversationType;
}
export interface IPreconversationConfig {
  context: string;
  fields: IPerconversationField[];
}
export interface ITavusPersonaFormik {
  name: string;
  model: string;
  defaultReplicaId: string;
  context: string;
  systemPrompt: string;
  customBackground: string;
  customGreeting: string;
  timerConfig: string;
  preconversationConfig: IPreconversationConfig;
  voiceId: string;
  defaultLanguage: string;
}

export interface ICreateTavusPersonaReq {
  persona_name: string;
  context: string;
  system_prompt: string;
  default_replica_id: string;
  custom_background?: string;
  custom_greeting?: string;
  model: string;
  timer_config: string;
  preconversation_config?: IPreconversationConfig;
  voice_id: string;
  default_language: string;
}

export interface ICreateTavusPersonaRes {
  persona_id: string;
  persona_name: string;
  created_at: string;
}

interface FunctionParameters {
  type: string;
  properties: {
    location: {
      type: string;
      description: string;
    };
    unit: {
      type: string;
      enum: string[];
    };
  };
  required: string[];
}

interface ToolFunction {
  name: string;
  description: string;
  parameters: FunctionParameters;
}

interface Tool {
  type: string;
  function: ToolFunction;
}

interface LLM {
  model: string;
  base_url: string;
  api_key: string;
  tools: Tool[];
}

interface VoiceSettings {
  speed: string;
  emotion: string[];
}

interface TTS {
  api_key: string;
  tts_engine: string;
  external_voice_id: string;
  voice_settings: VoiceSettings;
  playht_user_id: string;
}

interface VQA {
  enable_vision: string;
}

interface Layers {
  llm: LLM;
  tts: TTS;
  vqa: VQA;
}

export interface ITavusPersona {
  id: string;
  persona_name: string;
  system_prompt: string;
  default_replica_id: string;
  custom_background: string;
  context: string;
  layers: Layers;
  updated_at: string;
  user_id: string;
  custom_greeting?: string;
  model: string;
  timer_config: string;
  default_language: string;
  preconversation_config?: IPreconversationConfig;
  voice_id?: string;
  integration_settings?: IIntegrationSettings[] | null;
}

export interface ListPersonas {
  items: ITavusPersona[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

export enum EIntegrationSettingsType {
  WIDGET = 'widget',
  EMBED = 'embed'
}

export interface IIntegrationSettings {
  video_agent_name: string;
  description: string;
  button_text: string;
  powered_by: boolean;
  main_color: string;
  more_personalised_colors: boolean;
  dark_mode: boolean;
  font: string;
  camera: boolean;
  language_selection: boolean;
  captions_by_default: boolean;
  domains?: string[];
  welcome_button_color?: string;
  bubble_background_color?: string;
  bubble_icon_color?: string;
  bubble_icon_variant?: string;
  bubble_icon_custom?: boolean;
  bubble_icon_url?: string;
  dimensions?: {
    width: number;
    height: number;
  };
  type: EIntegrationSettingsType;
}
