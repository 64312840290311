import { create } from 'zustand';
import { tavusPersonaApi } from '../api';
import { toast } from 'react-toastify';
import {
  EIntegrationSettingsType,
  ICreateTavusPersonaReq,
  ICreateTavusPersonaRes,
  IIntegrationSettings,
  ITavusPersona,
  ITavusPersonaFormik
} from './tavusPersonaTypes';
import { showError } from 'shared/model';
import { getErrorMessageV2 } from 'shared/lib';

export const tavusInitialPersona: ITavusPersonaFormik = {
  name: '',
  defaultReplicaId: '',
  context: '',
  systemPrompt: '',
  model: 'llama',
  customBackground: '',
  customGreeting: '',
  timerConfig: '',
  preconversationConfig: {
    context: '',
    fields: []
  },
  voiceId: '',
  defaultLanguage: 'english'
};

type State = {
  isPersonaCreating: boolean;
  currentPersona: ITavusPersona | null;
  tavusPersonas: ITavusPersona[];
  tavusPersonasLoading: boolean;
  personaEmbedModal: {
    personaId: string;
    type: EIntegrationSettingsType;
  } | null;
  isPersonaDeleting: boolean;
  personaShareModal: ITavusPersona | null;
  updateIntegrationSettingsLoading: boolean;
  deleteIntegrationSettingsLoading: boolean;
};
type Actions = {
  addPersona: (
    data: ICreateTavusPersonaReq
  ) => Promise<ICreateTavusPersonaRes | undefined>;
  fetchPersonas: () => Promise<void>;
  fetchOne: (
    id: string,
    showErrorToast?: boolean
  ) => Promise<ITavusPersona | undefined>;
  setCurrentPersona: (persona: ITavusPersona | null) => void;
  togglePersonaEmbedModal: (
    personaId: string | null,
    type?: EIntegrationSettingsType
  ) => void;
  deletePersona: (id: string) => Promise<void>;
  updatePersona: (
    id: string,
    personaData: ICreateTavusPersonaReq
  ) => Promise<void>;
  setPersonaShareModal: (persona: ITavusPersona | null) => void;
  updateIntegrationSettings: (
    personaId: string,
    data: IIntegrationSettings
  ) => Promise<void>;
  deleteIntegrationSettings: (
    personaId: string,
    type: EIntegrationSettingsType
  ) => Promise<void>;
};

type Store = State & Actions;

export const tavusPersonaStore = create<Store>((set, get) => ({
  currentPersona: null,
  tavusPersonas: [],
  tavusPersonasLoading: false,
  personaEmbedModal: null,
  isPersonaCreating: false,
  isPersonaDeleting: false,
  personaShareModal: null,
  updateIntegrationSettingsLoading: false,
  deleteIntegrationSettingsLoading: false,
  setPersonaShareModal: (persona) => {
    set({ personaShareModal: persona });
  },
  addPersona: async (personaData) => {
    set({ isPersonaCreating: true });
    try {
      const data = await tavusPersonaApi.create(personaData);
      await get().fetchPersonas();
      return data;
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        'There was a problem when we tried to create a persona. Please try again later.'
      );
      showError(message);
    } finally {
      set({ isPersonaCreating: false });
    }
  },
  updatePersona: async (id, personaData) => {
    set({ isPersonaCreating: true });
    try {
      await tavusPersonaApi.update(id, personaData);
      await get().fetchPersonas();
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        'There was a problem when we tried to change a persona. Please try again later.',
        true
      );
      showError(
        'There was a problem when we tried to change a persona. Please try again later.'
      );
    } finally {
      set({ isPersonaCreating: false });
    }
  },
  deletePersona: async (id) => {
    set({ isPersonaDeleting: true });
    try {
      await tavusPersonaApi.delete(id);
      const tavusPersonas = [...get().tavusPersonas];
      const indexToDelete = tavusPersonas.findIndex((p) => p.id === id);
      tavusPersonas.splice(indexToDelete, 1);

      set({ tavusPersonas });
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        'There was a problem when we tried to delete a persona. Please try again later.'
      );
      showError(message);
    } finally {
      set({ isPersonaDeleting: false });
    }
  },
  setCurrentPersona: (persona) => {
    set({ currentPersona: persona });
  },
  togglePersonaEmbedModal: (personaId, type) => {
    set({
      personaEmbedModal: personaId
        ? {
            personaId,
            type: type!
          }
        : null
    });
  },
  fetchPersonas: async () => {
    set({ tavusPersonasLoading: true });
    try {
      const tavusPersonas = await tavusPersonaApi.fetchAll();

      set({ tavusPersonas });
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get personas list. Please try again later.'
      );
    } finally {
      set({ tavusPersonasLoading: false });
    }
  },
  fetchOne: async (id: string, showErrorToast = true) => {
    set({ tavusPersonasLoading: true });
    try {
      return await tavusPersonaApi.fetch(id);
    } catch (error) {
      if (showErrorToast) {
        toast.error(
          'There was a problem when we tried to get persona details. Please try again later.'
        );
      }
    } finally {
      set({ tavusPersonasLoading: false });
    }
  },
  updateIntegrationSettings: async (id, data) => {
    set({ updateIntegrationSettingsLoading: true });

    try {
      await tavusPersonaApi.updateIntegrationSettings(id, data);

      set((state) => {
        const personas = [...state.tavusPersonas];
        const currentPersona = personas.find((item) => item.id === id);

        if (!currentPersona) return {};

        currentPersona.integration_settings = currentPersona
          .integration_settings?.length
          ? currentPersona.integration_settings.map((item) =>
              item.type === data.type ? data : item
            )
          : [data];

        return {
          tavusPersonas: personas.map((item) =>
            item.id === id ? { ...currentPersona } : item
          )
        };
      });
    } catch (error) {
      toast.error(
        'There was a problem updating integration settings. Please try again later.'
      );
    } finally {
      set({ updateIntegrationSettingsLoading: false });
    }
  },
  deleteIntegrationSettings: async (personaId, type) => {
    set({ deleteIntegrationSettingsLoading: true });

    try {
      await tavusPersonaApi.deleteIntegrationSettings(personaId, type);

      set((state) => {
        const personas = [...state.tavusPersonas];
        const currentPersona = personas.find((item) => item.id === personaId);

        if (!currentPersona) return {};

        currentPersona.integration_settings =
          currentPersona.integration_settings?.filter(
            (item) => item.type !== type
          ) || [];

        return {
          tavusPersonas: personas.map((item) =>
            item.id === personaId ? { ...currentPersona } : item
          )
        };
      });
    } catch (error) {
      console.log(error);
      toast.error(
        'There was a problem when we tried to delete integration settings. Please try again later.'
      );
    } finally {
      set({ deleteIntegrationSettingsLoading: false });
    }
  }
}));
