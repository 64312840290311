import React, { forwardRef } from 'react';

export const Input = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & { className?: string }
>(({ className, ...props }, ref) => {
  return (
    <input
      ref={ref}
      className={`ag-appearance-none ag-block ag-w-full ag-px-3.5 ag-py-2.5 ag-border ag-border-neutral-300 ag-rounded-md ag-shadow-sm ag-text-neutral-500 ag-focus:outline-none focus:ag-ring-secondary focus:ag-border-secondary sm:ag-text-sm ${
        className ?? ''
      }`}
      {...props}
    />
  );
});

Input.displayName = 'Input';
