import {
  Dispatch,
  FC,
  Fragment,
  ReactNode,
  SetStateAction,
  useRef
} from 'react';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react';
import { cn } from 'shared/lib';
import { BiX } from 'react-icons/bi';

export interface ModalProps {
  open?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  closeButton?: ReactNode;
  children?: ReactNode;
  modalTitle?: string | ReactNode;
  icon?: ReactNode;
  noPadding?: boolean;
  className?: string;
  rootClassName?: string;
  sizeClasses?: string;
  positionClasses?: string;
  noCrossIcon?: boolean;
}

const Modal: FC<ModalProps> = ({
  open,
  setOpen,
  closeButton,
  children,
  modalTitle,
  noPadding = false,
  className,
  rootClassName,
  sizeClasses = 'ag-w-11/12 lg:ag-w-2/3 xl:ag-w-7/12',
  positionClasses = 'ag-inset-0',
  noCrossIcon = false
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className={cn('ag-relative ag-z-50 ag-border', rootClassName)}
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="ag-ease-out ag-duration-300"
          enterFrom="ag-opacity-0"
          enterTo="ag-opacity-100"
          leave="ag-ease-in ag-duration-200"
          leaveFrom="ag-opacity-100"
          leaveTo="ag-opacity-0"
        >
          <div className="ag-fixed ag-inset-0 ag-bg-neutral-600 ag-bg-opacity-50 ag-transition-opacity" />
        </TransitionChild>
        <div className={cn(`ag-fixed ag-z-10`, positionClasses)}>
          <div className="ag-flex ag-min-h-full ag-justify-center ag-p-4 ag-text-center ag-items-center sm:ag-p-0">
            <TransitionChild
              as={Fragment}
              enter="ag-ease-out ag-duration-300"
              enterFrom="ag-opacity-0 ag-translate-y-4 sm:ag-translate-y-0 sm:ag-scale-95"
              enterTo="ag-opacity-100 ag-translate-y-0 sm:ag-scale-100"
              leave="ag-ease-in ag-duration-200"
              leaveFrom="ag-opacity-100 ag-translate-y-0 sm:ag-scale-100"
              leaveTo="ag-opacity-0 ag-translate-y-4 sm:ag-translate-y-0 sm:ag-scale-95"
            >
              <DialogPanel
                // className={cn(
                //   'ag-relative ag-transform ag-rounded-lg ag-bg-white ag-text-left ag-shadow-xl ag-transition-all sm:ag-my-8 sm:ag-max-w-screen-md ag-w-11/12 md:ag-w-11/12 lg:ag-w-2/3 xl:ag-w-7/12',
                //   className
                // )}
                className={cn(
                  'ag-relative ag-transform ag-rounded-lg ag-bg-white ag-text-left ag-shadow-xl ag-transition-all sm:ag-my-8',
                  sizeClasses,
                  className
                )}
              >
                {!noCrossIcon && (
                  <BiX
                    className="ag-absolute ag-top-3 ag-right-3 ag-text-3xl ag-text-neutral-gray ag-cursor-pointer ag-z-[99999]"
                    onClick={() => setOpen(false)}
                  />
                )}
                <div
                  className={
                    noPadding
                      ? ''
                      : 'ag-px-4 ag-pt-5 ag-pb-4 sm:ag-p-8 sm:ag-pb-4'
                  }
                >
                  <div className="sm:ag-flex sm:ag-items-start ag-relative">
                    <div className="ag-w-full ag-text-left">
                      <div className="ag-flex ag-flex-row ag-justify-center">
                        <DialogTitle as="h3" className="ag-flex">
                          <div className="ag-my-auto">{modalTitle}</div>
                        </DialogTitle>
                      </div>
                      <div className={modalTitle ? `ag-mt-2` : ''}>
                        {children}
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
