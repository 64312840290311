import Dropdown from 'components/shared/Dropdown';
import { BsFilter } from 'react-icons/bs';
import Checkbox from 'components/shared/Checkbox';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { TRatioReplicaType } from 'entities/tavus/replica';

export const FiltersDropdown = () => {
  const { setRatioFilters, ratioFilters, resetRatioFilters } = replicaStore();
  return (
    <Dropdown
      buttonClassName={
        'ag-w-10 ag-h-10 !ag-border-neutral-300 ag-flex ag-justify-center ag-items-center ag-rounded-md ag-border'
      }
      className={'!ag-border-neutral-300 ag-bg-white'}
      icon={<BsFilter size={20} className="w-5 h-5 ag-text-primary-600" />}
    >
      <div className="ag-pt-5 ag-pb-4 ag-text-neutral-900">
        <div className="ag-pl-3.5 ag-pr-4 ag-flex ag-justify-between ag-w-full ag-items-baseline">
          <button
            className={`ag-text-sm ag-font-inter ag-font-semibold ${
              ratioFilters.length
                ? 'text-secondary'
                : 'ag-text-gray-300 pointer-events-none'
            }`}
            onClick={() => {
              resetRatioFilters();
            }}
          >
            Reset
          </button>
        </div>
        <div className="ag-flex ag-flex-col ag-items-start ag-gap-4 ag-justify-evenly ag-mt-5 ag-pl-3.5 ag-pr-4">
          <Checkbox
            label="Full Body"
            onClick={(value) => setRatioFilters(value as TRatioReplicaType)}
            choosenValues={ratioFilters}
            value="Full Body"
          />
          <Checkbox
            label="Half Body"
            onClick={(value) => setRatioFilters(value as TRatioReplicaType)}
            choosenValues={ratioFilters}
            value="half body"
          />
          <Checkbox
            label="Video Call"
            onClick={(value) => setRatioFilters(value as TRatioReplicaType)}
            choosenValues={ratioFilters}
            value="Video Call"
          />
          <Checkbox
            label="Vertical Selfie"
            onClick={(value) => setRatioFilters(value as TRatioReplicaType)}
            choosenValues={ratioFilters}
            value="Vertical Selfie"
          />
        </div>
      </div>
    </Dropdown>
  );
};
