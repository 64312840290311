import { FC, useEffect } from 'react';
import { IAvatar } from 'shared/model';
import { usePlaySound } from 'features/play-sound';
import { Loader } from 'shared/ui';
import Dropdown from 'components/shared/Dropdown';
import { VscChevronDown } from 'react-icons/vsc';
import {
  DefaultVoice,
  Voice,
  VoiceFilter,
  voiceStore
} from 'entities/tavus/voice';

type Props = {
  defaultVoiceAudioSampleUrl: string;
  avatarGender?: IAvatar['gender'];
  voiceId: string;
  onChange: (voiceId: string) => void;
};

export const TavusChooseVoice: FC<Props> = ({
  avatarGender,
  defaultVoiceAudioSampleUrl,
  voiceId,
  onChange
}) => {
  const { filteredVoices, loading, fetchVoices } = voiceStore();

  useEffect(() => {
    fetchVoices();
  }, []);

  const handleChangeVoice = (id: string) => {
    onChange(id);
  };
  const { currentAudio, isPlaying, handlePauseAudio, handlePlayAudio } =
    usePlaySound();

  return (
    <>
      <div className="ag-mb-6">
        <VoiceFilter />
      </div>
      <div className="ag-h-full ag-flex ag-flex-col ag-gap-y-2 ag-overflow-auto ag-scrollbar-none ag-text-white ag-pb-20">
        {loading ? (
          <div className="ag-flex ag-justify-center">
            <Loader />
          </div>
        ) : filteredVoices?.length ? (
          <>
            <DefaultVoice
              voice={{
                name: 'Default',
                audio_sample_url: defaultVoiceAudioSampleUrl
              }}
              selected={voiceId === ''}
              onPause={handlePauseAudio}
              onPlay={handlePlayAudio}
              isPlaying={isPlaying}
              currentAudio={currentAudio}
              onHandleChange={(voice) => handleChangeVoice('')}
            />

            {filteredVoices.map((voice) => (
              <Voice
                key={voice.external_id + voice.gender}
                voice={voice}
                doesGenderMatch={
                  avatarGender
                    ? avatarGender.toLowerCase() === voice.gender
                    : true
                }
                selected={voiceId === voice.external_id}
                onPause={handlePauseAudio}
                onPlay={handlePlayAudio}
                currentAudio={currentAudio}
                isPlaying={isPlaying}
                onHandleChange={(voice) => handleChangeVoice(voice.external_id)}
              />
            ))}
          </>
        ) : (
          <div className="ag-text-base ag-w-full ag-mx-auto ag-p-3 ag-text-center">
            No voices found matching filters
          </div>
        )}
      </div>
    </>
  );
};
