import { FC, useState } from 'react';
import { Tabs } from 'shared/ui';
import { GeneralSettings } from './GeneralSettings';
import { InterfaceSettings } from './InterfaceSettings';
import { PrivacySettings } from './PrivacySettings';
import { Integrate } from './Integrate';
import { FormikProps } from 'formik';
import { ISettingsFormik } from '../../model';

const tabs = ['General', 'Interface', 'Privacy', 'Integrate'];

interface IProps {
  formik: FormikProps<ISettingsFormik>;
}
export const Settings: FC<IProps> = ({ formik }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const renderTab = () => {
    switch (activeTab) {
      case 'General':
        return <GeneralSettings formik={formik} />;
      case 'Interface':
        return <InterfaceSettings formik={formik} />;
      case 'Privacy':
        return <PrivacySettings formik={formik} />;
      case 'Integrate':
        return <Integrate formik={formik} />;
    }
  };

  return (
    <>
      <Tabs
        className="ag-overflow-auto"
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="ag-pt-8 ag-space-y-6 ag-max-h-[37.25rem] ag-overflow-auto ag-scrollbar-none">
        {renderTab()}
      </div>
    </>
  );
};
