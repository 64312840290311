import {
  DailyAudio,
  DailyProvider,
  DailyVideo,
  useParticipantIds
} from '@daily-co/daily-react';
import { conversationStore } from 'entities/tavus/conversation';
import {
  DAILY_CALL_STATES,
  events,
  transcriptionEvents
} from 'features/daily-call/model';
import { dailyCallStore } from 'features/daily-call/model/dailyCallStore';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { MAIN_ROUTE } from 'shared/model';
import { Loader, ToolTip } from 'shared/ui';
import { TavusControlBar } from 'widgets/call-controls';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { cn, useFullScreen, useMouseVisibility } from 'shared/lib';
import { GreenScreenVideo } from 'features/green-screen-video';
import { ToggleCaptions } from 'features/toggle-captions';
import { useTimerConversation } from 'entities/tavus/conversation/lib';
import { RiFullscreenExitLine, RiFullscreenFill } from 'react-icons/ri';
import {
  hotkeyDescriptions,
  useKeyboardFullScreenAndCaptionsBinding
} from 'widgets/call-controls/lib';

export const TavusConference = () => {
  const { toggleFullScreen, videoContainer, isFullScreen } = useFullScreen();
  const { currentConversation, endConversation } = conversationStore(
    (state) => ({
      currentConversation: state.currentConversation,
      endConversation: state.endConversation
    })
  );

  const {
    startHairCheck,
    callObject,
    handleNewMeetingState,
    currentState,
    startLeavingCall,
    joinCall,
    addMessage,
    messages,
    withCaptions,
    toggleCaptions
  } = dailyCallStore();

  useKeyboardFullScreenAndCaptionsBinding({
    toggleFullScreen,
    toggleCaptions
  });

  useEffect(() => {
    if (currentConversation) {
      startHairCheck(currentConversation.conversation_url);
    }
  }, [currentConversation]);

  useEffect(() => {
    if (!callObject) return;

    handleNewMeetingState();
    events.forEach((event) => callObject.on(event, handleNewMeetingState));

    callObject.on('transcription-message', (message) => addMessage(message));

    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
      transcriptionEvents.forEach((event) => callObject.off(event, () => {}));
    };
  }, [callObject]);

  const nav = useNavigate();

  const handleEndCall = () => {
    endConversation(messages);
    startLeavingCall();
    nav(MAIN_ROUTE);
  };

  useEffect(() => {
    return () => {
      handleEndCall();
    };
  }, []);

  useEffect(() => {
    if (currentState === DAILY_CALL_STATES.STATE_HAIRCHECK) {
      if (currentConversation) {
        joinCall(currentConversation?.conversation_url);
      }
    }
    if (currentState === DAILY_CALL_STATES.STATE_ERROR) {
      handleEndCall();
    }
  }, [currentState]);

  useTimerConversation(currentConversation, callObject);

  const isVisible = useMouseVisibility();
  if (!callObject) return null;
  return (
    <div
      ref={videoContainer}
      className={`ag-bg-neutral-900 ag-h-screen ag-flex ag-items-center ag-justify-center ag-relative`}
    >
      <DailyProvider callObject={callObject}>
        {[
          DAILY_CALL_STATES.STATE_JOINING,
          DAILY_CALL_STATES.STATE_JOINED,
          DAILY_CALL_STATES.STATE_ERROR
        ].includes(currentState) ? (
          <>
            <Video />

            <TavusControlBar onCallEnd={handleEndCall} />
            <div
              className={cn(
                'ag-flex ag-items-center ag-gap-6 ag-absolute ag-top-3.5 ag-right-3.5',
                isVisible ? 'ag-block' : 'ag-hidden'
              )}
            >
              <div className="ag-relative group">
                <ToggleCaptions
                  withCaptions={withCaptions}
                  toggleCaptions={toggleCaptions}
                />
                <ToolTip
                  className="ag-right-5 ag-top-5 ag-text-white"
                  text={hotkeyDescriptions.toggleCaptions}
                />
              </div>

              <button
                className="ag-text-white ag-relative group"
                onClick={toggleFullScreen}
              >
                {isFullScreen ? (
                  <RiFullscreenExitLine size={20} />
                ) : (
                  <RiFullscreenFill size={20} />
                )}
                <ToolTip
                  className="ag-right-5 ag-top-5"
                  text={hotkeyDescriptions.toggleFullScreen}
                />
              </button>
            </div>
          </>
        ) : (
          <Loader size={96} />
        )}

        <DailyAudio />
      </DailyProvider>
    </div>
  );
};

const Video = () => {
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });
  const ref = useRef<HTMLVideoElement>(null);
  const currentPersona = tavusPersonaStore((state) => state.currentPersona);

  return (
    <div className="ag-relative ag-w-full ag-h-full ag-flex ag-justify-center ">
      {remoteParticipantIds.length > 0 ? (
        remoteParticipantIds.map((id) => (
          <React.Fragment key={id}>
            <DailyVideo
              className={'ag-absolute -ag-z-10 ag-opacity-0'}
              ref={ref}
              automirror
              sessionId={id}
              type={'video'}
              playableStyle={{ width: '100%', height: '100%' }}
              fit="cover"
            />
            {currentPersona?.custom_background?.includes('video;') && (
              <video
                muted
                loop
                autoPlay
                className={
                  'ag-absolute ag-object-cover ag-h-[calc(100%-4.5rem)] ag-w-full'
                }
                src={currentPersona?.custom_background?.replace('video;', '')}
              />
            )}
            <GreenScreenVideo
              background={currentPersona?.custom_background}
              videoRef={ref}
              classNames={'ag-relative ag-w-full ag-h-screen ag-inset-0'}
            />
          </React.Fragment>
        ))
      ) : (
        <div
          className={
            'ag-w-full ag-h-full ag-flex ag-justify-center ag-items-center ag-pb-5 ag-flex-col'
          }
        >
          <Loader size={96} />
        </div>
      )}
    </div>
  );
};
