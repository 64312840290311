import React, { FC } from 'react';

interface IProps {
  id: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioButton: FC<IProps> = ({
  id,
  name,
  value,
  checked,
  onChange
}) => {
  return (
    <div className="ag-flex ag-items-center ag-space-x-2">
      <label>
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          className="ag-hidden"
        />
        <div
          className={`ag-w-5 ag-h-5 ag-rounded-full ag-border ag-flex ag-items-center ag-justify-center 
          ${checked ? 'ag-border-primary-600' : 'ag-border-neutral-300'}`}
        >
          {checked && (
            <div className="ag-w-2 ag-h-2 ag-bg-primary-600 ag-rounded-full"></div>
          )}
        </div>
      </label>
    </div>
  );
};
