import { BsFilter } from 'react-icons/bs';
import { FC } from 'react';
import Dropdown from 'components/shared/Dropdown';
import { GenderOptions, RatioOptions } from 'shared/model';
import Checkbox from 'components/shared/Checkbox';
import { cn } from 'shared/lib';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import Divider from 'components/Divider';
import { TRatioReplicaType } from 'entities/tavus/replica';

type Props = {
  classAvatarWrapper?: string;
};

export const ReplicaFilter: FC<Props> = ({ classAvatarWrapper = '' }) => {
  const { setRatioFilters, ratioFilters, resetRatioFilters } = replicaStore();
  return (
    <div
      className={cn('ag-z-50 ag-relative sm:ag-w-full ', classAvatarWrapper)}
    >
      <Dropdown
        label="Filters"
        icon={<BsFilter className="ag-size-5" />}
        className="ag-left-0 ag-min-w-[12rem] ag-w-full"
        buttonClassName="ag-w-full ag-py-2.5 ag-px-4 ag-flex"
      >
        <div className="ag-pt-5 ag-pb-4 ag-text-white">
          <div className="ag-pl-3.5 ag-pr-4 ag-flex ag-justify-between ag-w-full ag-items-baseline">
            <span className="ag-text-sm ag-font-semibold">Filters</span>
            <button
              className={cn(
                'text-sm font-inter ag-font-semibold ag-cursor-pointer',
                ratioFilters.length
                  ? 'ag-text-secondary'
                  : 'ag-text-white ag-pointer-events-none'
              )}
              onClick={() => {
                resetRatioFilters();
              }}
              type="button"
            >
              Reset
            </button>
          </div>
          <div className="ag-flex ag-flex-col ag-items-start ag-gap-4 ag-justify-evenly ag-pl-3.5 ag-pr-4 ag-mt-5">
            <Checkbox
              label="Full Body"
              onClick={(value) => setRatioFilters(value as TRatioReplicaType)}
              choosenValues={ratioFilters}
              value="Full Body"
            />
            <Checkbox
              label="Half Body"
              onClick={(value) => setRatioFilters(value as TRatioReplicaType)}
              choosenValues={ratioFilters}
              value="half body"
            />
            <Checkbox
              label="Video Call"
              onClick={(value) => setRatioFilters(value as TRatioReplicaType)}
              choosenValues={ratioFilters}
              value="Video Call"
            />
            <Checkbox
              label="Vertical Selfie"
              onClick={(value) => setRatioFilters(value as TRatioReplicaType)}
              choosenValues={ratioFilters}
              value="Vertical Selfie"
            />
          </div>
          {/* <Divider className="ag-w-full ag-my-2 ag-border-neutral-600" /> */}
          {/* TODO: add filter by gender */}
          {/* <div className="ag-flex ag-flex-col ag-items-start ag-gap-4 ag-justify-evenly ag-pl-3.5 ag-pr-4 ag-mt-5">
            <Checkbox
              label="Male"
              onClick={(value) => setGenderFilters(value as GenderOptions)}
              choosenValues={genderFilters}
              value="male"
            />
            <Checkbox
              label="Female"
              onClick={(value) => setGenderFilters(value as GenderOptions)}
              choosenValues={genderFilters}
              value="female"
            />
          </div> */}
        </div>
      </Dropdown>
    </div>
  );
};
