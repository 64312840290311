import Divider from 'components/Divider';
import { ISettingsFormik } from '../../model';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { Field, Switch } from 'shared/ui';

interface IProps {
  formik: FormikProps<ISettingsFormik>;
}
export const GeneralSettings: FC<IProps> = ({ formik }) => {
  return (
    <>
      <Field label="Video Agent Name" name="videoAgentName" formik={formik} />
      <Field label="Description" name="description" formik={formik} />
      <Field label="Button" name="buttonText" formik={formik} />
      <Divider />
      <div className="ag-flex ag-items-center ag-justify-between">
        <span className="ag-text-neutral-700 ag-font-medium">
          Powered by Yepic AI
        </span>
        <Switch
          onChange={(value) => formik.setFieldValue('poweredBy', value)}
          checked={formik.values.poweredBy}
        />
      </div>
    </>
  );
};
