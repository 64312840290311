import { differenceInSeconds } from 'date-fns';

export const calculateDuration = (from: string, to: string): number | null => {
  if (!from || !to) return null;

  const start = new Date(from.includes('Z') ? from : `${from}Z`);
  const end = new Date(to.includes('Z') ? to : `${to}Z`);

  return differenceInSeconds(end, start);
};
