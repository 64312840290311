import { FC, useEffect, useMemo, useState } from 'react';
import {
  conversationStore,
  IConversationItem,
  processJSONTranscriptionsToText
} from 'entities/tavus/conversation';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { DataTable, Loader } from 'shared/ui';
import { calculateDuration, exportToCSV, formatDate } from 'shared/lib';
import { getConversationsColumns } from './conversations-columns';
import { fullNameSelector, userStore } from 'entities/user';

interface IProps {
  personaId: string | undefined;
}

export const ConversationsTable: FC<IProps> = ({ personaId }) => {
  const [currentConversationLoading, setCurrentConversationLoading] = useState<
    string | null
  >(null);
  const fullName = userStore(fullNameSelector);
  const {
    conversationsList,
    conversationsListLoading,
    fetchAll,
    transcriptionsLoading,
    getTranscriptions,
    setViewTranscriptionsModal
  } = conversationStore((state) => ({
    conversationsList: state.conversationsList,
    conversationsListLoading: state.conversationsListLoading,
    fetchAll: state.fetchAll,
    transcriptionsLoading: state.transcriptionsLoading,
    getTranscriptions: state.getTranscriptions,
    setViewTranscriptionsModal: state.setViewTranscriptionsModal
  }));

  const { fetchOne, currentPersona, setCurrentPersona } = tavusPersonaStore(
    (state) => state
  );

  const handleOpenTranscriptionsModal = (conversation: IConversationItem) => {
    setViewTranscriptionsModal(conversation);
  };

  useEffect(() => {
    if (personaId) {
      fetchOne(personaId).then((persona) => {
        if (persona) setCurrentPersona(persona);
      });
      fetchAll(personaId);
    }
  }, [personaId, fetchOne, setCurrentPersona, fetchAll]);

  useEffect(() => {
    if (!transcriptionsLoading) setCurrentConversationLoading(null);
  }, [transcriptionsLoading]);

  const [rowSelection, setRowSelection] = useState<IConversationItem[]>([]);

  const exportConversations = () => {
    exportToCSV(
      rowSelection.map((item) => ({
        persona_name: currentPersona?.persona_name,
        status: item.status,
        created_at: formatDate(item.created_at),
        updated_at: formatDate(item.updated_at),
        json_transcriptions: item.external_transcripts
          ? JSON.stringify(item.external_transcripts)
          : 'N/A',
        text_transcriptions: item.external_transcripts
          ? processJSONTranscriptionsToText({
              messages: item.external_transcripts,
              userName: fullName,
              assistantName: currentPersona?.persona_name
            })
          : 'N/A',
        duration:
          item.created_at && item.updated_at
            ? `${calculateDuration(item.created_at, item.updated_at)} sec`
            : 'N/A'
      })),
      'conversations'
    );
  };

  const columns = useMemo(
    () =>
      getConversationsColumns({
        currentConversationLoading,
        setCurrentConversationLoading,
        getTranscriptions,
        handleOpenTranscriptionsModal,
        exportConversations,
        userName: fullName,
        assistantName: currentPersona?.persona_name
      }),
    [
      currentConversationLoading,
      setCurrentConversationLoading,
      getTranscriptions,
      fullName,
      currentPersona,
      handleOpenTranscriptionsModal,
      exportConversations
    ]
  );

  return conversationsListLoading ? (
    <div className="ag-w-full ag-h-screen ag-flex ag-items-center ag-justify-center -ag-pt-24">
      <Loader size={96} />
    </div>
  ) : (
    <div className="ag-p-10">
      <div className="ag-mb-4 ag-flex ag-justify-between">
        <span className="ag-font-semibold ag-text-xl">
          Conversations of {currentPersona?.persona_name}
        </span>
      </div>
      <DataTable
        getRowClassName={(row) =>
          row.status === 'active' ? 'ag-bg-gray-200' : ''
        }
        clientFiltering
        enableRowSelection
        onChangeSelection={setRowSelection}
        enablePagination
        columns={columns}
        data={conversationsList}
      />
    </div>
  );
};
