import { useEffect, useState } from 'react';
import {
  getSessionTime,
  ITimerConfig,
  TIME_LIMIT
} from 'entities/tavus/conversation';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { logger } from 'shared/lib';

const formatTime = (duration: number) => {
  if (!duration || duration < 0) return '0:00';

  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const Timer = () => {
  const currentPersona = tavusPersonaStore((state) => state.currentPersona);
  const [timerDuration, setTimerDuration] = useState(0);
  const [time, setTime] = useState(() => {
    const sessionTime = getSessionTime();
    if (currentPersona) {
      try {
        const timerConfig: ITimerConfig | null = JSON.parse(
          currentPersona?.timer_config
        );
        if (timerConfig?.duration) {
          setTimerDuration(timerConfig.duration || TIME_LIMIT);
        }
      } catch (e) {
        logger.debug('timer config', e);
      }
    }
    return timerDuration - sessionTime;
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const sessionTime = getSessionTime();
      setTime(timerDuration - sessionTime);
    }, 1000);
    return () => clearInterval(interval);
  }, [timerDuration]);

  return (
    <div className="px-4 py-3 text-sm font-medium">
      Time Remaining: {timerDuration ? formatTime(time) : ''}
    </div>
  );
};
