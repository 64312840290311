import Divider from 'components/Divider';
import Modal from 'components/shared/Modal';

import { bubbleIcons, ISettingsFormik, validationSchema } from '../model';

import {
  EIntegrationSettingsType,
  tavusPersonaStore
} from 'entities/tavus/persona';
import { Preview } from './Preview';
import { Settings } from './Settings';
import { useFormik } from 'formik';
import { FilledButton } from 'shared/ui';
import { LuTrash2 } from 'react-icons/lu';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { cn } from 'shared/lib';
import { toast } from 'react-toastify';
import { DeleteSettingsModal } from './DeleteSettingsModal';
import { useState } from 'react';

export const TavusEmbedModal = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const {
    togglePersonaEmbedModal,
    personaEmbedModal,
    tavusPersonas,
    updateIntegrationSettings,
    updateIntegrationSettingsLoading,
    deleteIntegrationSettingsLoading
  } = tavusPersonaStore((state) => ({
    togglePersonaEmbedModal: state.togglePersonaEmbedModal,
    fetchOne: state.fetchOne,
    personaEmbedModal: state.personaEmbedModal,
    tavusPersonas: state.tavusPersonas,
    updateIntegrationSettings: state.updateIntegrationSettings,
    updateIntegrationSettingsLoading: state.updateIntegrationSettingsLoading,
    deleteIntegrationSettingsLoading: state.deleteIntegrationSettingsLoading
  }));

  const { replicas } = replicaStore((state) => ({
    replicas: state.replicas
  }));

  const currentPersona = tavusPersonas.find(
    (persona) => persona.id === personaEmbedModal?.personaId
  );

  const defaultReplicaId = currentPersona?.default_replica_id;
  const currentSettings = currentPersona?.integration_settings?.find(
    (item) => item.type === personaEmbedModal?.type
  );

  const initialValues: ISettingsFormik = {
    videoAgentName:
      currentSettings?.video_agent_name ?? currentPersona?.persona_name ?? '',
    description:
      currentSettings?.description ?? 'Ask me anything about our products',
    buttonText: currentSettings?.button_text ?? 'Start Chat',
    poweredBy: currentSettings?.powered_by ?? true,
    bubbleIconCustom: currentSettings?.bubble_icon_custom || false,
    mainColor: currentSettings?.main_color ?? '#C34DFF',
    morePersonalisedColors: currentSettings?.more_personalised_colors ?? false,
    welcomeButtonColor: currentSettings?.welcome_button_color ?? '#C34DFF',
    bubbleBackgroundColor:
      currentSettings?.bubble_background_color ?? '#C34DFF',
    bubbleIconColor: currentSettings?.bubble_icon_color ?? '#FFFFFF',
    bubbleIconVariant:
      currentSettings?.bubble_icon_variant || bubbleIcons.first.variant,
    bubbleIconUrl: currentSettings?.bubble_icon_url ?? '',
    darkMode: currentSettings?.dark_mode ?? false,
    font: currentSettings?.font ?? 'Inter',
    camera: currentSettings?.camera ?? true,
    languageSelection: currentSettings?.language_selection ?? true,
    captionsByDefault: currentSettings?.captions_by_default ?? false,
    domains: currentSettings?.domains ?? []
  };

  const formik = useFormik<ISettingsFormik>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (data) => {
      await updateIntegrationSettings(personaEmbedModal?.personaId!, {
        video_agent_name: data.videoAgentName,
        description: data.description,
        button_text: data.buttonText,
        powered_by: data.poweredBy,
        main_color: data.mainColor,
        more_personalised_colors: data.morePersonalisedColors,
        welcome_button_color: data.welcomeButtonColor,
        bubble_background_color: data.bubbleBackgroundColor,
        bubble_icon_color: data.bubbleIconColor,
        bubble_icon_url: data.bubbleIconUrl,
        bubble_icon_variant: data.bubbleIconVariant,
        bubble_icon_custom: data.bubbleIconCustom,
        dark_mode: data.darkMode,
        font: data.font,
        camera: data.camera,
        language_selection: data.languageSelection,
        captions_by_default: data.captionsByDefault,
        domains: data.domains,
        type: personaEmbedModal?.type!
      });
      toast('Changes saved successfully.');
    }
  });

  const currentReplica = replicas.find(
    (replica) => replica.replica_id === defaultReplicaId
  );

  const thumbnail =
    (currentReplica?.thumbnail_urls?.length &&
      currentReplica.thumbnail_urls[0]) ||
    currentReplica?.thumbnail_url;

  const videoThumbnail = currentReplica?.thumbnail_video_url;

  return (
    <Modal
      open={Boolean(personaEmbedModal)}
      setOpen={() => togglePersonaEmbedModal(null)}
      noPadding
      positionClasses="ag-inset-0 ag-overflow-auto ag-z-10 ag-p-4"
      className={cn('!ag-w-full sm:!ag-w-[983px]')}
    >
      <div className="ag-p-6">
        <h2 className="ag-text-lg ag-font-semibold ag-text-neutral-900">
          {personaEmbedModal?.type === EIntegrationSettingsType.WIDGET
            ? 'Widget Video Agent'
            : 'Embed Video Agent'}
        </h2>
      </div>
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <div className="sm:ag-block lg:ag-grid ag-grid-cols-[40%_1fr]">
          <div className="ag-border-r ag-border-neutral-200 ag-py-4 ag-px-6">
            <Settings formik={formik} />
          </div>
          <div className="ag-hidden lg:ag-block">
            <Preview
              videThumbnail={videoThumbnail}
              formik={formik}
              thumbnail={thumbnail}
            />
          </div>
        </div>
        <div className="ag-flex ag-justify-between ag-items-center ag-p-6 ag-border-t ag-border-neutral-200">
          <button
            type="button"
            onClick={() => {
              setOpenDeleteModal(true);
            }}
            disabled={deleteIntegrationSettingsLoading || !currentSettings}
            className={cn(
              'ag-flex ag-items-center ag-text-red-700 ag-font-semibold ag-gap-2',
              deleteIntegrationSettingsLoading || !currentSettings
                ? 'ag-opacity-70'
                : 'ag-cursor-pointer'
            )}
          >
            <LuTrash2 />
            <span>Delete</span>
          </button>
          <div className="ag-flex ag-gap-3">
            <button
              type="button"
              onClick={() => togglePersonaEmbedModal(null)}
              className="ag-bg-white ag-text-neutral-700 ag-rounded-md ag-text-sm/5 ag-font-medium ag-px-4 ag-py-2.5 ag-border ag-border-neutral-200"
            >
              Cancel
            </button>
            <FilledButton
              disabled={updateIntegrationSettingsLoading}
              className="ag-h-full ag ag-w-24 ag-py-2.5 ag-font-semibold"
              type="submit"
            >
              Save
            </FilledButton>
          </div>
        </div>
      </form>
      <DeleteSettingsModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
      />
    </Modal>
  );
};
