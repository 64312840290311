import { useEffect, useRef, MutableRefObject } from 'react';

export const useDynamicObjectFit = (
  videoRef: MutableRefObject<HTMLVideoElement | null>
) => {
  const resizeFrame = useRef<number | null>(null);

  const updateObjectFit = () => {
    const video = videoRef.current;
    if (!video || video.videoWidth === 0 || video.videoHeight === 0) return;

    const videoRatio = video.videoWidth / video.videoHeight;
    const containerRatio = window.innerWidth / window.innerHeight;

    video.style.objectFit = containerRatio < videoRatio ? 'cover' : 'contain';
  };

  const requestResizeUpdate = () => {
    if (resizeFrame.current !== null) return;
    resizeFrame.current = requestAnimationFrame(() => {
      updateObjectFit();
      resizeFrame.current = null;
    });
  };

  useEffect(() => {
    const video = videoRef.current;

    const handleLoadedMetadata = () => updateObjectFit();

    if (video) {
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
    }
    window.addEventListener('resize', requestResizeUpdate);

    return () => {
      if (video) {
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
      window.removeEventListener('resize', requestResizeUpdate);

      if (resizeFrame.current !== null) {
        cancelAnimationFrame(resizeFrame.current);
      }
    };
  }, [videoRef]);
};
