import React from 'react';
import { FormikProps } from 'formik';
import { cn } from 'shared/lib';

type FieldProps = {
  label?: string;
  name: string;
  containerClassName?: string;
  formik: FormikProps<any>;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Field: React.FC<FieldProps> = ({
  label,
  name,
  formik,
  containerClassName,
  ...props
}) => {
  const field = formik.getFieldProps(name);
  const error = (formik.touched[name] && formik.errors[name]) as
    | string
    | undefined;

  return (
    <div className={cn('flex flex-col ag-gap-1.5', containerClassName)}>
      <label
        htmlFor={name}
        className="ag-text-sm ag-font-medium ag-text-neutral-700"
      >
        {label || ''}
      </label>
      <input
        {...field}
        {...props}
        id={name}
        className={`border p-2 rounded-md ${
          error ? 'border-red-500' : 'border-gray-300'
        }`}
      />
      {error && <span className="text-red-500 text-xs">{error}</span>}
    </div>
  );
};
