import { bubbleIcons, ISettingsFormik } from '../../model';
import { FormikProps } from 'formik';
import { FC, useState } from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { FilledButton, Tabs } from 'shared/ui';
import { ReactComponent as ExpandIcon } from 'shared/ui/img/ExpandIcon.svg';
import { cn } from 'shared/lib';
import { ReactComponent as KeyboardCircleIcon } from 'shared/ui/img/keyboardCircle.svg';
import { ReactComponent as DotsCircleIcon } from 'shared/ui/img/dotsCircle.svg';
import SelectInput from 'components/shared/SelectInput';
import { STT_LOCALES_NAMES } from 'shared/model';
import { MdOutlineTranslate } from 'react-icons/md';
import {
  EIntegrationSettingsType,
  tavusPersonaStore
} from 'entities/tavus/persona';

interface IProps {
  thumbnail?: string;
  videThumbnail?: string;
  formik: FormikProps<ISettingsFormik>;
}

export const Preview: FC<IProps> = ({ thumbnail, formik, videThumbnail }) => {
  const { personaEmbedModal, personas } = tavusPersonaStore((state) => ({
    personaEmbedModal: state.personaEmbedModal,
    personas: state.tavusPersonas
  }));
  const isWidget = personaEmbedModal?.type === EIntegrationSettingsType?.WIDGET;
  const tabs = ['Welcome', 'Call', ...(isWidget ? ['Minimised'] : [])];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const currentPersona = personas.find(
    (item) => item.id === personaEmbedModal?.personaId
  );
  const defaultLanguage = currentPersona?.default_language || 'english';
  return (
    <>
      <div className="ag-pt-4 ag-px-4 ag-relative">
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        <div className="ag-w-full ag-absolute ag-left-0 ag-bottom-0 ag-h-[1px] ag-bg-neutral-200"></div>
      </div>
      <div
        style={{ fontFamily: formik.values.font }}
        className={cn(
          'ag-h-[39rem] ag-flex ag-justify-center ag-items-center ag-bg-neutral-100 ag-relative ag-overflow-auto ag-max-w-[835px]'
        )}
      >
        {activeTab !== tabs[2] ? (
          <div
            className={cn(
              'ag-border ag-border-neutral-200 ag-rounded-2xl ag-max-w-[375px] ag-h-[37.5rem]',
              formik.values.darkMode ? 'ag-bg-neutral-900' : 'ag-bg-white'
            )}
          >
            <div
              className={cn(
                'ag-text-center ag-flex ag-items-center ag-border-b ag-text-xs ag-py-3 ag-px-4',
                formik.values.darkMode
                  ? 'ag-text-white ag-border-neutral-600'
                  : 'ag-text-neutral-900 ag-border-neutral-200',
                isWidget ? 'ag-justify-between' : 'ag-justify-center'
              )}
            >
              {isWidget && (
                <ExpandIcon
                  className={cn(
                    formik.values.darkMode
                      ? 'ag-text-white'
                      : 'ag-text-gray-600'
                  )}
                />
              )}

              <div>
                <div className={'ag-font-semibold'}>
                  {formik.values.videoAgentName}
                </div>
                {formik.values.description && (
                  <div>{formik.values.description}</div>
                )}
                {formik.values.poweredBy && (
                  <div>
                    Powered by{' '}
                    <span className="ag-font-semibold">Yepic AI</span>
                  </div>
                )}
              </div>
              {isWidget && (
                <IoChevronDown
                  className={cn(
                    formik.values.darkMode
                      ? 'ag-text-white'
                      : 'ag-text-gray-600'
                  )}
                  size={24}
                />
              )}
            </div>
            <div
              className={cn(
                activeTab !== 'Call' ? 'ag-py-[85.5px] ag-px-16' : ''
              )}
            >
              <div
                className={cn(
                  activeTab !== 'Call'
                    ? 'ag-rounded-full ag-overflow-hidden ag-aspect-square ag-max-w-[343px] ag-mb-7'
                    : 'ag-relative'
                )}
              >
                {activeTab === 'Call' ? (
                  <>
                    <div
                      className="ag-w-full ag-h-full"
                      style={{ aspectRatio: '5/6' }}
                    >
                      <video
                        autoPlay
                        muted
                        loop
                        style={{ aspectRatio: '5/6' }}
                        className="ag-w-full ag-h-full ag-object-cover ag-object-top"
                        src={videThumbnail}
                      />
                    </div>

                    {formik.values.captionsByDefault && (
                      <div className="ag-absolute ag-bottom-4 ag-left-4 ag-rounded-lg ag-bg-black/60 ag-text-white ag-z-50 ag-p-2">
                        Welcome
                      </div>
                    )}
                  </>
                ) : activeTab === 'Welcome' ? (
                  <img
                    className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top"
                    src={thumbnail}
                  />
                ) : null}
              </div>
              {formik.values.languageSelection && activeTab === tabs[0] && (
                <SelectInput
                  name="select-language"
                  disabled
                  options={STT_LOCALES_NAMES}
                  value={
                    STT_LOCALES_NAMES.find(
                      (item) => item.toLocaleLowerCase() === defaultLanguage
                    )!
                  }
                  onChange={() => {}}
                  optionsWrapperClassName={cn(
                    formik.values?.darkMode
                      ? '!ag-bg-neutral-900 !ag-border-neutral-600'
                      : '!ag-bg-white !ag-border-neutral-200'
                  )}
                  className={cn(
                    'ag-h-7 ag-mb-4 !ag-cursor-default',
                    formik.values?.darkMode
                      ? '!ag-bg-neutral-900 !ag-border-neutral-600 !ag-text-white-700'
                      : '!ag-bg-white !ag-border-neutral-200 !ag-text-neutral-700'
                  )}
                  wrapperClassName={cn(
                    formik.values?.darkMode
                      ? '!ag-bg-neutral-900 !ag-text-white-700 !ag-border-neutral-600'
                      : '!ag-bg-white !ag-text-neutral-700 !ag-border-neutral-200',
                    'ag-cursor-default'
                  )}
                  icon={<MdOutlineTranslate className="ag-size-6 ag-p-1" />}
                  optionsClassName={cn(
                    'ag-text-sm ag-py-1',
                    formik.values?.darkMode
                      ? 'ag-text-white-700 ag-border-neutral-600 hover:ag-bg-neutral-600/80'
                      : 'ag-text-neutral-700 ag-border-neutral-200  hover:ag-bg-neutral-200/80 dark:hover:ag-bg-[#262626]/80'
                  )}
                  activeClassName={cn(
                    formik.values?.darkMode
                      ? '!ag-bg-[#262626]'
                      : '!ag-bg-neutral-100'
                  )}
                />
              )}

              {activeTab !== 'Call' && (
                <FilledButton
                  disabled
                  style={{
                    backgroundColor: formik.values.morePersonalisedColors
                      ? formik.values.welcomeButtonColor
                      : formik.values.mainColor
                  }}
                  className={cn(
                    'ag-w-full ag-py-2',
                    !formik.values.languageSelection && 'ag-mb-7'
                  )}
                >
                  {formik.values.buttonText}
                </FilledButton>
              )}
            </div>

            {activeTab === 'Call' && (
              <div
                className={
                  'ag-flex ag-items-center ag-p-3 ag-border-t ag-border-neutral-300 ag-gap-2'
                }
              >
                <DotsCircleIcon />
                <div className="ag-h-10 ag-w-[1px] ag-bg-neutral-300"></div>
                <KeyboardCircleIcon />
                {formik.values.camera && (
                  <svg
                    className="ag-size-10"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="19.7224" cy="20" r="13.6111" fill="#525252" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM30.6375 26.2568C30.8567 26.3695 31.1215 26.3565 31.3293 26.225C31.5386 26.0936 31.6667 25.861 31.6667 25.6111V15.5C31.6667 15.2501 31.5386 15.0176 31.3279 14.8861C31.1172 14.7532 30.8553 14.7431 30.636 14.8543L25.9723 17.2203V15.5C25.9723 14.3055 25.0142 13.3333 23.8369 13.3333H11.0244C9.84703 13.3333 8.88894 14.3055 8.88894 15.5V25.6111C8.88894 26.8057 9.84703 27.7778 11.0244 27.7778H23.8369C25.0142 27.7778 25.9723 26.8057 25.9723 25.6111V23.8908L30.6375 26.2568Z"
                      fill="#E5E5E5"
                    />
                  </svg>
                )}

                <div className="ag-flex ag-items-center ag-rounded-full ag-bg-neutral-100 ag-border ag-border-neutral-300 ag-relative">
                  <button
                    disabled
                    type="button"
                    className={cn(
                      'ag-size-10 ag-p-2 ag-border ag-bg-neutral-200 ag-border-neutral-300 bg-neutral-200 ag-text-neutral-600 ag-rounded-full ag-relative -ag-left-[1px] ag-flex ag-items-center ag-justify-center'
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 56 56"
                      fill="none"
                      className="ag-size-full"
                    >
                      <g clipPath="url(#clip0_1358_2011)">
                        <circle
                          cx="27.9999"
                          cy="28.0001"
                          r="19.0556"
                          fill="#525252"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0ZM37.3516 29.2572C36.9929 31.5247 35.8152 33.5345 34.1317 34.9734C32.4793 36.3856 30.3361 37.2408 28 37.2408C25.6639 37.2408 23.5207 36.3855 21.8683 34.9734C20.1848 33.5345 19.0071 31.5247 18.6484 29.2572C18.5313 28.5001 17.8225 27.9813 17.0655 28.0984C16.3084 28.2156 15.7896 28.9243 15.9067 29.6814C16.3727 32.6272 17.8923 35.2295 20.0622 37.084C21.9627 38.7082 24.359 39.7645 26.9816 39.9836V42.5644H23.2146C22.4455 42.5644 21.822 43.1879 21.822 43.9569C21.822 44.7261 22.4454 45.3496 23.2146 45.3496H33.5339C34.3031 45.3496 34.9265 44.7261 34.9265 43.9569C34.9265 43.1878 34.303 42.5644 33.5339 42.5644H29.767V39.8976C32.0963 39.5566 34.2197 38.5524 35.9378 37.0841C38.1077 35.2296 39.6274 32.6273 40.0933 29.6815C40.2104 28.9244 39.6916 28.2157 38.9345 28.0985C38.1776 27.9813 37.4687 28.5001 37.3516 29.2572ZM28 10.6505C26.1292 10.6505 24.4296 11.4148 23.1982 12.6462C21.9669 13.8775 21.2026 15.5772 21.2026 17.4479V27.778C21.2026 29.6487 21.967 31.3485 23.1982 32.5798C24.4296 33.8111 26.1294 34.5754 28.0001 34.5754C29.8709 34.5754 31.5705 33.8111 32.8019 32.5798C34.0332 31.3484 34.7975 29.6486 34.7975 27.778V17.4479C34.7975 15.5772 34.0332 13.8775 32.8019 12.6462C31.5704 11.4148 29.8708 10.6505 28 10.6505Z"
                          fill="#E5E5E5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1358_2011">
                          <rect width="56" height="56" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <button
                    disabled
                    type="button"
                    className="ag-text-neutral-600 ag-pl-1.5 ag-pr-3"
                  >
                    <IoChevronUp />
                  </button>
                </div>
                <svg
                  className="ag-size-10 ag-ml-12"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 56 56"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1358_2025)">
                    <path
                      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
                      fill="#DC2626"
                    />
                    <path
                      d="M25.301 30.6687C29.1466 34.5143 32.9157 36.0744 34.2732 34.7169L36.1374 32.8531C36.7541 32.2365 37.5905 31.8901 38.4625 31.8901C39.3346 31.8901 40.171 32.2365 40.7876 32.8531L44.9728 37.0382C45.5894 37.6549 45.9358 38.4913 45.9358 39.3633C45.9358 40.2354 45.5894 41.0718 44.9728 41.6884L41.7121 44.9491C38.6312 48.03 29.5189 43.9175 20.7856 35.1845C16.4211 30.8179 13.5585 26.7049 11.7083 22.8374C9.85812 18.9699 9.48319 15.7976 11.0227 14.258L14.2834 10.9974C14.9001 10.3807 15.7365 10.0343 16.6085 10.0343C17.4806 10.0343 18.317 10.3807 18.9336 10.9974L23.1187 15.1812C23.7354 15.7978 24.0818 16.6342 24.0818 17.5063C24.0818 18.3783 23.7354 19.2147 23.1187 19.8314L21.2532 21.6999C19.8957 23.0562 21.4554 26.8248 25.301 30.6687Z"
                      fill="#EEEFEE"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1358_2025">
                      <rect width="56" height="56" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            )}
          </div>
        ) : (
          <div className="ag-absolute ag-right-3 ag-bottom-3">
            {formik.values.bubbleIconCustom ? (
              <img
                src={formik.values.bubbleIconUrl}
                className="ag-w-10 ag-h-10 ag-object-cover"
              />
            ) : (
              Object.values(bubbleIcons)
                .filter(
                  (item) => item.variant === formik.values.bubbleIconVariant
                )
                .map((item) =>
                  item.draw(
                    ...(formik.values.morePersonalisedColors
                      ? [
                          formik.values.bubbleBackgroundColor,
                          formik.values.bubbleIconColor
                        ]
                      : [])
                  )
                )
            )}
          </div>
        )}
      </div>
    </>
  );
};
