import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Bg from './bg.jpg';
import Hls from 'hls.js';
import { useParams } from 'react-router';
import { conversationStore } from 'entities/tavus/conversation';
import { logger } from 'shared/lib';

export const VideoFeedback = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1);
  const [streamURL, setStreamURL] = useState<string | null>(null);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const targetColor = useRef<any>({ r: 0, g: 0, b: 0 });
  const { id: conversationId } = useParams();
  const { getStreamLinkVideo } = conversationStore((state) => ({
    getStreamLinkVideo: state.getStreamLinkVideo
  }));

  useEffect(() => {
    if (conversationId) {
      getStreamLinkVideo(conversationId).then((response) => {
        if (response) {
          setStreamURL(response);
        }
      });
    }
  }, [conversationId]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    if (Hls.isSupported() && streamURL) {
      const hls = new Hls();
      hls.loadSource(streamURL);
      hls.attachMedia(video);
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = streamURL!;
    }
  }, [streamURL]);

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (!video || !canvas) return;

    ctxRef.current = canvas.getContext('2d');
    const ctx = ctxRef.current;
    if (!ctx) return;

    video.addEventListener(
      'play',
      () => {
        setTimeout(() => {
          render();
        }, 500);
      },
      { once: true }
    );
  }, []);

  function render() {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = ctxRef.current;
    if (video && canvas && ctx) {
      canvas.width = video?.videoWidth;
      canvas.height = video?.videoHeight;
      if (canvas.width && canvas.height) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const frame = ctx.getImageData(10, 10, canvas.width, canvas.height);
        targetColor.current = getPixelColor(frame, 5, 5);
        removeBackground();
      }
    }
    requestAnimationFrame(render);
  }

  function removeBackground() {
    const canvas = canvasRef.current;
    const ctx = ctxRef.current;
    if (!canvas || !ctx) return;

    const frame = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = frame.data;

    const hardThreshold = 30;
    const softThreshold = 150;
    const featheringStrength = 4;

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];

      const diff = Math.sqrt(
        Math.pow(r - targetColor.current.r, 2) +
          Math.pow(g - targetColor.current.g, 2) +
          Math.pow(b - targetColor.current.b, 2)
      );

      if (diff < hardThreshold) {
        data[i + 3] = 0;
      } else if (diff < softThreshold) {
        data[i + 3] =
          Math.pow(
            (diff - hardThreshold) / (softThreshold - hardThreshold),
            featheringStrength
          ) * 255;
      }
    }

    ctx.putImageData(frame, 0, 0);

    applyErosion(2);
  }

  function applyErosion(passes = 1) {
    const canvas = canvasRef.current;
    const ctx = ctxRef.current;
    if (!canvas || !ctx) return;

    const width = canvas.width;
    const height = canvas.height;

    for (let pass = 0; pass < passes; pass++) {
      const frame = ctx.getImageData(0, 0, width, height);
      const data = frame.data;

      for (let y = 1; y < height - 1; y++) {
        for (let x = 1; x < width - 1; x++) {
          const i = (y * width + x) * 4;

          if (data[i + 3] > 0) {
            let transparentNeighbors = 0;

            for (let dy = -1; dy <= 1; dy++) {
              for (let dx = -1; dx <= 1; dx++) {
                if (dx === 0 && dy === 0) continue;
                const ni = ((y + dy) * width + (x + dx)) * 4;
                if (data[ni + 3] === 0) {
                  transparentNeighbors++;
                }
              }
            }

            if (transparentNeighbors > 2) {
              data[i + 3] = Math.max(0, data[i + 3] - 80);
            }
          }
        }
      }

      ctx.putImageData(frame, 0, 0);
    }
  }

  function getPixelColor(imageData: ImageData, x: number, y: number) {
    const index = (y * imageData.width + x) * 4;
    return {
      r: imageData.data[index],
      g: imageData.data[index + 1],
      b: imageData.data[index + 2]
    };
  }

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (!video) return;

    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handleVolumeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const video = videoRef.current;
    if (!video) return;
    video.volume = parseFloat(e.target.value);
    setVolume(parseFloat(e.target.value));
  };

  const handleProgressChange = (e: ChangeEvent<HTMLInputElement>) => {
    const video = videoRef.current;
    if (!video) return;

    const newTime = (parseFloat(e.target.value) / 100) * video.duration;
    requestAnimationFrame(() => {
      video.currentTime = newTime;
    });

    setProgress(parseFloat(e.target.value));
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const updateProgress = () => {
      setProgress((video.currentTime / video.duration) * 100);
    };
    video.addEventListener('timeupdate', updateProgress);
    return () => video.removeEventListener('timeupdate', updateProgress);
  }, []);

  const toggleFullscreen = () => {
    const container = canvasRef.current?.parentElement;
    if (!container) return;

    if (!document.fullscreenElement) {
      container.requestFullscreen().then(() => setIsFullscreen(true));
    } else {
      document.exitFullscreen().then(() => setIsFullscreen(false));
    }
  };

  return (
    <div className="flex flex-col items-center text-white font-sans relative">
      <canvas
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
        width={'1920'}
        height={'1080'}
        ref={canvasRef}
        className="cursor-pointer h-screen"
      />
      <div className="fixed bottom-8 flex items-center gap-2 bg-black/70 p-3 rounded-lg">
        <button onClick={togglePlayPause} className="cursor-pointer">
          {isPlaying ? '⏸️' : '▶️'}
        </button>
        <input
          type="range"
          min="0"
          max="100"
          value={progress}
          step="0.1"
          onChange={handleProgressChange}
          className="cursor-pointer"
        />
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          className="cursor-pointer"
        />
        <button onClick={toggleFullscreen} className="cursor-pointer">
          {isFullscreen ? '⛶' : '🔲'}
        </button>
      </div>
      <video ref={videoRef} playsInline className="hidden" />
    </div>
  );
};
