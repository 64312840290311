import { ColumnDef } from '@tanstack/react-table';
import { formatDistanceToNow } from 'date-fns';
import { creditStore } from 'entities/credit';
import { conversationStore } from 'entities/tavus/conversation';
import { tavusPersonaStore } from 'entities/tavus/persona';
import {
  EPreconversationStatus,
  IExternalTranscription,
  IPreconversationItem,
  preconversationStore
} from 'entities/tavus/preconversation';
import { PreconversationDeleteModal } from 'features/preconversation-delete-modal';
import { useEffect, useMemo, useState } from 'react';
import { FiPhoneCall } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { logger } from 'shared/lib';
import {
  CALL_WITH_GROUND_CONTROLS_ROUTE,
  ITMessageDaily,
  showMessage,
  showSuccessMessage
} from 'shared/model';
import { DataTable, FilledButton, Loader } from 'shared/ui';
import { useAuthStore } from 'stores/useAuthStore';

export const PreconversationsList = () => {
  const [openModal, setOpenModal] = useState(false);
  const [currentPreconversation, setCurrentPreconversation] =
    useState<IPreconversationItem | null>(null);
  const [callLoading, setCallLoading] = useState(false);

  useEffect(() => {
    setCurrentPreconversation(null);
  }, []);

  const { downloadVideo } = conversationStore((state) => ({
    downloadVideo: state.downloadVideo
  }));

  const {
    preconversationsLoading,
    preconversationsList,
    fetchPreconversations,
    setCurrentPreconversationId,
    currentPreconversationId,
    setPreconversationStatus,
    setPreconversationTranscription,
    setConversationId,
    getSummary,
    summarizeLoading,
    fetchOnePreconversation
  } = preconversationStore((state) => ({
    preconversationsLoading: state.preconversationsLoading,
    preconversationsList: state.preconversationsList,
    fetchPreconversations: state.fetchPreconversations,
    setCurrentPreconversationId: state.setCurrentPreconversationId,
    currentPreconversationId: state.currentPreconversationId,
    setPreconversationStatus: state.setPreconversationStatus,
    setPreconversationTranscription: state.setPreconversationTranscription,
    setConversationId: state.setConversationId,
    summarizeLoading: state.summarizeLoading,
    getSummary: state.getSummary,
    fetchOnePreconversation: state.fetchOne
  }));

  const { isAuth, toggleCreateAccModal } = useAuthStore();
  useEffect(() => {
    fetchPreconversations();
  }, []);

  const getValue = (conversation: IPreconversationItem, nameField: string) => {
    return conversation.payload.find((item) => item.name === nameField)?.value;
  };
  const { isChatAvailable, toggleFeatureBlockModal } = creditStore();
  const { fetchOne } = tavusPersonaStore();

  const handleDeletePreconversation = (
    preconversation: IPreconversationItem
  ) => {
    setCurrentPreconversation(preconversation);
    setOpenModal(true);
  };

  const [childWindow, setChildWindow] = useState<any>(null);

  const handleStartCallSession = () => {
    setChildWindow(
      window.open(
        CALL_WITH_GROUND_CONTROLS_ROUTE,
        'Call',
        'width=800,height=600,top=100,left=100'
      )
    );
  };

  const handleStopCurrentConversation = () => {
    if (childWindow) {
      childWindow.postMessage(
        {
          type: 'STOP_CONVERSATION'
        },
        '*'
      );
    }
    setCurrentPreconversationId(null);
  };

  const handlePersonaCall = async (
    preconversationId: string,
    personaId: string
  ) => {
    if (isAuth) {
      if (isChatAvailable) {
        const persona = await fetchOne(personaId);
        if (persona) {
          if (childWindow) {
            handleStopCurrentConversation();
            setCurrentPreconversationId(preconversationId);
            setCallLoading(true);
            childWindow.postMessage(
              {
                type: 'START_CONVERSATION',
                payload: {
                  persona,
                  preconversationId
                }
              },
              '*'
            );
            showMessage('The call has started');
          }
        }
      } else {
        toggleFeatureBlockModal('agents');
      }
    } else {
      toggleCreateAccModal();
    }
  };

  const copyTranscriptions = (messages: IExternalTranscription[]) => {
    navigator.clipboard
      .writeText(JSON.stringify(messages))
      .then(() => showSuccessMessage('The transcriptions has been copied'));
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'CHANGE_STATUS') {
        logger.debug('CHANGE_STATUS', event);
        const payload = event.data.payload;
        if (payload.status === EPreconversationStatus.FINISHED) {
          if (currentPreconversationId === payload.preconversationId) {
            setCurrentPreconversationId(null);
          }
          setConversationId(payload.preconversationId, payload.conversationId);
        }

        if (payload.status === EPreconversationStatus.IN_PROGRESS) {
          setCallLoading(false);
        }

        setPreconversationStatus(payload.preconversationId, payload.status);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [currentPreconversationId]);

  const columns: ColumnDef<IPreconversationItem>[] = useMemo(
    () => [
      {
        accessorKey: 'uuid',
        header: 'ID'
      },
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => <div>{getValue(row.original, 'name')}</div>,
        sortingFn: (rowA, rowB) => {
          const nameA = getValue(rowA.original, 'name')!;
          const nameB = getValue(rowB.original, 'name')!;
          return nameA?.localeCompare(nameB);
        }
      },
      {
        accessorKey: 'email',
        header: 'Email',
        cell: ({ row }) => <div>{getValue(row.original, 'email')}</div>,
        meta: { enableColumnFilter: true },
        sortingFn: (rowA, rowB) => {
          const nameA = getValue(rowA.original, 'email')!;
          const nameB = getValue(rowB.original, 'email')!;
          return nameA?.localeCompare(nameB);
        },
        filterFn: (row, columnId, filterValue) => {
          const name = getValue(row.original, 'email');
          if (name) {
            return name?.toLowerCase().includes(filterValue.toLowerCase());
          }
          return false;
        }
      },
      {
        accessorKey: 'language',
        header: 'Language',
        cell: ({ row }) => <div>{getValue(row.original, 'language')}</div>,
        sortingFn: (rowA, rowB) => {
          const nameA = getValue(rowA.original, 'language')!;
          const nameB = getValue(rowB.original, 'language')!;
          return nameA?.localeCompare(nameB);
        }
      },
      {
        accessorKey: 'created_at',
        header: 'Created At',
        cell: ({ row }) => {
          const date = row.original.created_at?.includes('Z')
            ? row.original.created_at
            : row.original.created_at + 'Z';
          return (
            <div>
              {row.original.created_at
                ? formatDistanceToNow(date, {
                    addSuffix: true
                  })
                : ''}
            </div>
          );
        }
      },
      {
        accessorKey: 'status',
        header: 'Status'
      },
      {
        accessorKey: 'external_transcripts',
        header: 'Transcription',
        cell: ({ row }) => (
          <div>
            <div
              onClick={() => {
                if (row.original.external_transcripts) {
                  copyTranscriptions(row.original.external_transcripts);
                } else {
                  fetchOnePreconversation(row.original.uuid).then(
                    (response) => {
                      if (!response?.external_transcripts) {
                        toast.warning(
                          'Transcription is not ready. Please try again later'
                        );
                      }
                    }
                  );
                }
              }}
              className="underline ag-cursor-pointer"
            >
              {row.original.external_transcripts
                ? 'Copy transcription'
                : row.original.conversation_id
                ? 'Get transcription'
                : ''}
            </div>
          </div>
        )
      },
      {
        id: 'call',
        header: '',
        cell: ({ row }) => (
          <div>
            <FilledButton
              onClick={() => {
                handlePersonaCall(row.original.uuid, row.original.persona_id);
              }}
              disabled={
                row.original.uuid === currentPreconversationId || !childWindow
              }
              className="ag-py-2 ag-w-full ag-min-w-16"
            >
              <FiPhoneCall className="ag-w-5 ag-h-5 ag-mr-2" />
              Call
            </FilledButton>
          </div>
        )
      },
      {
        id: 'restart',
        header: '',
        meta: {
          isCollapsible: true
        },
        cell: ({ row }) => (
          <div>
            <FilledButton
              onClick={() => {
                handleStopCurrentConversation();
                setTimeout(() => {
                  handlePersonaCall(row.original.uuid, row.original.persona_id);
                }, 3000);
              }}
              disabled={
                !(row.original.uuid === currentPreconversationId) ||
                !childWindow
              }
              className="ag-py-2 ag-w-full ag-min-w-48"
            >
              Restart a conversation
            </FilledButton>
          </div>
        )
      },
      {
        id: 'summary',
        header: '',
        meta: {
          isCollapsible: true
        },
        cell: ({ row }) => (
          <div>
            <FilledButton
              disabled={!row.original.conversation_id || summarizeLoading}
              onClick={() => {
                if (row.original.summary) {
                  navigator.clipboard
                    .writeText(row.original.summary)
                    .then(() =>
                      showSuccessMessage('The summary has been copied')
                    );
                } else if (row.original.conversation_id) {
                  getSummary(row.original.uuid);
                }
              }}
              className="ag-py-2 ag-w-full ag-min-w-36"
            >
              {row.original.summary ? 'Copy summary' : 'Get Summary'}
            </FilledButton>
          </div>
        )
      },
      {
        id: 'download-video',
        header: '',
        meta: {
          isCollapsible: true
        },
        cell: ({ row }) => (
          <div>
            <FilledButton
              disabled={!row.original.conversation_id}
              onClick={() => downloadVideo(row.original.conversation_id!)}
              className="ag-py-2 ag-w-full ag-min-w-36"
            >
              Download video
            </FilledButton>
          </div>
        )
      },
      {
        id: 'open-video',
        header: '',
        meta: {
          isCollapsible: true
        },
        cell: ({ row }) => (
          <div>
            <FilledButton
              disabled={!row.original.conversation_id}
              onClick={() => downloadVideo(row.original.conversation_id!)}
              className="ag-py-2 ag-w-full ag-min-w-36"
            >
              Download video
            </FilledButton>
          </div>
        )
      },

      {
        id: 'delete',
        header: '',
        meta: {
          isCollapsible: true
        },
        cell: ({ row }) => (
          <div>
            <FilledButton
              onClick={() => handleDeletePreconversation(row.original)}
              className="ag-py-2 ag-w-full ag-mt-3.5 ag-bg-red-600 ag-min-w-16"
            >
              Delete
            </FilledButton>
          </div>
        )
      }
    ],
    [
      handlePersonaCall,
      handleDeletePreconversation,
      currentPreconversationId,
      childWindow,
      summarizeLoading
    ]
  );

  const currentCallMember = useMemo(() => {
    return preconversationsList.find(
      (item) => item.uuid === currentPreconversationId
    );
  }, [currentPreconversationId, preconversationsList]);

  const nextCallMember = useMemo(() => {
    return preconversationsList.find(
      (item) =>
        item.uuid !== currentPreconversationId &&
        item.status === EPreconversationStatus.PENDING
    );
  }, [currentPreconversationId, preconversationsList]);

  return (
    <div>
      {preconversationsLoading ? (
        <div className="ag-w-full ag-h-screen ag-flex ag-items-center ag-justify-center -ag-pt-24">
          <Loader size={96} />
        </div>
      ) : (
        <div className="ag-p-10">
          <div
            className={
              'ag-w-full ag-flex ag-items-start ag-gap-10 ag-flex-wrap ag-mb-4'
            }
          >
            <FilledButton
              className="ag-p-2 ag-mt-3.5"
              disabled={childWindow}
              onClick={handleStartCallSession}
            >
              Start Call Session
            </FilledButton>
            <FilledButton
              disabled={!currentPreconversationId}
              className="ag-p-2 ag-mt-3.5 ag-bg-red-400"
              onClick={handleStopCurrentConversation}
            >
              Stop Current Conversation
            </FilledButton>
            {nextCallMember && (
              <FilledButton
                className="ag-p-2 ag-mt-3.5"
                disabled={!childWindow || callLoading}
                onClick={() =>
                  handlePersonaCall(
                    nextCallMember.uuid,
                    nextCallMember.persona_id
                  )
                }
              >
                Next Call
              </FilledButton>
            )}
          </div>
          <div className="ag-flex ag-gap-10">
            {nextCallMember ? (
              <div className="mb-4">
                <div className="ag-font-medium">Next Call</div>
                <div>
                  <span className="ag-font-medium">id:</span>{' '}
                  {nextCallMember.uuid}
                </div>
                <div>
                  <span className="ag-font-medium">email:</span>{' '}
                  {getValue(nextCallMember, 'email')}
                </div>
                <div>
                  <span className="ag-font-medium">language:</span>{' '}
                  {getValue(nextCallMember, 'language')}
                </div>
              </div>
            ) : (
              <div className="mb-4">Participants are finished</div>
            )}
            {currentCallMember && (
              <div className="mb-4">
                <div className="ag-font-medium">Current Call</div>
                <div>
                  <span className="ag-font-medium">id:</span>{' '}
                  {currentCallMember.uuid}
                </div>
                <div>
                  <span className="ag-font-medium">email:</span>{' '}
                  {getValue(currentCallMember, 'email')}
                </div>
                <div>
                  <span className="ag-font-medium">language:</span>{' '}
                  {getValue(currentCallMember, 'language')}
                </div>
              </div>
            )}
          </div>

          <DataTable
            getRowClassName={(row) => {
              if (row.uuid === currentPreconversationId) {
                return 'ag-bg-primary-300';
              }
              if (row.status === EPreconversationStatus.FINISHED) {
                return 'ag-text-gray-400';
              }
              return '';
            }}
            columns={columns}
            data={preconversationsList}
          />
        </div>
      )}
      {currentPreconversation && (
        <PreconversationDeleteModal
          preconversation={currentPreconversation}
          open={openModal}
          setOpen={setOpenModal}
        />
      )}
    </div>
  );
};
