import { ENVIRONMENT } from '..';

export function getCookie(name: string) {
  const nameEQ = name + '=';
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ')
      cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) === 0)
      return cookie.substring(nameEQ.length, cookie.length);
  }
  return null;
}

export function setCookie(
  name: string,
  value: string,
  expiresTime?: Date | number | string
): void {
  let expires = '';
  if (expiresTime !== undefined) {
    if (expiresTime instanceof Date) {
      expires = '; expires=' + expiresTime.toUTCString();
    }
    if (typeof expiresTime === 'string') {
      const date = new Date(expiresTime);
      expires = '; expires=' + date.toUTCString();
    }
    if (typeof expiresTime === 'number') {
      const date = new Date();
      date.setTime(date.getTime() + expiresTime * 1000);
      expires = '; expires=' + date.toUTCString();
    }
  }
  document.cookie =
    name +
    '=' +
    (value || '') +
    expires +
    '; path=/; domain=' +
    `${ENVIRONMENT === 'local' ? 'localhost' : '.yepic.ai'}`;
}

export const deleteCookie = (name: string) => {
  document.cookie =
    `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;` +
    ' path=/; domain=' +
    `${ENVIRONMENT === 'local' ? 'localhost' : '.yepic.ai'}`;
};
