import { useState } from 'react';
import { BsFilter } from 'react-icons/bs';
import { VscChevronDown } from 'react-icons/vsc';

import { capitalize, cn, GenderOptions } from 'shared';

import Checkbox from 'components/shared/Checkbox';
import Dropdown from 'components/shared/Dropdown';

import { Menu } from '@headlessui/react';
import { FiGlobe } from 'react-icons/fi';
import { voiceStore } from '../model/voiceStore';

export const VoiceFilter = () => {
  const { setFilters, filters, changeGender, genders, setGenders, languages } =
    voiceStore();

  const [isOpen, setIsOpen] = useState(false);

  const handleClearFilters = () => {
    setGenders([]);
  };

  return (
    <div className="ag-flex ag-items-center ag-gap-4 ag-text-neutral-100">
      <Dropdown
        wrapperClassName="ag-relative ag-w-full"
        icon={
          <VscChevronDown
            className={`ag-my-auto ag-transform  ${
              isOpen ? 'ag-rotate-180' : ''
            }  `}
          />
        }
        labelClassName="ag-line-clamp-1"
        label={filters?.language && capitalize(filters?.language)}
        buttonClassName="ag-px-4 ag-py-2.5 ag-w-full"
      >
        <div className="ag-overflow-y-scroll ag-h-60">
          {languages &&
            languages.map((lang) => (
              <Menu.Item
                as="div"
                onClick={() =>
                  setFilters({
                    ...filters,
                    language: lang.language,
                    isMultilingual: lang.isMultilingual
                  })
                }
                key={`languages-${lang.language}`}
                className={cn(
                  `ag-py-2 ag-px-2 ag-flex ag-items-center ag-gap-2 hover:ag-bg-neutral-700`,
                  lang.language === filters?.language
                    ? 'ag-bg-neutral-700 ag-rounded-lg'
                    : 'ag-cursor-pointer'
                )}
              >
                {lang.country &&
                  (lang.language === 'multilingual' ? (
                    <FiGlobe className="ag-size-5" />
                  ) : (
                    <img src={lang.country.image} className="ag-size-5" />
                  ))}
                <span>{capitalize(lang.language)}</span>
              </Menu.Item>
            ))}
        </div>
      </Dropdown>
      <div className="ag-w-fit">
        <Dropdown
          label="Filters"
          icon={<BsFilter className="ag-w-5 ag-h-5" />}
          className="ag-min-w-[9rem]"
          buttonClassName="ag-px-4 ag-py-2.5"
        >
          <div className="ag-pt-5 ag-pl-3.5 ag-pr-4 ag-pb-4">
            <div className="ag-flex ag-justify-between ag-w-full ag-items-baseline">
              <span className="ag-text-sm ag-font-semibold">Filters</span>
              <button
                className={`ag-text-sm ag-font-semibold ${
                  genders.length
                    ? 'ag-text-primary-600'
                    : 'ag-text-neutral-300 ag-pointer-events-none'
                }`}
                onClick={() => handleClearFilters()}
              >
                Reset
              </button>
            </div>
            <div className="ag-flex ag-flex-col ag-items-start ag-gap-4 ag-justify-evenly ag-mt-5">
              <Checkbox
                key="filter-male"
                label="Male"
                onClick={(value) => changeGender(value as GenderOptions)}
                choosenValues={genders}
                value="male"
              />
              <Checkbox
                key="filter-female"
                label="Female"
                onClick={(value) => changeGender(value as GenderOptions)}
                choosenValues={genders}
                value="female"
              />
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
