interface ToolTipProps {
  topClass?: string;
  leftClass?: string;
  text: string;
  className?: string;
}

export const ToolTip = ({
  topClass,
  leftClass,
  text,
  className
}: ToolTipProps) => {
  return (
    <span
      className={`ag-wtext-white ag-hidden ag-absolute ag-py-2 ag-px-3 ${topClass} ${leftClass} ag-whitespace-nowrap ag-rounded-lg ag-bg-[#000] ag-text-xs ag-z-[99999]
      group-hover:block ${className}`}
    >
      {text}
    </span>
  );
};
