import React, { FC, useState } from 'react';
import { clsx } from 'clsx';
import FormError from 'components/Forms/FormError/FormError';
import { FormikProps } from 'formik';
import { ChooseModelAiModal } from 'features/choose-model-ai';
import { VscChevronDown } from 'react-icons/vsc';
import { ITavusPersona, ITavusPersonaFormik } from 'entities/tavus/persona';
import { aiModels } from 'entities/tavus/ai-model';
import { featureFlags } from 'shared/model';

interface IProps {
  formik: FormikProps<ITavusPersonaFormik>;
  persona: ITavusPersona;
}

export const AvatarEditPersonaForm: FC<IProps> = ({ formik, persona }) => {
  const [openChoseAiModelModal, setOpenChoseAiModelModal] = useState(false);
  return (
    <div>
      <div className="ag-flex ag-flex-col ag-relative ag-text-neutral-900 ag-font-medium ag-text-sm ag-mb-10">
        <div className={'ag-font-semibold ag-mb-4'}>LLM</div>
        <div className="ag-relative">
          <input
            className={clsx(
              'ag-border ag-w-full ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
              formik.errors.name && formik.touched.name
                ? 'ag-text-red-900  focus:ag-border-red-500'
                : 'placeholder:ag-text-gray-400 focus:ag-border-primary-800'
            )}
            placeholder="My Video Agent"
            onClick={() => setOpenChoseAiModelModal(true)}
            value={
              aiModels.find((model) => model.value === formik.values.model)
                ?.name
            }
            name="model"
            id="name"
            readOnly
          />
          <VscChevronDown
            className={`ag-right-2 ag-transform ag-top-1/2 -ag-translate-y-1/2 ag-absolute ${
              openChoseAiModelModal && 'ag-rotate-180'
            } `}
          />
        </div>
      </div>
      <div className="ag-flex ag-flex-col ag-relative ag-mb-6">
        <label
          className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold"
          htmlFor="name"
        >
          <div>Knowledge</div>
          <div className="ag-text-sm ag-font-normal ag-text-neutral-600">
            Enter information the Video Agent needs to know.
          </div>
        </label>

        <textarea
          className={clsx(
            'ag-border ag-text-neutral-900 ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.context && formik.touched.context
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'placeholder:ag-text-neutral-500 focus:ag-border-primary-800'
          )}
          placeholder="For example, “An expert in digital marketing strategies.”"
          name="context"
          id="context"
          rows={3}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.context}
        />
        {formik.errors.context && formik.touched.context && (
          <FormError>{formik.errors.context}</FormError>
        )}
      </div>
      <div className="ag-flex ag-flex-col ag-relative ag-mb-6">
        <label
          className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold"
          htmlFor="greeting"
        >
          <div>Greeting (Optional)</div>
          <div className="ag-text-sm ag-font-normal ag-text-neutral-600">
            This is the first thing they’ll say when starting a new
            conversation.
          </div>
        </label>

        <textarea
          className={clsx(
            'ag-border ag-text-neutral-900 ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.customGreeting && formik.touched.customGreeting
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'placeholder:ag-text-neutral-500 focus:ag-border-primary-800'
          )}
          placeholder="For example, “An expert in digital marketing strategies.”"
          name="customGreeting"
          id="customGreeting"
          rows={3}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.customGreeting}
        />
        {formik.errors.customGreeting && formik.touched.customGreeting && (
          <FormError>{formik.errors.customGreeting}</FormError>
        )}
      </div>
      {featureFlags.conversationTimer && (
        <div className="ag-flex ag-flex-col ag-relative ag-mb-6">
          <label
            className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold"
            htmlFor="timerConfig"
          >
            <div>Timer configuration(JSON)</div>
            <div className="ag-text-sm ag-font-normal ag-text-neutral-600">
              <h3>JSON Structure Description</h3>

              <h4>1. `duration` (number, seconds)</h4>
              <p>This represents the total duration of the timer in seconds.</p>

              <h4>2. `events` (array of objects)</h4>
              <p>
                This is an array that holds a list of events to be executed at
                specific time points. Each event is an object with the following
                properties:
              </p>
              <ul>
                <li>
                  <strong>type</strong> (string) - The type of action to
                  perform. It can be one of the following values:
                  <ul>
                    <li>
                      <code>echo</code> - A message will be echoed at the
                      specified time point.
                    </li>
                    <li>
                      <code>system_prompt</code> - A system prompt for the LLM.
                    </li>
                    <li>
                      <code>end_call</code> - The call will end at the specified
                      time point.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>time_point</strong> (number, seconds) - The time in
                  seconds when the action should take place.
                </li>
                <li>
                  <strong>value</strong> (string, optional) - The value that is
                  used with <code>echo</code> and <code>system_prompt</code>
                  events. This property is not required for the{' '}
                  <code>end_call</code> action.
                </li>
              </ul>
            </div>
          </label>

          <textarea
            className={clsx(
              'ag-border ag-text-neutral-900 ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
              formik.errors.timerConfig && formik.touched.timerConfig
                ? 'ag-text-red-900  focus:ag-border-red-500'
                : 'placeholder:ag-text-neutral-500 focus:ag-border-primary-800'
            )}
            name="timerConfig"
            id="timerConfig"
            rows={3}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.timerConfig}
          />
          {formik.errors.timerConfig && formik.touched.timerConfig && (
            <FormError>{formik.errors.timerConfig}</FormError>
          )}
        </div>
      )}
      <ChooseModelAiModal
        models={aiModels.map((item) => ({
          value: item.value,
          name: item.name,
          description: item.description,
          icon: item.iconBlack
        }))}
        onChoose={(value) => formik.setFieldValue('model', value)}
        setOpen={setOpenChoseAiModelModal}
        isOpen={openChoseAiModelModal}
      />
    </div>
  );
};
