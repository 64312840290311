import { ColumnDef } from '@tanstack/react-table';
import Dropdown from 'components/shared/Dropdown';
import {
  copyTranscriptions,
  copyTranscriptionsAsText,
  IConversationItem
} from 'entities/tavus/conversation';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { BsFiletypeCsv } from 'react-icons/bs';
import { GrView } from 'react-icons/gr';
import { IoIosRefresh } from 'react-icons/io';
import { LuFileJson2 } from 'react-icons/lu';
import { MdCopyAll } from 'react-icons/md';
import { calculateDuration, formatDate } from 'shared/lib';
import { showSuccessMessage } from 'shared/model';
import {
  dateBetweenFilterFn,
  IndeterminateCheckbox,
  Loader,
  numberRangeFilter
} from 'shared/ui';

export const getConversationsColumns = ({
  currentConversationLoading,
  setCurrentConversationLoading,
  getTranscriptions,
  handleOpenTranscriptionsModal,
  exportConversations,
  userName,
  assistantName
}: {
  currentConversationLoading: string | null;
  getTranscriptions: (id: string) => Promise<void>;
  setCurrentConversationLoading: (id: string) => void;
  handleOpenTranscriptionsModal: (conversation: IConversationItem) => void;
  exportConversations: () => void;
  userName?: string;
  assistantName?: string;
}): ColumnDef<IConversationItem>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
        checked={table.getIsAllRowsSelected()}
      />
    ),
    cell: ({ row }) => (
      <IndeterminateCheckbox
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
        checked={row.getIsSelected()}
      />
    ),
    size: 20
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 100,
    meta: {
      filterVariant: 'select'
    }
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    size: 150,
    filterFn: dateBetweenFilterFn,
    meta: {
      filterVariant: 'date'
    },
    cell: ({ row }) => <div>{formatDate(row.original.created_at)}</div>
  },
  {
    accessorKey: 'updated_at',
    filterFn: dateBetweenFilterFn,
    header: 'Updated At',
    meta: {
      filterVariant: 'date'
    },
    size: 150,
    cell: ({ row }) => <div>{formatDate(row.original.updated_at)}</div>
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    filterFn: numberRangeFilter,
    meta: {
      filterVariant: 'number'
    },
    accessorFn: (row) => calculateDuration(row.created_at, row.updated_at) || 0,
    cell: ({ getValue }) => {
      const duration = getValue();
      return <div>{duration ? `${duration} sec` : 'N/A'}</div>;
    },
    size: 50
  },
  {
    id: 'actions',
    size: 100,
    header: ({ table }) => {
      return (
        <div className="ag-flex ag-items-center">
          <span>Actions</span>
          <Dropdown
            anchor="bottom end"
            icon={
              <div className="ag-w-5 ag-h-5 ag-flex ag-items-center ag-justify-center !ag-text-neutral-800 !ag-border-neutral-200">
                <BiDotsHorizontalRounded />
              </div>
            }
            buttonClassName="!ag-border-neutral-200 !ag-text-neutral-700 ag-bg-white ag-p-1.5 ag-rounded-md ag-flex ag-items-center ag-justify-center ag-group ag-cursor-pointer"
            optionClassName="!ag-bg-white !ag-text-neutral-700 hover:!ag-bg-neutral-200"
            disabledOptionClass="ag-opacity-60"
            options={[
              {
                label: 'Export to CSV',
                icon: BsFiletypeCsv,
                disabled:
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected(),
                onClick: exportConversations
              }
            ]}
            className="!ag-w-52 !ag-bg-white !ag-border-neutral-200"
            wrapperClassName="ag-absolute ag-top-0 ag-right-0 ag-flex ag-justify-end"
          />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <Dropdown
          anchor="bottom end"
          icon={
            <div className="ag-w-5 ag-h-5 ag-flex ag-items-center ag-justify-center !ag-text-neutral-800 !ag-border-neutral-200">
              {currentConversationLoading === row.original.id ? (
                <Loader />
              ) : (
                <BiDotsHorizontalRounded />
              )}
            </div>
          }
          buttonClassName="!ag-border-neutral-200 !ag-text-neutral-700 ag-bg-white ag-p-1.5 ag-rounded-md ag-flex ag-items-center ag-justify-center ag-group ag-cursor-pointer"
          optionClassName="!ag-bg-white !ag-text-neutral-700 hover:!ag-bg-neutral-200"
          disabledOptionClass="ag-opacity-60"
          options={[
            {
              label: 'Update Transcriptions',
              icon: IoIosRefresh,
              onClick: () => {
                getTranscriptions(row.original.id).then(() => {
                  showSuccessMessage('Transcriptions updated successfully');
                });
                setCurrentConversationLoading(row.original.id!);
              }
            },
            {
              label: 'Copy JSON',
              icon: LuFileJson2,
              onClick: () => {
                copyTranscriptions(row.original.external_transcripts!);
              },
              disabled: !row.original.external_transcripts
            },
            {
              label: 'Copy Text',
              icon: MdCopyAll,
              onClick: () => {
                copyTranscriptionsAsText({
                  messages: row.original.external_transcripts!,
                  userName,
                  assistantName
                });
              },
              disabled: !row.original.external_transcripts
            },
            {
              label: 'View Transcriptions',
              onClick: () => {
                handleOpenTranscriptionsModal(row.original);
              },
              icon: GrView,
              disabled: !row.original.external_transcripts
            }
          ]}
          className="!ag-w-52 !ag-bg-white !ag-border-neutral-200"
          wrapperClassName="ag-absolute ag-top-0 ag-right-0 ag-flex ag-justify-end"
        />
      );
    }
  }
];
