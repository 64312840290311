import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FilledButton } from 'shared/ui';
import Divider from 'components/Divider';
import Modal from 'components/shared/Modal';
import {
  EPreconversationType,
  tavusPersonaStore
} from 'entities/tavus/persona';
import { preconversationStore } from 'entities/tavus/preconversation';
import { useMemo, useState } from 'react';
import { cn, replaceUnderscoreAndCapitalize } from 'shared/lib';
import FormError from 'components/Forms/FormError/FormError';
import { LuCopy } from 'react-icons/lu';
import Input from 'components/shared/Input';
import {
  AVATARS_CALL_ROUTE,
  showSuccessMessage,
  STT_LOCALES,
  STT_LOCALES_NAMES
} from 'shared/model';
import { useNavigate } from 'react-router';
import SelectInput from 'components/shared/SelectInput';
import { MdOutlineTranslate } from 'react-icons/md';

export const PreconversationModal = () => {
  const [preconversationId, setPreconversationId] = useState<null | string>(
    null
  );
  const nav = useNavigate();
  const { open, setOpen, createPreconversation, createPreconversationLoading } =
    preconversationStore((state) => ({
      open: state.preconversationModal,
      setOpen: state.setPreconversationModal,
      createPreconversation: state.createPreconversation,
      createPreconversationLoading: state.createPreconversationLoading
    }));

  const currentPersona = tavusPersonaStore((state) => state.currentPersona);

  const validationSchema = Yup.object(
    currentPersona?.preconversation_config?.fields.reduce((acc, field) => {
      if (field.type === EPreconversationType.EMAIL) {
        acc[field.name] = Yup.string().email('Invalid email address');
      } else {
        acc[field.name] = Yup.string();
      }
      return acc;
    }, {} as Record<string, Yup.StringSchema>)
  );
  const initialValues = useMemo(() => {
    return currentPersona?.preconversation_config?.fields.reduce(
      (acc, field) => {
        acc[field.name] = '';
        return acc;
      },
      { language: STT_LOCALES[0] } as Record<string, string>
    );
  }, [currentPersona?.preconversation_config]);

  const formik = useFormik({
    initialValues: initialValues!,
    validationSchema,
    onSubmit: async (values) => {
      const data = Object.entries(values).map(([name, value]) => ({
        name,
        value:
          name === 'language'
            ? STT_LOCALES_NAMES[
                (STT_LOCALES as unknown as string).indexOf(value)
              ].toLowerCase()
            : value
      }));

      const result = await createPreconversation(currentPersona?.id!, data);
      if (result?.preconversation_id) {
        setPreconversationId(result?.preconversation_id);
      }
    },
    enableReinitialize: true
  });

  if (!formik.values) return null;

  const handlePersonaCall = () => {
    setOpen(false);
    nav(AVATARS_CALL_ROUTE + `?preconversation_id=${preconversationId}`);
  };

  return (
    <Modal
      open={open}
      setOpen={(value) => setOpen(Boolean(value))}
      className="!ag-w-[464px] max-sm:ag-mt-auto max-sm:ag-mb-2.5"
    >
      <div className="ag-p-6 ag-pb-5">
        <h2 className="ag-text-lg ag-font-semibold ag-text-neutral-900">
          Preconversation
        </h2>
      </div>
      <Divider />
      <div>
        {currentPersona?.preconversation_config?.fields?.map((field) => (
          <div key={field.name} className="my-3">
            <label htmlFor="">
              <div className="ag-font-medium">
                {field.label || replaceUnderscoreAndCapitalize(field.name)}
              </div>
            </label>
            <input
              id={`${field.name}`}
              className={cn(
                'ag-border ag-w-full ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
                formik.errors.name && formik.touched.name
                  ? 'ag-text-red-900  focus:ag-border-red-500'
                  : 'placeholder:ag-text-gray-400 focus:ag-border-primary-800'
              )}
              type={
                field.type === EPreconversationType.EMAIL ? 'email' : 'text'
              }
              name={field.name}
              value={formik?.values?.[field.name]}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
            />
            {formik.touched[field.name] && formik.errors[field.name] && (
              <FormError>{formik.errors[field.name]}</FormError>
            )}
          </div>
        ))}
        <div className="my-3">
          <div className="ag-font-medium">Language</div>
          <SelectInput
            name="select-language"
            options={STT_LOCALES_NAMES}
            value={
              STT_LOCALES_NAMES[
                (STT_LOCALES as unknown as string[]).indexOf(
                  formik.values.language
                )!
              ]
            }
            onChange={(value) =>
              formik.setFieldValue(
                'language',
                STT_LOCALES[STT_LOCALES_NAMES.indexOf(value)]
              )
            }
            optionsWrapperClassName="!ag-bg-white !ag-border-neutral-200"
            className="ag-h-7 !ag-bg-white !ag-border-neutral-200 !ag-text-neutral-700"
            wrapperClassName="!ag-bg-white !ag-text-neutral-700 !ag-border-neutral-200"
            icon={<MdOutlineTranslate className="ag-size-6 ag-p-1" />}
            optionsClassName="ag-text-neutral-700 ag-border-neutral-200 ag-text-sm ag-py-1 hover:ag-bg-neutral-200/80 dark:hover:ag-bg-[#262626]/80"
          />
        </div>
      </div>
      {preconversationId && (
        <div className="my-3">
          <div>Preconversation id:</div>
          <div className="ag-flex ag-items-center ag-justify-between ag-gap-2">
            <Input
              readOnly={true}
              value={preconversationId}
              className="ag-w-full ag-py-2.5 ag-px-3.5 ag-text-neutral-900 ag-text-base ag-cursor-copy"
            />
            <FilledButton
              onClick={() => {
                navigator.clipboard
                  .writeText(preconversationId)
                  .then(() => showSuccessMessage('Preconversation id copied'));
              }}
              className="ag-py-2.5 ag-ml-1 ag-px-[1.125rem] ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 ag-gap-3 ag-text-base"
            >
              <LuCopy className="ag-text-xl" />
              <div>Copy</div>
            </FilledButton>
          </div>
          <div className="my-2">
            <FilledButton
              onClick={handlePersonaCall}
              className="ag-py-2.5 ag-ml-1 ag-px-[1.125rem] ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 ag-gap-3 ag-text-base"
            >
              Start a call with this ID
            </FilledButton>
          </div>
        </div>
      )}

      <FilledButton
        className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-w-1/2 sm:ag-w-36"
        disabled={
          createPreconversationLoading || !formik.isValid || !formik.dirty
        }
        onClick={formik.submitForm}
      >
        Create Preconversation
      </FilledButton>
    </Modal>
  );
};
