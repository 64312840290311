import { FC } from 'react';
import { cn } from 'shared/lib';

interface IProps {
  tabs: string[];
  activeTab: string;
  activeClassName?: string;
  className?: string;
  itemClassName?: string;
  containerClassName?: string;
  setActiveTab: (tab: string) => void;
}
export const Tabs: FC<IProps> = ({
  tabs,
  className,
  activeTab,
  setActiveTab,
  itemClassName,
  activeClassName,
  containerClassName
}) => {
  return (
    <div className={cn('border-b border-gray-200', className)}>
      <div className={cn('flex space-x-6', containerClassName)}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={cn(
              `ag-text-sm ag-font-semibold ag-pt-[1px] ag-px-1 ag-pb-[11px] ag-cursor-pointer`,
              itemClassName,
              activeTab === tab
                ? 'ag-text-primary-700 ag-border-b-2 ag-border-primary-700'
                : 'text-gray-500',
              activeTab === tab && activeClassName
            )}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
            {activeTab === tab && (
              <span className="absolute left-0 bottom-0 w-full h-1 bg-purple-600" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
