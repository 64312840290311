import { isAfter, isBefore, parseISO, isValid, startOfDay } from 'date-fns';

export const dateBetweenFilterFn = <
  TData extends {
    original: any;
  }
>(
  row: TData,
  columnId: string,
  filterValue: [string | null | undefined, string | null | undefined]
) => {
  const rawDate = (row.original as any)?.[columnId];
  if (!rawDate) return false;

  const rowDate = startOfDay(parseISO(rawDate));
  if (!isValid(rowDate)) return false;

  const [from, to] = filterValue.map((d) =>
    d ? startOfDay(parseISO(new Date(d).toISOString())) : null
  );

  if (from && !isValid(from)) return false;
  if (to && !isValid(to)) return false;

  if (!from && !to) return true;

  if (from && isBefore(rowDate, from)) return false;
  if (to && isAfter(rowDate, to)) return false;

  return true;
};

type NumberRangeFilterValue = [
  number | undefined | string,
  number | undefined | string
];

export const numberRangeFilter = (
  row: any,
  columnId: string,
  filterValue: NumberRangeFilterValue
) => {
  if (!Array.isArray(filterValue) || filterValue.length !== 2) return true;
  const [rawMin, rawMax] = filterValue;

  const min = rawMin !== '' ? Number(rawMin) : undefined;
  const max = rawMax !== '' ? Number(rawMax) : undefined;

  const value: any = Number(row.getValue(columnId));
  if (isNaN(value)) return false;

  return (
    (min === undefined || value >= min) && (max === undefined || value <= max)
  );
};
