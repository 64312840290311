import { tavusHttpService } from 'shared';
import { ETaskType, ITask } from '../model';

const tasksEndpoint = '/tasks';

export const taskApi = {
  fetchAll: async () => {
    const { data } = await tavusHttpService.get<ITask[]>(tasksEndpoint + '/');

    return data;
  },
  restartTask: async (taskId: string, type: ETaskType) => {
    const { data } = await tavusHttpService.post<ITask>(
      tasksEndpoint + `/${taskId}`,
      {},
      {
        params: {
          task_type: type
        }
      }
    );
    return data;
  }
};
