import Modal from '../../../components/shared/Modal';
import { BiX } from 'react-icons/bi';
import Divider from '../../../components/Divider';
import { FilledButton } from 'shared/ui';
import { LuCopy } from 'react-icons/lu';

import {
  conversationStore,
  copyTranscriptionsAsText,
  processTranscriptionRoleName
} from 'entities/tavus/conversation';
import { ChatRole } from 'shared/model';
import { fullNameSelector, userStore } from 'entities/user';
import { tavusPersonaStore } from 'entities/tavus/persona';

const roleStyles = {
  [ChatRole.USER]: 'ag-bg-blue-100 ag-text-blue-800',
  [ChatRole.ASSISTANT]: 'ag-bg-green-100 ag-text-green-800',
  [ChatRole.SYSTEM]: 'ag-bg-gray-100 ag-text-gray-800'
};

export const ViewTranscriptionsModal = () => {
  const { setViewTranscriptionsModal, viewTranscriptionsModal } =
    conversationStore((state) => ({
      setViewTranscriptionsModal: state.setViewTranscriptionsModal,
      viewTranscriptionsModal: state.viewTranscriptionsModal
    }));

  const fullName = userStore(fullNameSelector);
  const currentPersona = tavusPersonaStore((state) => state.currentPersona);

  return (
    <Modal
      setOpen={() => setViewTranscriptionsModal(null)}
      open={!!viewTranscriptionsModal}
      closeButton={
        <BiX
          onClick={() => setViewTranscriptionsModal(null)}
          className="ag-cursor-pointer ag-w-8 ag-h-8 ag-p-1.5 ag-absolute ag-right-4 ag-top-4"
        />
      }
      noPadding
      positionClasses="ag-inset-0 ag-overflow-auto ag-z-10 ag-p-4"
      className="!ag-w-[480px] max-sm:ag-mt-auto max-sm:ag-mb-4"
    >
      <div className="ag-text-left ag-p-6">
        <h2 className="ag-text-lg ag-font-semibold ag-text-neutral-900">
          View Transcriptions
        </h2>
        <Divider className="ag-border-neutral-200 ag-my-3" />

        <div className="ag-space-y-5">
          <div>
            <div className="ag-border ag-border-neutral-300 ag-rounded-md ag-p-3 ag-mb-2 ag-overflow-auto ag-max-h-96 ag-text-sm ag-space-y-2">
              {viewTranscriptionsModal?.external_transcripts?.map(
                (msg, index) => (
                  <div
                    key={index}
                    className={`p-2 my-1 rounded-lg whitespace-pre-wrap ${
                      roleStyles[msg.role]
                    }`}
                  >
                    <span className="ag-font-semibold">
                      {processTranscriptionRoleName({
                        message: msg,
                        assistantName: currentPersona?.persona_name,
                        userName: fullName
                      })}
                      :
                    </span>{' '}
                    {msg.content}
                  </div>
                )
              )}
              {viewTranscriptionsModal?.external_transcripts?.length === 0 &&
                'No transcriptions available.'}
            </div>
            <FilledButton
              className="ag-py-2 ag-px-4 ag-font-medium ag-text-sm ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 ag-flex ag-items-center ag-gap-2"
              onClick={() =>
                copyTranscriptionsAsText({
                  messages: viewTranscriptionsModal?.external_transcripts!,
                  userName: fullName,
                  assistantName: currentPersona?.persona_name
                })
              }
            >
              <LuCopy className="ag-text-base" />
              <span>Copy Text</span>
            </FilledButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
