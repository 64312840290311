import React from 'react';
import DatePicker, { DatePickerProps } from 'react-datepicker';
import { cn } from 'shared/lib';
import { Input } from './Input';

export default function CustomDatePicker(props: DatePickerProps) {
  return (
    <div className={cn('relative w-full')}>
      <DatePicker {...props} dateFormat="dd/MM/yyyy" customInput={<Input />} />
      <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"></span>
    </div>
  );
}
