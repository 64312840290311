import { AxiosResponse } from 'axios';
import { tavusHttpService } from 'shared/api';
import { userStore } from 'entities/user';
import {
  IPreconversation,
  IPreconversationItem,
  IPreconversationPayload
} from '../model';

const preconversationsEndpoint = '/preconversation';
export const preconversationApi = {
  createConversation: async (
    personaId: string,
    payload: IPreconversationPayload[]
  ) => {
    const { data }: AxiosResponse<IPreconversation> =
      await tavusHttpService.post(
        preconversationsEndpoint + '/',
        {
          persona_id: personaId,
          payload
        },
        {
          headers: {
            Authorization: userStore.getState().user?.internalKey
          }
        }
      );

    return data;
  },
  fetchAll: async () => {
    const { data } = await tavusHttpService.get<IPreconversationItem[]>(
      preconversationsEndpoint + '/'
    );
    return data;
  },
  fetchOne: async (preconversationId: string) => {
    const { data } = await tavusHttpService.get<IPreconversationItem>(
      preconversationsEndpoint + `/${preconversationId}`
    );
    return data;
  },
  delete: (id: string) => {
    return tavusHttpService.delete(preconversationsEndpoint + '/' + id);
  },
  getSummary: async (preconversationId: string) => {
    const { data } = await tavusHttpService.get<string>(
      preconversationsEndpoint + `/summary/${preconversationId}`
    );
    return data;
  }
};
