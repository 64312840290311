export enum ETaskType {
  CHECK_VIDEO = 'check_video',
  GENERATE_SUMMARY = 'generate_summary',
  GENERATE_VIDEO = 'generate_video',
  EMAIL_NOTIFICATION = 'email_notification'
}

export enum ETaskState {
  CREATED = 'CREATED',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED'
}

export interface ITask {
  id: string;
  data: string | null;
  payload: any;
  created_at: string;
  run_at: string | null;
  state: 'CREATED' | 'RUNNING' | 'FAILED' | 'FINISHED';
  type: ETaskType | null;
}
