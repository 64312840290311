import { AxiosResponse } from 'axios';
import { tavusHttpService } from 'shared/api';
import { IVoice } from '../model';

export const voiceApi = {
  fetchVoices: async () => {
    const { data }: AxiosResponse<IVoice[]> = await tavusHttpService.get(
      `/voice/`
    );
    return data;
  }
};
