import { FormikProps } from 'formik';
import { ISettingsFormik } from '../../model';
import { FC, useState } from 'react';
import { Field } from 'shared/ui';
import { GoPlus } from 'react-icons/go';
import { cn } from 'shared/lib';

interface IProps {
  formik: FormikProps<ISettingsFormik>;
}
export const PrivacySettings: FC<IProps> = ({ formik }) => {
  const [domain, setDomain] = useState('');
  const handleAddDomain = () => {
    const domains = formik.values.domains || [];
    formik.setFieldValue('domains', [...domains, domain]);
    setDomain('');
  };
  const removeDomainName = (index: number) => {
    const domains =
      formik.values?.domains?.filter((_, indx) => index !== indx) || [];
    formik.setFieldValue('domains', domains);
  };
  return (
    <>
      <div>
        <div className="ag-text-neutral-700 ag-font-medium">Domains</div>
        <div className="ag-text-neutral-600">
          Set the domain where the widget can be integrated
        </div>
      </div>
      <div className="ag-mt-2">
        <div className="ag-flex ag-items-center ag-justify-between ag-gap-2 ag-mb-2">
          <input
            id="domain"
            value={domain}
            type="text"
            placeholder="Enter domain"
            className="ag-grow ag-border ag-border-neutral-200 ag-rounded-lg"
            onChange={(e) => setDomain(e.target.value)}
          />
          <button
            className={cn(
              'ag-rounded-lg ag-border ag-self-stretch ag-px-4 ag-flex ag-items-center',
              !domain ? 'ag-opacity-70' : 'ag-cursor-pointer'
            )}
            onClick={handleAddDomain}
            disabled={!domain}
          >
            <GoPlus className="ag-text-xl ag-mr-2" />
            <div>Add</div>
          </button>
        </div>
        {(formik.values.domains?.length || 0) > 0 && (
          <div className="ag-rounded-lg ag-bg-neutral-50 ag-border ag-p-4 ag-space-y-4 ag-text-neutral-600 ag-text-sm">
            {formik.values.domains?.map((d, ind) => (
              <div
                key={d + ind}
                className="ag-flex ag-items-center ag-justify-between"
              >
                <div>{d}</div>
                <button
                  onClick={() => removeDomainName(ind)}
                  className="ag-text-neutral-700"
                >
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.3333 5.00008V4.33341C12.3333 3.39999 12.3333 2.93328 12.1517 2.57676C11.9919 2.26316 11.7369 2.00819 11.4233 1.8484C11.0668 1.66675 10.6001 1.66675 9.66667 1.66675H8.33333C7.39991 1.66675 6.9332 1.66675 6.57668 1.8484C6.26308 2.00819 6.00811 2.26316 5.84832 2.57676C5.66667 2.93328 5.66667 3.39999 5.66667 4.33341V5.00008M7.33333 9.58342V13.7501M10.6667 9.58342V13.7501M1.5 5.00008H16.5M14.8333 5.00008V14.3334C14.8333 15.7335 14.8333 16.4336 14.5608 16.9684C14.3212 17.4388 13.9387 17.8212 13.4683 18.0609C12.9335 18.3334 12.2335 18.3334 10.8333 18.3334H7.16667C5.76654 18.3334 5.06647 18.3334 4.53169 18.0609C4.06129 17.8212 3.67883 17.4388 3.43915 16.9684C3.16667 16.4336 3.16667 15.7335 3.16667 14.3334V5.00008"
                      stroke="currentColor"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
