import { useEffect, useState } from 'react';
import { IoIosPlayCircle } from 'react-icons/io';
import { IoStopCircle } from 'react-icons/io5';
import { capitalize } from '../../../shared';
import { useNotificationStore } from 'stores/useNotificationStore';
import { IVoice } from 'entities/tavus/voice';

type VoiceCardProps = {
  voice: IVoice;
  onPlay: (audio: any) => void;
  onPause: () => void;
  isPlaying: boolean;
  currentAudio: any;
  countryIcon: string;
  selected: boolean;
  doesGenderMatch: boolean;
};

export const VoiceCard = ({
  voice,
  onPlay,
  onPause,
  isPlaying,
  currentAudio,
  countryIcon,
  selected,
  doesGenderMatch
}: VoiceCardProps) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const showWarn = useNotificationStore((state) => state.showWarn);

  useEffect(() => {
    if (!voice.audio_sample_url) return;
    setAudio(new Audio(voice.audio_sample_url));
  }, []);

  const handlePlay = (e: any) => {
    if (e) e.preventDefault();
    if (isPlaying && currentAudio === audio) {
      onPause();
      return;
    }
    onPlay(audio);
  };

  const handlePlayNofitication = () => {
    showWarn(
      "We are sorry, but at the moment it's impossible to preview this voice"
    );
  };

  return (
    <div
      className={`ag-truncate ag-flex ag-flex-row ag-justify-between ag-items-center ag-p-1 ag-rounded-full ag-border ag-border-transparent`}
      title={
        voice.provider === 'resemble'
          ? 'At the moment Resemble voice provider script text char limit is 2000 instead of 4000'
          : voice.name
      }
    >
      <div className="ag-flex ag-w-full ag-justify-between">
        <div className="ag-flex ag-justify-between ag-items-center ag-w-5/6">
          <div className="ag-flex ag-flex-col ag-justify-between ag-truncate">
            <span className="ag-overflow-hidden ag-text-ellipsis ag-text-sm ag-text-neutral-900 ag-mr-2 ag-font-semibold">
              {capitalize(voice.name)}
            </span>
            <span className="ag-text-sm ag-text-neurtal-600 ag-flex ag-flex-row ag-gap-1">
              <span className="">
                {voice.is_multilingual ? '🌎' : countryIcon}
              </span>
              <span>
                {voice.is_multilingual ? 'Multilingual' : voice.language}
              </span>
            </span>
          </div>
        </div>
        <button
          className="inline-flex items-center justify-center rounded-lg w-1/6 relative z-20"
          onClick={(e) =>
            voice?.audio_sample_url ? handlePlay(e) : handlePlayNofitication()
          }
        >
          {isPlaying && currentAudio === audio ? (
            <IoStopCircle className="w-8 h-8 text-neutral-600" />
          ) : (
            <IoIosPlayCircle className="w-8 h-8 text-neutral-600" />
          )}
        </button>
      </div>
    </div>
  );
};
