import React, { useEffect, useState } from 'react';
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs';
import { FaStop } from 'react-icons/fa';
import { creditStore } from 'entities/credit';
import { cn } from 'shared/lib';
import { IVoice } from '../model';
import { useNotificationStore } from 'stores/useNotificationStore';

type VoiceProps = {
  voice: IVoice;
  onPlay: (audio: HTMLAudioElement) => void;
  onPause: () => void;
  isPlaying: boolean;
  currentAudio: any;
  selected?: boolean;
  doesGenderMatch: boolean;
  onHandleChange: (voice: IVoice) => void;
};

export const Voice: React.FC<VoiceProps> = ({
  voice,
  onPlay,
  onPause,
  isPlaying,
  currentAudio,
  selected,
  doesGenderMatch,
  onHandleChange
}) => {
  const { premiumVoicesAllowed, toggleFeatureBlockModal } = creditStore();
  const showWarn = useNotificationStore((state) => state.showWarn);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (voice.audio_sample_url) {
      setAudio(new Audio(voice.audio_sample_url));
    }
  }, []);

  const handlePlay = () => {
    if (isPlaying && currentAudio === audio) {
      onPause();
      return;
    }
    if (audio) onPlay(audio);
  };

  const handlePlayNotification = () => {
    showWarn(
      "We're sorry, but at the moment voice preview is disabled due to technical problems."
    );
  };

  const handleChooseVoice = async () => {
    if (voice.is_premium_voice) {
      if (!premiumVoicesAllowed) {
        return toggleFeatureBlockModal('premiumVoices');
      }
    }
    onHandleChange(voice);
  };

  return (
    <div
      className={cn(
        `ag-flex ag-justify-between ag-p-1 ag-py-3 ag-w-full ag-border ag-items-center ag-text-sm disabled:ag-opacity-50 ag-rounded-lg`,
        selected
          ? 'ag-border-secondary'
          : 'ag-border-neutral-600 ag-cursor-pointer'
      )}
      onClick={handleChooseVoice}
    >
      <div className="ag-flex ag-items-center ag-space-x-2 ag-truncate">
        <button
          className="ag-w-6 ag-h-6 ag-p-1.5 ag-pl-2 ag-shrink-0"
          onClick={(e) => {
            e.stopPropagation();
            if (voice.audio_sample_url) {
              handlePlay();
            } else {
              handlePlayNotification();
            }
          }}
          type="button"
        >
          {isPlaying && currentAudio === audio ? (
            <FaStop className="ag-text-secondary" />
          ) : (
            <svg
              viewBox="0 0 11 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.525 13.0258C1.19167 13.2425 0.854333 13.2552 0.513 13.0638C0.171666 12.8725 0.000666667 12.5765 0 12.1758V1.82582C0 1.42582 0.171 1.12982 0.513 0.937822C0.855 0.745822 1.19233 0.758489 1.525 0.975822L9.675 6.15082C9.975 6.35082 10.125 6.63416 10.125 7.00082C10.125 7.36749 9.975 7.65082 9.675 7.85082L1.525 13.0258Z"
                fill="#C34DFF"
              />
            </svg>
          )}
        </button>
        <div
          title={
            doesGenderMatch
              ? ''
              : "This voice doesn't match chosen actor's gender"
          }
          className={`ag-w-6 ag-h-6 ag-p-1 ag-shrink-0 ${
            doesGenderMatch ? '' : 'ag-text-red-400'
          }`}
        >
          {voice.gender === 'male' ? (
            <BsGenderMale className="ag-w-full ag-h-full" />
          ) : (
            <BsGenderFemale className="ag-w-full ag-h-full" />
          )}
        </div>
        <div className="ag-text-left ag-inline-flex">
          <span className="ag-text-sm ag-mr-2 ag-font-medium ag-w-fit">
            {voice.name.charAt(0).toUpperCase() + voice.name.slice(1)}
          </span>
          <span className="ag-text-sm ag-overflow-hidden ag-inline-block ag-truncate sm:ag-w-auto">
            {voice.language}
          </span>
        </div>
      </div>
    </div>
  );
};
