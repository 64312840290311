import { deleteCookie, getCookie, setCookie } from 'shared/lib';

export const getSessionTime = () => {
  const sessionTime = getCookie('sessionTime');

  if (sessionTime) {
    return Math.floor(
      JSON.parse(sessionTime).reduce(
        (total: number, session: { start: number; end: number }) =>
          total + (session.end - session.start) / 1000,
        0
      )
    );
  }

  return 0;
};

const expirationMinutes = 60 * 6;

export const setSessionStartTime = () => {
  const sessionTime = getCookie('sessionTime');
  const arr = sessionTime ? JSON.parse(sessionTime) : [];
  const currentTime = Date.now();
  arr.push({
    start: currentTime,
    end: currentTime,
    exp: expirationMinutes
  });

  setCookie('sessionTime', JSON.stringify(arr), expirationMinutes);
};

export const updateSessionEndTime = () => {
  const sessionTime = getCookie('sessionTime');
  if (!sessionTime) return;

  const arr = JSON.parse(sessionTime);
  arr[arr.length - 1].end = Date.now();

  setCookie('sessionTime', JSON.stringify(arr), expirationMinutes);
};

export const clearSessionTime = () => {
  deleteCookie('sessionTime');
};
