import { Column, Table } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { FaFilter, FaTimes } from 'react-icons/fa';
import { Popover, PopoverContent, PopoverTrigger } from '../popover';
import { FilledButton, Input } from '..';
import SelectInput from 'components/shared/SelectInput';
import DatePicker from '../DatePicker';

export const Filter = ({
  column,
  table
}: {
  column: Column<any, any>;
  table: Table<any>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [stringFilter, setStringFilter] = useState<string>('');
  const [numberFilter, setNumberFilter] = useState<[string, string] | null>(
    null
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [selectFilter, setSelectFilter] = useState<string | null>(null);

  const filterVariant = column?.columnDef?.meta?.filterVariant;
  const columnFilterValue = column.getFilterValue();
  const isFiltered =
    columnFilterValue !== undefined &&
    columnFilterValue !== '' &&
    columnFilterValue !== null;
  const applyFilter = () => {
    switch (filterVariant) {
      case 'number':
        if (numberFilter) column.setFilterValue(numberFilter);
        break;
      case 'date':
        if (startDate || endDate) {
          column.setFilterValue([startDate, endDate ?? null]);
        }
        break;
      case 'select':
        if (selectFilter) column.setFilterValue(selectFilter);
        break;
      default:
        column.setFilterValue(stringFilter);
    }
    setIsOpen(false);
  };

  const resetFilter = (closeModal: boolean = true) => {
    column.setFilterValue(undefined);
    setStringFilter('');
    setNumberFilter(null);
    setSelectFilter(null);
    if (closeModal) {
      setIsOpen(false);
    }
  };

  const selectOptions = useMemo(
    () =>
      column.getFacetedUniqueValues
        ? Array.from(column.getFacetedUniqueValues().keys()).filter(
            (item) => item
          )
        : [],
    [column.getFacetedUniqueValues(), filterVariant]
  );

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger>
        <div className="ag-px-2">
          <FaFilter
            className={isFiltered ? 'text-blue-500' : 'text-gray-500'}
          />
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto min-w-72">
        <div className="mb-2 flex items-center justify-between">
          <span className="font-semibold">Filter</span>
          <button onClick={() => setIsOpen(false)}>
            <FaTimes className="text-gray-500 hover:text-gray-700" />
          </button>
        </div>
        {filterVariant === 'number' ? (
          <div className="flex flex-col space-y-2">
            <Input
              type="number"
              value={numberFilter ? numberFilter[0] : ''}
              onChange={(e) =>
                setNumberFilter((prev) => [e.target.value, prev ? prev[1] : ''])
              }
              placeholder="Min"
            />
            <Input
              type="number"
              value={numberFilter ? numberFilter[1] : ''}
              onChange={(e) =>
                setNumberFilter((prev) => [prev ? prev[0] : '', e.target.value])
              }
              placeholder="Max"
            />
          </div>
        ) : filterVariant === 'select' ? (
          <SelectInput
            name="select-language"
            placeholder="Select..."
            options={selectOptions}
            value={selectFilter?.toLowerCase() || null}
            onChange={(value) => setSelectFilter(value)}
            optionsWrapperClassName="!ag-bg-white !ag-border-neutral-200 ag-left-auto ag-right-0"
            className="ag-h-8 !ag-bg-white !ag-border-neutral-200 !ag-text-neutral-700"
            wrapperClassName="!ag-bg-white !ag-text-neutral-700 !ag-border-neutral-200 !ag-w-[14.5rem]"
            optionsClassName="ag-text-neutral-700 ag-border-neutral-200 ag-text-sm ag-py-1 hover:ag-bg-neutral-200/80 dark:hover:ag-bg-[#262626]/80"
          />
        ) : filterVariant === 'date' ? (
          <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            placeholderText="Select range"
            value={undefined}
            onChange={(dates) => {
              const [start, end] = dates;
              setStartDate(start!);
              setEndDate(end!);
            }}
          />
        ) : (
          <Input
            type="text"
            value={stringFilter}
            onChange={(e) => setStringFilter(e.target.value)}
            placeholder="Search..."
          />
        )}
        <div className="mt-3 flex flex-col items-end">
          <FilledButton
            className="ag-px-3 ag-py-2.5 ag-font-semibold"
            onClick={applyFilter}
          >
            Apply
          </FilledButton>
          <button
            className="ag-underline ag-text-primary-600 ag-cursor-pointer ag-py-2"
            onClick={() => resetFilter()}
          >
            Reset
          </button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
