import Divider from 'components/Divider';
import Modal from 'components/shared/Modal';
import { FC, useEffect, useState } from 'react';
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs';
import { capitalize } from 'shared/lib';
import { useAuthStore } from 'stores/useAuthStore';
import { creditStore } from 'entities/credit';
import { IVoice, voiceStore } from 'entities/tavus/voice';
import { VoiceCard } from './VoiceCard';
import Checkbox from 'components/shared/Checkbox';
import { ILanguage, IVoiceFilter } from './types';
import LanguageSelect from './LanguageSelect';

const initialFilters = {
  language: 'All languages',
  provider: 'All providers'
};

const defaultLanguage = 'All languages';

type Props = {
  currentVoice: IVoice | null | undefined;
  open: boolean;
  setOpen: (open: boolean) => void;
  chooseVoice: (value: IVoice | null) => void;
};

export const VoiceModal: FC<Props> = ({
  open,
  setOpen,
  currentVoice = null,
  chooseVoice
}) => {
  const { isAuth, toggleCreateAccModal } = useAuthStore((state) => ({
    user: state.user,
    isAuth: state.isAuth,
    toggleCreateAccModal: state.toggleCreateAccModal
  }));
  const { toggleFeatureBlockModal, premiumVoicesAllowed } = creditStore(
    (state) => ({
      toggleFeatureBlockModal: state.toggleFeatureBlockModal,
      premiumVoicesAllowed: state.premiumVoicesAllowed
    })
  );

  const { voices, languages } = voiceStore((state) => ({
    voices: state.voices,
    languages: state.languages,
    voiceProviders: state.voiceProviders,
    fetchVoices: state.fetchVoices
  }));

  const [langLookup, setLangLookup] = useState<any>({});
  const [search, setSearch] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [chosenProviders, setChosenProviders] = useState<string[]>([]);
  const [chosenGenders, setChosenGenders] = useState<string[]>([]);
  const [filterVoices, setFilterVoices] = useState<IVoice[]>([]);

  const [filters, setFilters] = useState(initialFilters);
  const [language, setLanguage] = useState<string>(defaultLanguage);
  const [selectedVoice, setSelectedVoice] = useState<IVoice | null>(null);
  const [currentAudio, setCurrentAudio] = useState<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const lookup = languages.reduce((acc, curr: ILanguage) => {
      acc[curr.language] = curr.country?.emoji ? curr.country?.emoji : '';
      return acc;
    }, {} as Record<string, string>);
    setLangLookup(lookup);
    setSelectedVoice(currentVoice);
    setFilterVoices(voices);
    setFilters((_prevFilters) => ({
      ..._prevFilters,
      language
    }));
  }, []);

  useEffect(() => {
    setSelectedVoice(currentVoice);
  }, [currentVoice]);

  useEffect(() => {
    const handlePause = () => {
      setIsPlaying(false);
    };

    if (currentAudio) {
      currentAudio.addEventListener('ended', handlePause);
    }

    return () => {
      if (currentAudio) {
        currentAudio.removeEventListener('ended', handlePause);
      }
    };
  }, [currentAudio]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(search);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    let _filterVoices = voices;
    if (language === 'All languages') {
      _filterVoices = [...voices];
    } else if (language === 'multilingual') {
      _filterVoices = voices.filter((voice) => voice.is_multilingual);
    } else {
      _filterVoices = voices.filter((voice) => {
        return voice.language === language;
      });
    }
    if (chosenGenders.length > 0) {
      _filterVoices = _filterVoices.filter((voice) => {
        if (chosenGenders.length === 0) {
          return true;
        }
        return chosenGenders.includes(voice.gender);
      });
    }
    if (chosenProviders.length > 0) {
      _filterVoices = _filterVoices.filter((voice) => {
        if (chosenProviders.length === 0) {
          return true;
        }
        return chosenProviders.includes(voice.provider);
      });
    }
    if (debouncedInputValue.length) {
      _filterVoices = _filterVoices.filter((item) =>
        item.name?.toLowerCase().includes(debouncedInputValue?.toLowerCase())
      );
    }
    setFilterVoices(_filterVoices);
  }, [language, chosenGenders, chosenProviders, debouncedInputValue, voices]);

  const handleSetFilter = (_filters: IVoiceFilter) => {
    setLanguage(_filters.language);
    setFilters(_filters);
  };

  const handleClose = async () => {
    setLanguage(defaultLanguage);
    setFilters(initialFilters);
    setChosenGenders([]);
    setChosenProviders([]);
    setSearch('');
    setOpen(false);
  };

  const handleApply = async () => {
    chooseVoice(selectedVoice);
    setOpen(false);
  };

  const handlePlayAudio = (audio: any) => {
    if (isPlaying) {
      handlePauseAudio();
    }

    setCurrentAudio(audio);
    setIsPlaying(true);
    audio.play();
  };

  const handlePauseAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      currentAudio.removeEventListener('ended', () => setIsPlaying(false));
      setCurrentAudio(null);
      setIsPlaying(false);
    }
  };

  const handleChangeVoice = (e: any) => {
    const newVoice = e.target.value || '';
    const selVoice = voices.find((v) => v.external_id === newVoice);
    if (!selVoice) return;

    if (newVoice === selectedVoice?.external_id) {
      setSelectedVoice(null);
      return;
    }
    if (selVoice.is_premium_voice) {
      if (isAuth) {
        if (!premiumVoicesAllowed) {
          toggleFeatureBlockModal('premiumVoices');
          return;
        }
      } else {
        toggleCreateAccModal();
        return;
      }
    }
    setSelectedVoice(selVoice);
  };

  const renderVoices = () => {
    return (
      <div className="ag-w-auto ag-relative ag-text-white-600">
        <div className="ag-grid md:grid-cols-2 sm:grid-cols-1 ag-grid-flow-row ag-auto-rows-max ag-gap-4 ag-h-[400px] ag-overflow-y-scroll">
          {filterVoices.map((v, ind) => (
            <label
              className={`ag-block ag-cursor-pointer ag-relative ag-z-10 ag-border ag-rounded-lg ag-p-2 ag-h-max ${
                selectedVoice?.id === v.id
                  ? 'ag-border-primary-600'
                  : 'ag-border-gray-300'
              }`}
              key={`${v.external_id}-${ind}`}
              htmlFor={v.external_id}
            >
              <input
                type="radio"
                id={v.external_id}
                value={v.external_id}
                name="voices"
                className="absolute invisible"
                onChange={handleChangeVoice}
                checked={selectedVoice?.id === v.external_id}
              />
              <VoiceCard
                voice={v}
                onPlay={handlePlayAudio}
                onPause={handlePauseAudio}
                isPlaying={isPlaying}
                currentAudio={currentAudio}
                countryIcon={langLookup[v.language]}
                selected={selectedVoice?.external_id === v.external_id}
                doesGenderMatch={selectedVoice?.gender === v.gender}
              />
              <div className="ag-border-b ag-border-gray-200 ag-py-1 ag-w-auto"></div>
              <div className="ag-flex ag-justify-between ag-items-center ag-py-1">
                <div className="ag-flex ag-items-center">
                  {v?.gender === 'male' ? (
                    <BsGenderMale className={`ag-inline-block ag-text-lg`} />
                  ) : (
                    <BsGenderFemale className={`ag-inline-block ag-text-lg `} />
                  )}
                  <div className="ag-text-sm ag-py-2 ag-pl-2">
                    {capitalize(v.gender)}
                  </div>
                </div>
                {v.is_premium_voice && (
                  <div className="ag-mt-0.5">
                    <svg
                      width="18"
                      height="22"
                      viewBox="0 0 18 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.86866 14.4599L4 21L8.5884 18.247C8.73805 18.1572 8.81288 18.1123 8.89276 18.0947C8.96341 18.0792 9.03659 18.0792 9.10724 18.0947C9.18712 18.1123 9.26195 18.1572 9.4116 18.247L14 21L13.1319 14.4571M13.4259 3.24888C13.5803 3.6224 13.8768 3.9193 14.25 4.0743L15.5589 4.61648C15.9325 4.77121 16.2292 5.06799 16.384 5.44154C16.5387 5.81509 16.5387 6.23481 16.384 6.60836L15.8422 7.91635C15.6874 8.29007 15.6872 8.71021 15.8427 9.08374L16.3835 10.3913C16.4602 10.5764 16.4997 10.7747 16.4997 10.975C16.4998 11.1752 16.4603 11.3736 16.3837 11.5586C16.3071 11.7436 16.1947 11.9118 16.0531 12.0534C15.9114 12.195 15.7433 12.3073 15.5582 12.3839L14.2503 12.9256C13.8768 13.0801 13.5799 13.3765 13.4249 13.7498L12.8827 15.0588C12.728 15.4323 12.4312 15.7291 12.0577 15.8838C11.6841 16.0386 11.2644 16.0386 10.8909 15.8838L9.58296 15.342C9.20942 15.1877 8.78987 15.188 8.41656 15.3429L7.10767 15.8843C6.73434 16.0387 6.31501 16.0386 5.94178 15.884C5.56854 15.7293 5.27194 15.4329 5.11711 15.0598L4.57479 13.7504C4.42035 13.3769 4.12391 13.08 3.75064 12.925L2.44175 12.3828C2.06838 12.2282 1.77169 11.9316 1.61691 11.5582C1.46213 11.1849 1.46192 10.7654 1.61633 10.3919L2.1581 9.08391C2.31244 8.71035 2.31213 8.29079 2.15722 7.91746L1.61623 6.60759C1.53953 6.42257 1.50003 6.22426 1.5 6.02397C1.49997 5.82369 1.5394 5.62536 1.61604 5.44032C1.69268 5.25529 1.80504 5.08716 1.94668 4.94556C2.08832 4.80396 2.25647 4.69166 2.44152 4.61508L3.74947 4.07329C4.12265 3.91898 4.41936 3.6229 4.57448 3.25004L5.11664 1.94111C5.27136 1.56756 5.56813 1.27078 5.94167 1.11605C6.3152 0.961318 6.7349 0.961318 7.10844 1.11605L8.41638 1.65784C8.78993 1.81218 9.20948 1.81187 9.58279 1.65696L10.8922 1.11689C11.2657 0.962245 11.6853 0.962277 12.0588 1.11697C12.4322 1.27167 12.729 1.56837 12.8837 1.94182L13.426 3.25115L13.4259 3.24888Z"
                        stroke="#FEC84B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </label>
          ))}
        </div>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div className="ag-flex ag-flex-col ag-gap-2 ag-text-neutral-500 md:w-2/5 ag-mr-4 sm:w-full">
        <div className="ag-flex ag-justify-between ag-items-center ag-pt-6 ag-pb-4">
          <span className="ag-text-neutral-800 ag-font-medium">Filter By</span>
          <button
            className="ag-text-primary-600 ag-text-sm/5 ag-font-medium"
            onClick={() => {
              setLanguage('English (United States)');
              setFilters({
                language: 'English (United States)',
                provider: 'All providers'
              });
              setChosenGenders([]);
              setChosenProviders([]);
            }}
          >
            Reset all Filters
          </button>
        </div>
        <Divider className="ag-border-neutral-200" />
        <div className="ag-py-2">
          <div className="ag-flex ag-justify-between ag-items-center ag-mb-4">
            <span className="ag-text-neutral-800 ag-font-medium">Language</span>
            <button
              className="ag-text-primary-600 ag-text-sm/5 ag-font-medium"
              onClick={() => {
                setLanguage('English (United States)');
                setFilters({
                  language: 'English (United States)',
                  provider: 'All providers'
                });
              }}
            >
              Reset
            </button>
          </div>
          <LanguageSelect
            languages={languages}
            filters={filters}
            onFilterSet={handleSetFilter}
          />
        </div>
        <div className="ag-py-2">
          <div className="ag-flex ag-justify-between ag-items-center ag-mb-4">
            <span className="ag-text-neutral-800 ag-font-medium">Gender</span>
            <button
              className="ag-text-primary-600 ag-text-sm/5 ag-font-medium"
              onClick={() => {
                setChosenGenders([]);
              }}
            >
              Reset
            </button>
          </div>
          <div className="ag-grid ag-grid-cols-2 ag-gap-2 ag-text-neutral-600 ag-mb-4">
            <Checkbox
              label="Male"
              onClick={(value) => {
                if (chosenGenders.includes(value)) {
                  setChosenGenders(chosenGenders.filter((p) => p !== value));
                } else {
                  setChosenGenders([...chosenGenders, value]);
                }
              }}
              choosenValues={chosenGenders}
              value="male"
            />
            <Checkbox
              label="Female"
              onClick={(value) => {
                if (chosenGenders.includes(value)) {
                  setChosenGenders(chosenGenders.filter((p) => p !== value));
                } else {
                  setChosenGenders([...chosenGenders, value]);
                }
              }}
              choosenValues={chosenGenders}
              value="female"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderVoiceList = () => {
    return (
      <>
        <div className="md:flex">
          {renderFilters()}
          <div className="ag-flex ag-flex-col ag-text-neutral-500 ag-pt-6 md:pl-4 ag-w-full md:border-solid md:border-l ag-border-neutral-200 sm:w-full">
            <div className="md:flex md:flex-row md:gap-2 ag-mt-4 ag-mb-4 ag-justify-between sm:flex-col">
              <div
                className={`md:w-2/3 sm:w-full ag-flex ag-rounded ag-border ag-border-gray-300 ag-voices md:w-full`}
              >
                <span className="ag-relative ag-left-0 ag-flex ag-items-center ag-pl-2 ag-pt-2">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 30 30"
                    className="w-6 h-6"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </span>
                <input
                  type="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  name="q"
                  autoComplete="off"
                  className="ag-w-full ag-text-md ag-text-gray-900 ag-border-none"
                  placeholder="Find your voice"
                />
              </div>
            </div>

            {renderVoices()}
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      setOpen={(value) => setOpen(value as boolean)}
      open={open}
      noPadding
      positionClasses="ag-inset-0 ag-overflow-y-auto"
      rootClassName="!ag-z-[5]"
      className="ag-relative ag-transform ag-rounded-lg ag-text-left ag-shadow-xl ag-transition-all ag-w-[62rem]"
    >
      <div className="md:h-[45rem] ag-w-full md:overflow-hidden">
        <div className="ag-flex ag-flex-col ag-px-5 ag-pt-6 ag-pb-4">
          <h2 className="ag-pb-1 ag-text-xl ag-font-semibold ag-text-neutral-900">
            Select Voice
          </h2>
          <h3 className="ag-text-sm ag-text-neutral-600">
            Find the perfect voice match for your avatar
          </h3>
        </div>
        <Divider />
        <div className="ag-px-5 ag-py-0">{renderVoiceList()}</div>
        <>
          <Divider className="ag-hidden md:block" />
          <div className="ag-my-6 ag-mr-5 ag-flex ag-justify-end ag-gap-2 ag-text-neutral-500 ag-w-auto">
            <button
              className="ag-bg-white ag-text-neutral-700 ag-rounded-md ag-text-sm/5 ag-font-medium ag-px-4 ag-py-2.5 ag-border ag-border-neutral-200"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className={`ag-text-white ag-rounded-md ag-text-sm/5 ag-font-medium ag-px-4 ag-py-0 ${
                currentVoice?.id === selectedVoice?.id
                  ? 'ag-bg-gray-400 ag-border-gray-400 ag-cursor-not-allowed'
                  : 'ag-bg-primary-600 ag-border-primary-600 ag-hover:bg-primary-700 ag-hover:border-primary-700'
              }`}
              disabled={currentVoice?.id === selectedVoice?.id}
              onClick={handleApply}
            >
              Apply
            </button>
          </div>
        </>
      </div>
    </Modal>
  );
};
