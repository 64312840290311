import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { GoArrowLeft } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { MAIN_ROUTE } from 'shared/model';
import { FilledButton, Loader, OutlinedButton } from 'shared/ui';
import { BiX } from 'react-icons/bi';
import { ReactComponent as KeyboardCircleIcon } from 'shared/ui/img/keyboardCircle.svg';
import { ReactComponent as DotsCircleIcon } from 'shared/ui/img/dotsCircle.svg';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { cn } from 'shared/lib';
import { IoChevronUp } from 'react-icons/io5';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import {
  ITavusPersona,
  ITavusPersonaFormik,
  tavusPersonaStore,
  timerConfigValidationSchema
} from 'entities/tavus/persona';
import { AvatarEditPersonaSidebar } from './AvatarEditPersonaSidebar';
import { AvatarIdentityForm } from 'widgets/avatar-identity-form';
import { ChooseBackground } from 'widgets/choose-background';

export const AvatarEditPersonaLayout: FC<
  PropsWithChildren<{ persona: ITavusPersona }>
> = ({ persona }) => {
  const { currentReplica, getReplicaById } = replicaStore((state) => ({
    currentReplica: state.currentReplica,
    getReplicaById: state.getReplicaById
  }));
  const { isPersonaCreating, updatePersona } = tavusPersonaStore((state) => ({
    isPersonaCreating: state.isPersonaCreating,
    updatePersona: state.updatePersona
  }));

  const nav = useNavigate();

  const [onCall, setOnCall] = useState(false);

  useEffect(() => {
    if (currentReplica?.replica_id !== formik.values.defaultReplicaId) {
      formik.setFieldValue('defaultReplicaId', currentReplica?.replica_id);
    }
  }, [currentReplica]);

  const onSubmit = async (values: ITavusPersonaFormik) => {
    formik.setSubmitting(true);
    try {
      await updatePersona(persona.id, {
        persona_name: values.name || 'Video Agent Name',
        default_replica_id: currentReplica?.replica_id!,
        context: values.context,
        system_prompt: values.systemPrompt,
        custom_greeting: values.customGreeting,
        custom_background: values.customBackground,
        model: values.model,
        timer_config: values.timerConfig,
        preconversation_config: values.preconversationConfig,
        voice_id: values.voiceId,
        default_language: values.defaultLanguage
      });
      nav(MAIN_ROUTE);
    } catch (error) {
      console.error('update avatar error:', error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik<ITavusPersonaFormik>({
    initialValues: {
      name: persona.persona_name,
      defaultReplicaId: persona.default_replica_id,
      systemPrompt: persona.system_prompt,
      context: persona.context,
      customGreeting: persona.custom_greeting || '',
      customBackground: persona.custom_background || '',
      model: persona.model || 'llama',
      timerConfig: persona.timer_config || '',
      voiceId: persona.voice_id || '',
      preconversationConfig: persona.preconversation_config || {
        context: '',
        fields: []
      },
      defaultLanguage: persona.default_language || 'english'
    },
    validationSchema: Yup.object({
      name: Yup.string(),
      systemPrompt: Yup.string()
        .required('Please enter description prompt')
        .max(80, 'Please make description shorter'),
      customGreeting: Yup.string().max(500, 'Please make greeting shorter'),
      timerConfig: timerConfigValidationSchema,
      preconversationConfig: Yup.object({
        context: Yup.string(),
        fields: Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .matches(/^[A-Za-zА-Яа-я_]+$/, 'No numbers or spaces allowed')
              .notOneOf(['language'], 'This name is reserved')
              .required('Required')
          })
        )
      })
    }),
    onSubmit
  });
  const isDisabled = !formik.dirty || formik.isSubmitting;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="ag-bg-white ag-grid ag-grid-rows-[auto,1fr,4.5rem] lg:ag-grid-rows-[4rem,calc(100vh-4rem)] lg:ag-grid-cols-[556px,calc(100vw-556px)] ag-h-screen ag-w-full"
    >
      <div className="lg:ag-col-span-2 ag-h-14 lg:ag-h-16 bg-neutral-white ag-border-b ag-border-neutral-200 ag-font-semibold ag-text-neutral-600 ag-flex ag-justify-between ag-items-center ag-py-4 lg:ag-py-3 ag-px-4 lg:ag-px-6">
        <Link to={MAIN_ROUTE} className="ag-items-center ag-hidden lg:ag-flex">
          <GoArrowLeft className="ag-w-6 ag-h-6 ag-mr-4" />
          Edit video agent
        </Link>
        <FilledButton
          className="ag-h-full ag-px-6 ag-hidden lg:ag-flex disabled:ag-bg-primary-600 disabled:ag-opacity-20 ag-w-36"
          disabled={isDisabled}
          type="submit"
        >
          {isPersonaCreating ? <Loader isButton /> : 'Save changes'}
        </FilledButton>
        <h1 className="ag-font-semibold lg:ag-hidden">Edit Video Agent</h1>
        <Link to={MAIN_ROUTE} className="ag-p-1 lg:ag-hidden">
          <BiX className="ag-w-5 ag-h-5" />
        </Link>
      </div>
      <AvatarEditPersonaSidebar
        IdentityFormComponent={AvatarIdentityForm}
        ChooseBackgroundComponent={ChooseBackground}
        formik={formik}
        persona={persona}
      />
      <div className="ag-hidden lg:ag-flex ag-items-center ag-justify-center ag-bg-neutral-50 ag-overflow-y-auto">
        <div className="ag-max-w-[343px] ag-m-auto ag-py-3 ag-h-[42.75rem]">
          <div className="ag-flex ag-bg-neutral-100 ag-rounded-lg ag-border ag-border-neutral-200 ag-p-1 ag-mb-8 ">
            <OutlinedButton
              onClick={() => setOnCall(false)}
              className={clsx(
                'ag-py-2 ag-flex-1 ag-border-0',
                !onCall ? 'ag-bg-white ag-shadow-button' : ''
              )}
            >
              Before Call
            </OutlinedButton>
            <OutlinedButton
              onClick={() => setOnCall(true)}
              className={clsx(
                'ag-flex-1 ag-border-0',
                onCall
                  ? 'ag-bg-white ag-shadow-button'
                  : 'ag-py-2 ag-border-transparent'
              )}
            >
              On Call
            </OutlinedButton>
          </div>
          <div
            className={clsx(
              'ag-border ag-border-neutral-200 ag-rounded-2xl ag-bg-white'
            )}
          >
            <div
              className={
                'ag-text-center ag-border-b ag-border-neutral-200 ag-text-neutral-600 ag-text-xs ag-py-3'
              }
            >
              <div className={'ag-font-semibold'}>Name</div>
              <div>Powered by Yepic AI</div>
            </div>
            <div className={clsx(!onCall ? 'ag-py-[85.5px] ag-px-16' : '')}>
              <div
                className={clsx(
                  !onCall
                    ? 'ag-rounded-full ag-overflow-hidden ag-aspect-square ag-max-w-[343px]'
                    : ''
                )}
              >
                {currentReplica ? (
                  currentReplica.thumbnail_url && !onCall ? (
                    <img
                      className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top"
                      src={currentReplica?.thumbnail_url}
                    />
                  ) : (
                    <video
                      autoPlay
                      muted
                      loop
                      style={{ aspectRatio: '5/6' }}
                      className="ag-w-full ag-h-full ag-object-cover ag-object-top"
                      src={currentReplica.thumbnail_video_url}
                    />
                  )
                ) : (
                  <div className="ag-size-[343px] ag-rounded-lg ag-object-cover ag-object-top ag-bg-neutral-100" />
                )}
              </div>
              {!onCall && (
                <FilledButton className="ag-w-full ag-py-2 ag-mb-7 ag-mt-7">
                  Start new chat
                </FilledButton>
              )}
            </div>

            {onCall && (
              <div
                className={
                  'ag-flex ag-items-center ag-p-3 ag-border-t ag-border-neutral-300 ag-gap-2'
                }
              >
                <DotsCircleIcon />
                <div className="ag-h-10 ag-w-[1px] ag-bg-neutral-300"></div>
                <KeyboardCircleIcon />
                <svg
                  className="ag-size-10"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="19.7224" cy="20" r="13.6111" fill="#525252" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM30.6375 26.2568C30.8567 26.3695 31.1215 26.3565 31.3293 26.225C31.5386 26.0936 31.6667 25.861 31.6667 25.6111V15.5C31.6667 15.2501 31.5386 15.0176 31.3279 14.8861C31.1172 14.7532 30.8553 14.7431 30.636 14.8543L25.9723 17.2203V15.5C25.9723 14.3055 25.0142 13.3333 23.8369 13.3333H11.0244C9.84703 13.3333 8.88894 14.3055 8.88894 15.5V25.6111C8.88894 26.8057 9.84703 27.7778 11.0244 27.7778H23.8369C25.0142 27.7778 25.9723 26.8057 25.9723 25.6111V23.8908L30.6375 26.2568Z"
                    fill="#E5E5E5"
                  />
                </svg>
                <div className="ag-flex ag-items-center ag-rounded-full ag-bg-neutral-100 ag-border ag-border-neutral-300 ag-relative">
                  <button
                    className={cn(
                      'ag-size-10 ag-p-2 ag-border ag-bg-neutral-200 ag-border-neutral-300 bg-neutral-200 ag-text-neutral-600 ag-rounded-full ag-relative -ag-left-[1px] ag-flex ag-items-center ag-justify-center'
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 56 56"
                      fill="none"
                      className="ag-size-full"
                    >
                      <g clipPath="url(#clip0_1358_2011)">
                        <circle
                          cx="27.9999"
                          cy="28.0001"
                          r="19.0556"
                          fill="#525252"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0ZM37.3516 29.2572C36.9929 31.5247 35.8152 33.5345 34.1317 34.9734C32.4793 36.3856 30.3361 37.2408 28 37.2408C25.6639 37.2408 23.5207 36.3855 21.8683 34.9734C20.1848 33.5345 19.0071 31.5247 18.6484 29.2572C18.5313 28.5001 17.8225 27.9813 17.0655 28.0984C16.3084 28.2156 15.7896 28.9243 15.9067 29.6814C16.3727 32.6272 17.8923 35.2295 20.0622 37.084C21.9627 38.7082 24.359 39.7645 26.9816 39.9836V42.5644H23.2146C22.4455 42.5644 21.822 43.1879 21.822 43.9569C21.822 44.7261 22.4454 45.3496 23.2146 45.3496H33.5339C34.3031 45.3496 34.9265 44.7261 34.9265 43.9569C34.9265 43.1878 34.303 42.5644 33.5339 42.5644H29.767V39.8976C32.0963 39.5566 34.2197 38.5524 35.9378 37.0841C38.1077 35.2296 39.6274 32.6273 40.0933 29.6815C40.2104 28.9244 39.6916 28.2157 38.9345 28.0985C38.1776 27.9813 37.4687 28.5001 37.3516 29.2572ZM28 10.6505C26.1292 10.6505 24.4296 11.4148 23.1982 12.6462C21.9669 13.8775 21.2026 15.5772 21.2026 17.4479V27.778C21.2026 29.6487 21.967 31.3485 23.1982 32.5798C24.4296 33.8111 26.1294 34.5754 28.0001 34.5754C29.8709 34.5754 31.5705 33.8111 32.8019 32.5798C34.0332 31.3484 34.7975 29.6486 34.7975 27.778V17.4479C34.7975 15.5772 34.0332 13.8775 32.8019 12.6462C31.5704 11.4148 29.8708 10.6505 28 10.6505Z"
                          fill="#E5E5E5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1358_2011">
                          <rect width="56" height="56" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <button className="ag-text-neutral-600 ag-pl-1.5 ag-pr-3">
                    <IoChevronUp />
                  </button>
                </div>
                <svg
                  className="ag-size-10 ag-ml-12"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 56 56"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1358_2025)">
                    <path
                      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
                      fill="#DC2626"
                    />
                    <path
                      d="M25.301 30.6687C29.1466 34.5143 32.9157 36.0744 34.2732 34.7169L36.1374 32.8531C36.7541 32.2365 37.5905 31.8901 38.4625 31.8901C39.3346 31.8901 40.171 32.2365 40.7876 32.8531L44.9728 37.0382C45.5894 37.6549 45.9358 38.4913 45.9358 39.3633C45.9358 40.2354 45.5894 41.0718 44.9728 41.6884L41.7121 44.9491C38.6312 48.03 29.5189 43.9175 20.7856 35.1845C16.4211 30.8179 13.5585 26.7049 11.7083 22.8374C9.85812 18.9699 9.48319 15.7976 11.0227 14.258L14.2834 10.9974C14.9001 10.3807 15.7365 10.0343 16.6085 10.0343C17.4806 10.0343 18.317 10.3807 18.9336 10.9974L23.1187 15.1812C23.7354 15.7978 24.0818 16.6342 24.0818 17.5063C24.0818 18.3783 23.7354 19.2147 23.1187 19.8314L21.2532 21.6999C19.8957 23.0562 21.4554 26.8248 25.301 30.6687Z"
                      fill="#EEEFEE"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1358_2025">
                      <rect width="56" height="56" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="ag-p-4 ag-border-t ag-border-neutral-300 ag-bg-white ag-flex ag-gap-4 lg:ag-hidden">
        <Link to={MAIN_ROUTE} className="ag-w-full">
          <OutlinedButton className="ag-h-full ag-w-full ag-bg-white ag-text-neutral-600">
            Cancel
          </OutlinedButton>
        </Link>
        <FilledButton
          className="ag-h-full ag-w-full disabled:ag-bg-primary-600 disabled:ag-ag-opacity-20"
          disabled={isDisabled}
          type="submit"
        >
          {isPersonaCreating ? <Loader isButton /> : 'Save changes'}
        </FilledButton>
      </div>
    </form>
  );
};
