import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';

import { cn } from 'shared/lib';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        'ag-z-50 ag-w-72 ag-rounded-md ag-border ag-bg-white ag-p-4 ag-text-popover-foreground ag-shadow-md ag-outline-none data-[state=open]:ag-animate-in data-[state=closed]:ag-animate-out data-[state=closed]:ag-fade-out-0 data-[state=open]:ag-fade-in-0 data-[state=closed]:ag-zoom-out-95 data-[state=open]:ag-zoom-in-95 data-[side=bottom]:ag-slide-in-from-top-2 data-[side=left]:ag-slide-in-from-right-2 data-[side=right]:ag-slide-in-from-left-2 data-[side=top]:ag-slide-in-from-bottom-2 ',
        className
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent };
