import { Dispatch, SetStateAction, useEffect } from 'react';
import { logger } from 'shared/lib';

export const useKeyboardBindings = ({
  toggleMic,
  toggleWebcam,
  setOpen
}: {
  toggleMic: () => void;
  toggleWebcam: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.shiftKey) {
        if (event.key === 'M' || event.key === 'm') {
          logger.debug('keyboard toggle mic', event);
          event.preventDefault();
          toggleMic();
        }

        if (event.key === 'V' || event.key === 'v') {
          logger.debug('keyboard toggle webcam', event);
          event.preventDefault();
          toggleWebcam();
        }
      }
      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        logger.debug('Left arrow pressed');
      }

      if (event.key === 'ArrowRight') {
        event.preventDefault();
        logger.debug('Right arrow pressed');
      }

      if (event.key === 'Escape') {
        setOpen((value) => !value);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [toggleMic, toggleWebcam, open]);
};

export const useKeyboardFullScreenAndCaptionsBinding = ({
  toggleFullScreen,
  toggleCaptions
}: {
  toggleFullScreen: () => void;
  toggleCaptions: () => void;
}) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    logger.debug('handle keydown fullscreen', event);
    if ((event.metaKey || event.ctrlKey) && event.shiftKey) {
      if (event.key === 'F' || event.key === 'f') {
        event.preventDefault();
        logger.debug('keyboard toggle fullscreen', event);
        toggleFullScreen();
      }
      if (event.key === 'C' || event.key === 'c') {
        event.preventDefault();
        logger.debug('keyboard toggle captions', event);
        toggleCaptions();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [toggleFullScreen]);
};
