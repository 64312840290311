import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { preconversationApi } from '../api';
import {
  EPreconversationStatus,
  IExternalTranscription,
  IPreconversation,
  IPreconversationItem,
  IPreconversationPayload
} from './preconversationTypes';
import { toast } from 'react-toastify';
import { getErrorMessageV2 } from 'shared/lib';
import { ITMessageDaily, showError } from 'shared/model';

type State = {
  currentPreconversationId: string | null;
  createPreconversationLoading: boolean;
  preconversationModal: boolean;
  preconversationsLoading: boolean;
  isPreconversationDeleting: boolean;
  preconversationsList: IPreconversationItem[];
  summarizeLoading: boolean;
};

type Actions = {
  createPreconversation: (
    personaId: string,
    payload: IPreconversationPayload[]
  ) => Promise<IPreconversation | undefined>;
  setPreconversationModal: (value: boolean) => void;
  fetchPreconversations: () => Promise<void>;
  fetchOne: (
    preconversationId: string
  ) => Promise<IPreconversationItem | undefined>;
  deletePreconversation: (preconversationId: string) => Promise<void>;
  setCurrentPreconversationId: (id: string | null) => void;
  setPreconversationStatus: (
    preconversationId: string,
    status: EPreconversationStatus
  ) => void;
  setPreconversationTranscription: (
    preconversationId: string,
    messages: ITMessageDaily[]
  ) => void;
  setConversationId: (
    preconversationId: string,
    conversationId: string
  ) => void;
  getSummary: (conversationId: string) => Promise<void>;
};

type Store = State & Actions;

export const preconversationStore = create<Store>()(
  persist(
    (set, get) => ({
      summarizeLoading: false,
      createPreconversationLoading: false,
      currentPreconversationId: null,
      preconversationsLoading: false,
      preconversationModal: false,
      preconversationsList: [],
      isPreconversationDeleting: false,
      setConversationId(preconversationId, conversationId) {
        const preconversations = get().preconversationsList;
        set({
          preconversationsList: preconversations.map((item) => {
            if (item.uuid === preconversationId) {
              return {
                ...item,
                conversation_id: conversationId
              };
            }
            return item;
          })
        });
      },
      setPreconversationStatus: (preconversationId, status) => {
        const preconversations = get().preconversationsList;
        set({
          preconversationsList: preconversations.map((item) => {
            if (item.uuid === preconversationId) {
              return {
                ...item,
                status
              };
            }
            return item;
          })
        });
      },
      setPreconversationTranscription: (preconversationId, messages) => {
        const preconversations = get().preconversationsList;
        set({
          preconversationsList: preconversations.map((item) => {
            if (item.uuid === preconversationId) {
              return {
                ...item,
                transcription: messages.map(
                  (message) =>
                    ({
                      content: message.content,
                      role: message.role,
                      timestamp: message.timestamp,
                      user_name: message.userName
                    } as unknown as ITMessageDaily)
                )
              };
            }
            return item;
          })
        });
      },
      setCurrentPreconversationId: (id) => {
        set({ currentPreconversationId: id });
      },
      deletePreconversation: async (id) => {
        set({ isPreconversationDeleting: true });
        try {
          await preconversationApi.delete(id);
          set((state) => ({
            preconversationsList: state.preconversationsList.filter(
              (p) => p.uuid !== id
            )
          }));
        } catch (error) {
          const message = getErrorMessageV2(
            error,
            'There was a problem when we tried to delete a preconversation. Please try again later.'
          );
          showError(message);
        } finally {
          set({ isPreconversationDeleting: false });
        }
      },
      createPreconversation: async (personaId, payload) => {
        set({ createPreconversationLoading: true });
        try {
          return await preconversationApi.createConversation(
            personaId,
            payload
          );
        } catch (e) {
          toast.error(
            "Couldn't create the preconversation. Please try again later."
          );
        } finally {
          set({ createPreconversationLoading: false });
        }
      },
      setPreconversationModal: (preconversationModal) => {
        set({ preconversationModal });
      },
      getSummary: async (preconversationId: string) => {
        set({ summarizeLoading: true });
        try {
          const summary = await preconversationApi.getSummary(
            preconversationId
          );
          const preconversationList = get().preconversationsList;
          set({
            preconversationsList: preconversationList.map((item) => {
              if (item.uuid === preconversationId) {
                return {
                  ...item,
                  summary
                };
              }
              return item;
            })
          });
        } catch (error) {
          toast.error(getErrorMessageV2(error, 'Please try again later'));
        } finally {
          set({ summarizeLoading: false });
        }
      },
      fetchPreconversations: async () => {
        set({ preconversationsLoading: true });
        try {
          const data = await preconversationApi.fetchAll();
          set({ preconversationsList: data });
        } catch (error) {
          toast.error(
            'There was a problem when we tried to get preconversations list. Please try again later.'
          );
        } finally {
          set({ preconversationsLoading: false });
        }
      },
      fetchOne: async (preconversationId) => {
        try {
          const newPreconversation = await preconversationApi.fetchOne(
            preconversationId
          );
          set((state) => ({
            preconversationsList: state.preconversationsList.map((item) => {
              if (item.uuid === preconversationId) {
                return newPreconversation;
              }
              return item;
            })
          }));
          return newPreconversation;
        } catch (error) {
          toast.error(
            'There was a problem when we tried to get transcriptions list. Please try again later.'
          );
        }
      }
    }),
    {
      name: 'preconversation-storage',
      partialize: (state) => ({
        currentPreconversationId: state.currentPreconversationId
      })
    }
  )
);
