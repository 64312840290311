import React, {
  FormEvent,
  RefObject,
  createRef,
  useCallback,
  useEffect,
  useState
} from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { capitalize, useClickOutside } from 'shared/lib';
import { ILanguage, IVoiceFilter } from './types';

interface LanguageSelectProps {
  languages: ILanguage[];
  filters: IVoiceFilter;
  onFilterSet: (filter: IVoiceFilter) => void;
}

const moveLanguageToTop = (languages: ILanguage[], language: string) => {
  const index = languages.findIndex((lang) => lang.language === language);

  const newFilteredLanguages = [...languages];
  if (index !== -1) {
    newFilteredLanguages.splice(index, 1);
    newFilteredLanguages.unshift(languages[index]);
  }

  return newFilteredLanguages;
};

const LanguageSelect = ({
  languages,
  filters,
  onFilterSet
}: LanguageSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [filteredLanguages, setFilteredLanguages] = useState(languages);
  const inputRef = createRef<HTMLInputElement>();

  const langRef = createRef<HTMLDivElement>();
  useClickOutside(langRef, setIsOpen);

  const handleSearch = (searchString: string) => {
    setSearchString(searchString);
    const filtered = languages.filter((language) =>
      language.language?.toLowerCase().startsWith(searchString?.toLowerCase())
    );

    setFilteredLanguages(filtered);
  };

  const clearSearch = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        setSearchString('');

        const newLanguages = moveLanguageToTop(languages, filters.language);
        setFilteredLanguages(newLanguages);
      }
    },
    [inputRef.current]
  );

  useEffect(() => {
    if (searchString !== '') {
      document.addEventListener('keydown', clearSearch);
    } else {
      document.removeEventListener('keydown', clearSearch);
    }
  }, [searchString]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    } else {
      setSearchString('');
      const newLanguages = moveLanguageToTop(languages, filters.language);
      setFilteredLanguages(newLanguages);
    }
  }, [isOpen]);

  const handleChangeVoice = (
    e: FormEvent,
    langRef: RefObject<HTMLDivElement>
  ) => {
    e.preventDefault();
    if (filteredLanguages.length > 0) {
      handleChoose(filteredLanguages[0], langRef);

      setIsOpen(false);
    }
  };

  const handleChoose = (l: ILanguage, langRef: RefObject<HTMLDivElement>) => {
    setSearchString('');

    const newFilteredLanguages = moveLanguageToTop(languages, l.language);

    langRef.current && langRef.current.scrollTo(0, 0);

    setFilteredLanguages(newFilteredLanguages);
    onFilterSet({
      ...filters,
      language: l.language
    });
  };

  return (
    <div className={`group relative text-sm  mb-2 w-full`}>
      <div
        className="px-3 py-2 border border-tertiary focus:outline-none shadow-sm  focus:ring-secondary focus:border-secondary text-sm rounded-md w-full cursor-pointer relative flex items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <form
          onSubmit={(e) => handleChangeVoice(e, langRef)}
          className="absolute top-0 left-0 p-0 opacity-0 w-1 -z-10"
        >
          <input
            ref={inputRef}
            type="text"
            value={searchString}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </form>
        <span className="text-neutral-600">{capitalize(filters.language)}</span>
        <AiFillCaretDown className={`${isOpen && 'transform rotate-180'}`} />
        <div
          ref={langRef}
          className={`absolute w-full max-h-[10rem] top-9 py-1 px-1 left-0 overflow-y-scroll bg-white z-50 border border-tertiary rounded-lg ${
            isOpen ? 'block' : 'hidden'
          }`}
        >
          {filteredLanguages.map((l) => (
            <div
              onClick={() => handleChoose(l, langRef)}
              key={`languages-${l.language}`}
              className={`py-2 px-2 flex justify-between ${
                l.language === filters.language && 'bg-slate-200 rounded-lg'
              }`}
            >
              {l.country?.emoji} {capitalize(l.language)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LanguageSelect;
