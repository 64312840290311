import { HTMLProps, useEffect, useRef } from 'react';

export const IndeterminateCheckbox = ({
  indeterminate,
  className = '',
  ...props
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !props.checked && indeterminate;
    }
  }, [indeterminate, props.checked]);

  return (
    <label className="ag-flex ag-items-center">
      <input type="checkbox" ref={ref} className="hidden" {...props} />
      {indeterminate ? (
        <div
          style={{ color: '#FCF5FF' }}
          className="ag-w-4 ag-border ag-border-[#D4D4D4] ag-h-4 ag-bg-[#FCF5FF] ag-rounded-[4px] ag-flex ag-items-center ag-justify-center"
        >
          <div className="ag-bg-primary-600 ag-h-1 ag-rounded-sm ag-w-3"></div>
        </div>
      ) : (
        <svg
          className="ag-w-4 ag-h-4 ag-inline"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            rx="3.5"
            fill="#FCF5FF"
            className={`${props.checked ? 'ag-visible' : 'ag-hidden'}`}
          />
          <path
            d="M12 5L6.5 10.5L4 8"
            stroke="#C34DFF"
            strokeWidth="1.6666"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`${props.checked ? 'ag-visible' : 'ag-hidden'}`}
          />
          <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            rx="3.5"
            stroke="#D4D4D4"
            fill={`${props.checked ? 'none' : '#fff'}`}
          />
        </svg>
      )}
    </label>
  );
};
