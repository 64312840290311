import { create } from 'zustand';
import { replicaApi } from '../api';
import { toast } from 'react-toastify';
import { Replica, TRatioReplicaType } from './replicaTypes';
import { GenderOptions } from 'shared/model';

type State = {
  replicas: Replica[];
  filteredReplicas: Replica[] | null;
  genderFilters: GenderOptions[];
  ratioFilters: TRatioReplicaType[];
  replicasLoading: boolean;
  currentReplica: Replica | null;
};

type Actions = {
  fetchReplicas: (key?: string) => Promise<void>;
  fetchOne: (id: string) => Promise<Replica | undefined>;
  setFilteredReplicas: () => void;
  getReplicaById: (id: string) => Replica | undefined;
  setCurrentReplica: (replica: Replica | null) => void;
  setGenderFilters: (value: GenderOptions) => void;
  setRatioFilters: (value: TRatioReplicaType) => void;
  resetGenders: () => void;
  resetRatioFilters: () => void;
};

type Store = State & Actions;

export const replicaStore = create<Store>((set, get) => ({
  replicas: [],
  genderFilters: [],
  filteredReplicas: null,
  ratioFilters: [],
  replicasLoading: false,
  currentReplica: null,
  replicaNameFilter: '',
  total: 0,
  page: 1,
  size: 12,
  pages: 1,
  setCurrentReplica: (replica) => {
    set({ currentReplica: replica });
  },
  setFilteredReplicas: () => {
    const replicas = get().replicas;
    const genderFilters = get().genderFilters;
    const ratioFilters = get().ratioFilters;
    const filteredReplicas = replicas
      ?.filter(
        (replica) =>
          !genderFilters.length ||
          genderFilters.length === 2 ||
          genderFilters.includes(replica.gender?.toLowerCase() as GenderOptions)
      )
      .filter(
        (replica: Replica) =>
          !ratioFilters.length ||
          ratioFilters.length === 2 ||
          ratioFilters.includes(replica.yepic_avatar_type)
      );
    set({
      filteredReplicas
    });
  },
  fetchReplicas: async (key) => {
    set({ replicasLoading: true });
    try {
      const items = await replicaApi.fetchAll(key);
      set({
        replicas: items
      });
      get().setFilteredReplicas();
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get replicas list. Please try again later.'
      );
    } finally {
      set({ replicasLoading: false });
    }
  },
  fetchOne: async (id: string) => {
    set({ replicasLoading: true });
    try {
      return await replicaApi.fetch(id);
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get replica details. Please try again later.'
      );
    } finally {
      set({ replicasLoading: false });
    }
  },
  getReplicaById: (id) => {
    const allReplicas = get().replicas;

    return allReplicas.find((a) => a.replica_id === id);
  },
  setGenderFilters: (value) => {
    const genderFilters = get().genderFilters;
    if (genderFilters.includes(value)) {
      set({
        genderFilters: genderFilters.filter((filter) => filter !== value)
      });
    } else {
      set({
        genderFilters: [...genderFilters, value]
      });
    }
    get().setFilteredReplicas();
  },
  setRatioFilters: (value) => {
    const ratioFilters = get().ratioFilters;
    if (ratioFilters.includes(value)) {
      set({
        ratioFilters: ratioFilters.filter((filter) => filter !== value)
      });
    } else {
      set({
        ratioFilters: [...ratioFilters, value]
      });
    }
    get().setFilteredReplicas();
  },
  resetGenders: () => {
    set({
      genderFilters: []
    });
  },
  resetRatioFilters: () => {
    set({
      ratioFilters: []
    });
    get().setFilteredReplicas();
  }
}));
