import { create } from 'zustand';
import { ETaskType, ITask } from './types';
import { toast } from 'react-toastify';
import { taskApi } from '../api';
import { getErrorMessageV2 } from 'shared/lib';

interface IState {
  restartTaskLoading: boolean;
  tasksLoading: boolean;
  tasksList: ITask[];
}

interface IActions {
  fetchAll: () => Promise<void>;
  restartTask: (taskId: string, type: ETaskType) => Promise<void>;
}

type Store = IState & IActions;

export const taskStore = create<Store>((set) => ({
  tasksList: [],
  tasksLoading: false,
  restartTaskLoading: false,
  restartTask: async (taskId, type) => {
    set({ restartTaskLoading: true });
    try {
      const task = await taskApi.restartTask(taskId, type);
      set((state) => ({
        tasksList: state.tasksList.map((item) => {
          if (item.id === taskId) {
            return task;
          }
          return item;
        })
      }));
    } catch (error) {
      toast.error(getErrorMessageV2(error, 'Please try again later'));
    } finally {
      set({ restartTaskLoading: false });
    }
  },
  fetchAll: async () => {
    set({ tasksLoading: true });
    try {
      const tasks = await taskApi.fetchAll();
      set({
        tasksList: tasks
      });
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get tasks list. Please try again later.'
      );
    } finally {
      set({ tasksLoading: false });
    }
  }
}));
