import * as Yup from 'yup';

const eventSchema = Yup.object().shape({
  type: Yup.mixed().oneOf(['echo', 'system_prompt', 'end_call']).required(),
  time_point: Yup.number().min(0).required(),
  value: Yup.string()
});

// Схема для всего таймера
const timerConfigSchema = Yup.object().shape({
  duration: Yup.number().min(0).required(),
  events: Yup.array().of(eventSchema).required()
});
export const timerConfigValidationSchema = Yup.string().test(
  'is-valid-json',
  'Invalid JSON FORMAT',
  (value) => {
    try {
      if (!value) return true;
      const parsedValue = JSON.parse(value);
      return timerConfigSchema.isValidSync(parsedValue);
    } catch (e) {
      return false;
    }
  }
);
