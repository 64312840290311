import { ChatRole, showSuccessMessage } from 'shared/model';
import { IExternalTranscription } from '../model';

export const processTranscriptionRoleName = ({
  message,
  userName,
  assistantName
}: {
  message: IExternalTranscription;
  userName?: string;
  assistantName?: string;
}) => {
  if (message.role === ChatRole.USER) {
    return userName || message.role;
  }
  return assistantName || message.role;
};

export const copyTranscriptions = (messages: IExternalTranscription[]) => {
  navigator.clipboard
    .writeText(JSON.stringify(messages, null, 2))
    .then(() => showSuccessMessage('The transcriptions have been copied'));
};

export const processJSONTranscriptionsToText = ({
  messages,
  userName,
  assistantName
}: {
  messages: IExternalTranscription[];
  userName?: string;
  assistantName?: string;
}) => {
  return messages
    ?.map(
      (msg) =>
        `${processTranscriptionRoleName({
          message: msg,
          assistantName,
          userName
        })}: ${msg.content}`
    )
    .join('\n');
};

export const copyTranscriptionsAsText = ({
  messages,
  userName,
  assistantName
}: {
  messages: IExternalTranscription[];
  userName?: string;
  assistantName?: string;
}) => {
  const text = processJSONTranscriptionsToText({
    messages,
    userName,
    assistantName
  });
  navigator.clipboard
    .writeText(text)
    .then(() =>
      showSuccessMessage('The transcriptions have been copied as text')
    );
};
