import { tavusPersonaStore } from 'entities/tavus/persona';
import { useEffect } from 'react';
import { TavusCall } from './TavusCall';
import { preconversationStore } from 'entities/tavus/preconversation';
import { logger } from 'shared/lib';

export const CallWithGroundControls = () => {
  const { setCurrentPersona } = tavusPersonaStore((state) => ({
    currentPersona: state.currentPersona,
    setCurrentPersona: state.setCurrentPersona
  }));

  const setCurrentPreconversationId = preconversationStore(
    (state) => state.setCurrentPreconversationId
  );

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'START_CONVERSATION') {
        const payload = event.data.payload;
        logger.debug('event START_CONVERSATION', payload);
        setCurrentPersona(event.data.payload.persona);
        const preconversationId = payload.preconversationId;
        setCurrentPreconversationId(preconversationId);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      logger.debug('before unload');
      window.opener.postMessage({ type: 'CHILD_CLOSE' }, '*');
    });
  }, []);

  return <TavusCall />;
};
