import { AxiosResponse } from 'axios';

import { tavusHttpService } from 'shared';
import { Replica } from '../model';
import { userStore } from 'entities/user';

const replicasEndpoint = '/replica/';

export const replicaApi = {
  fetch: async (id: string) => {
    const { data }: AxiosResponse<Replica> = await tavusHttpService.get(
      `${replicasEndpoint}/${id}`
    );

    return data;
  },
  fetchAll: async (key?: string) => {
    const { data }: AxiosResponse<Replica[]> = await tavusHttpService.get(
      replicasEndpoint,
      {
        headers: {
          Authorization: userStore.getState().user?.internalKey || key
        }
      }
    );

    return data;
  }
};
