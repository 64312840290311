import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import {
  MAIN_ROUTE,
  Loader,
  useQueryParams,
  STT_LOCALES_NAMES,
  STT_LOCALES
} from 'shared';
import { videoCallStore } from 'features/livekit-call/';
import { creditStore } from 'entities/credit';
import { userStore } from 'entities/user';
import { conversationStore } from 'entities/tavus/conversation/model/conversationStore';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { TavusConference } from './TavusConference';
import {
  EPreconversationStatus,
  preconversationStore
} from 'entities/tavus/preconversation';

export const TavusCall = () => {
  const { startConversation } = conversationStore();
  const { currentPersona } = tavusPersonaStore();
  const { user } = userStore();
  const { isChatAvailable } = creditStore();
  const { sttLocale } = videoCallStore();

  const currentPreconversationId = preconversationStore(
    (state) => state.currentPreconversationId
  );

  useEffect(() => {
    if (user && isChatAvailable && currentPersona) {
      startConversation(
        currentPersona,
        undefined,
        STT_LOCALES_NAMES[STT_LOCALES.indexOf(sttLocale)].toLocaleLowerCase() ||
          'english',
        currentPreconversationId
      ).then(() => {
        window.opener.postMessage({
          type: 'CHANGE_STATUS',
          payload: {
            status: EPreconversationStatus.IN_PROGRESS,
            preconversationId: currentPreconversationId
          }
        });
      });
    }
  }, [user, isChatAvailable, currentPersona, currentPreconversationId]);

  return <TavusConference />;
};
