import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import {
  cn,
  ENVIRONMENT,
  FilledButton,
  Loader,
  showError,
  STT_LOCALES,
  STT_LOCALES_NAMES,
  useQueryParams
} from 'shared';

import {
  EIntegrationSettingsType,
  tavusPersonaStore
} from 'entities/tavus/persona';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { conversationStore } from 'entities/tavus/conversation';
import { TavusEmbedConference } from './TavusEmbedConference';
import { Replica } from 'entities/tavus/replica';
import { MdOutlineTranslate } from 'react-icons/md';
import SelectInput from 'components/shared/SelectInput';

export const TavusCallEmbed = () => {
  const { startConversation, currentConversation, conversationLoading } =
    conversationStore((state) => ({
      startConversation: state.startConversation,
      currentConversation: state.currentConversation,
      conversationLoading: state.conversationLoading
    }));
  const { fetchOne, currentPersona, setCurrentPersona } = tavusPersonaStore();
  const { id } = useParams();
  const query = useQueryParams();
  const type = query.get('type') as EIntegrationSettingsType;

  const [language, setLanguage] = useState<string | undefined>(
    currentPersona?.default_language || 'english'
  );

  useEffect(() => {
    if (currentPersona) {
      setLanguage(currentPersona.default_language);
    }
  }, [currentPersona]);

  const { getReplicaById, replicas } = replicaStore((state) => ({
    getReplicaById: state.getReplicaById,
    replicas: state.replicas
  }));
  const [replica, setReplica] = useState<Replica | undefined>();

  useEffect(() => {
    getPersonaData(id);
  }, []);

  const integrationSettings = currentPersona?.integration_settings?.find(
    (item) => item.type === type
  );

  const getPersonaData = async (id: string | undefined) => {
    if (!id) return showError("Can't get agent. Please check embed url");
    const persona = await fetchOne(id, false).then((success) => {
      !success && showError('You need to refresh your link');
      if (success) {
        return success;
      }
    });
    if (persona) {
      setCurrentPersona(persona);
      setReplica(getReplicaById(persona.default_replica_id));
    }
  };

  useEffect(() => {
    if (currentPersona) {
      setReplica(getReplicaById(currentPersona?.default_replica_id));
    }
  }, [replicas, currentPersona]);

  const startCall = () => {
    let key = query.get('key') || undefined;

    if (!key) {
      if (ENVIRONMENT === 'development' || ENVIRONMENT === 'local') {
        key = '3dd8b41b-4e2f-4465-a7fe-b5029f5851e3';
      }
    }
    startConversation(currentPersona, key, language);
  };

  const currentDomain = window.location.hostname;
  if (
    integrationSettings?.domains?.length &&
    !integrationSettings?.domains?.includes(currentDomain)
  )
    return <div>This domain is not in the allowed list.</div>;

  return (
    <div
      className={cn(
        `ag-w-full`,
        integrationSettings?.dark_mode === true
          ? 'ag-bg-neutral-900 ag-text-white'
          : 'ag-bg-white ag-text-neutral-900'
      )}
      style={{ fontFamily: integrationSettings?.font }}
    >
      {!currentPersona ? (
        <div className="ag-w-full ag-h-screen ag-flex ag-items-center ag-justify-center">
          <Loader size={50} />
        </div>
      ) : (
        <div className="ag-h-screen ag-flex ag-flex-col ag-overflow-hidden">
          <div
            className={cn(
              'ag-relative ag-z-50 ag-left-0 ag-top-0 ag-w-full ag-border-b',
              integrationSettings?.dark_mode && 'ag-border-neutral-600'
            )}
          >
            <div className="ag-flex ag-flex-col ag-justify-center ag-items-center ag-p-3 ag-text-xs">
              <div className="ag-font-semibold">
                {integrationSettings?.video_agent_name ||
                  currentPersona.persona_name}
              </div>
              {integrationSettings?.description && (
                <div>{integrationSettings?.description}</div>
              )}
              {integrationSettings?.powered_by !== false && (
                <div
                  className={cn(
                    !integrationSettings?.dark_mode && 'ag-text-neutral-600'
                  )}
                >
                  Powered by yepic ai
                </div>
              )}
            </div>
          </div>

          <>
            {conversationLoading ? (
              <div
                className={cn(
                  'ag-w-full ag-flex ag-items-center ag-justify-center ag-h-screen',
                  integrationSettings?.dark_mode
                    ? 'ag-bg-neutral-900'
                    : 'ag-bg-white'
                )}
              >
                <Loader size={50} />
              </div>
            ) : !currentConversation ? (
              <div className="ag-w-full ag-grow ag-flex ag-justify-center ag-items-center">
                <div className="ag-w-56 lg:ag-w-96 ag-flex ag-flex-col ag-items-center ag-justify-center ag-mx-auto ">
                  <div className="ag-w-56 ag-h-56 ag-rounded-full ag-overflow-hidden ag-mb-7">
                    {replica ? (
                      replica.thumbnail_urls ? (
                        replica.thumbnail_urls[0] && (
                          <img
                            className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top"
                            src={replica?.thumbnail_urls[0]}
                          />
                        )
                      ) : replica.thumbnail_url ? (
                        <img
                          className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top"
                          src={replica?.thumbnail_url}
                        />
                      ) : (
                        <video
                          className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top"
                          src={replica.thumbnail_video_url}
                        />
                      )
                    ) : (
                      <div className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top ag-bg-neutral-100" />
                    )}
                  </div>
                  {integrationSettings?.language_selection && (
                    <SelectInput
                      name="select-language"
                      options={STT_LOCALES_NAMES}
                      value={
                        STT_LOCALES_NAMES.find(
                          (item) => item.toLowerCase() === language
                        )!
                      }
                      onChange={(value) => setLanguage(value.toLowerCase())}
                      optionsWrapperClassName={cn(
                        integrationSettings?.dark_mode
                          ? '!ag-bg-neutral-900 !ag-border-neutral-600'
                          : '!ag-bg-white !ag-border-neutral-200'
                      )}
                      className={cn(
                        'ag-h-7 ag-mb-4',
                        integrationSettings?.dark_mode
                          ? '!ag-bg-neutral-900 !ag-border-neutral-600 !ag-text-white-700'
                          : '!ag-bg-white !ag-border-neutral-200 !ag-text-neutral-700'
                      )}
                      wrapperClassName={cn(
                        integrationSettings?.dark_mode
                          ? '!ag-bg-neutral-900 !ag-text-white-700 !ag-border-neutral-600'
                          : '!ag-bg-white !ag-text-neutral-700 !ag-border-neutral-200'
                      )}
                      icon={<MdOutlineTranslate className="ag-size-6 ag-p-1" />}
                      optionsClassName={cn(
                        'ag-text-sm ag-py-1',
                        integrationSettings?.dark_mode
                          ? 'ag-text-white-700 ag-border-neutral-600 hover:ag-bg-neutral-600/80'
                          : 'ag-text-neutral-700 ag-border-neutral-200  hover:ag-bg-neutral-200/80 dark:hover:ag-bg-[#262626]/80'
                      )}
                      activeClassName={cn(
                        integrationSettings?.dark_mode
                          ? '!ag-bg-[#262626]'
                          : '!ag-bg-neutral-100'
                      )}
                    />
                  )}

                  <FilledButton
                    className="ag-w-full ag-py-2 ag-mb-7"
                    onClick={startCall}
                    style={{
                      backgroundColor:
                        integrationSettings?.more_personalised_colors === true
                          ? integrationSettings?.welcome_button_color
                          : integrationSettings?.main_color
                    }}
                  >
                    {integrationSettings?.button_text || 'Start new chat'}
                  </FilledButton>
                </div>
              </div>
            ) : (
              <div
                className={cn(
                  `ag-grow ag-shrink-0`,
                  integrationSettings?.dark_mode
                    ? 'ag-bg-neutral-900'
                    : 'ag-bg-white'
                )}
              >
                <TavusEmbedConference />
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};
