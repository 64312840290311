import countries from 'shared/lib/countries.json';
import { create } from 'zustand';
import { IVoice } from './types';
import { GenderOptions, Language } from 'shared/model';
import { voiceApi } from '../api';

type Filter = {
  language?: string;
  provider?: string;
  isMultilingual?: boolean;
};

export type TCountry = {
  name: string;
  emoji: string;
  image: string;
};

interface VoiceStore {
  selectedVoice: IVoice | null;
  languages: Language[];
  voices: IVoice[];
  fetchVoices: () => void;
  chooseVoice: (voice: IVoice) => void;
  providers: string[] | null;
  loading: boolean;
  setFilters: (filter: Filter) => void;
  filters: Filter | null;
  filteredVoices: IVoice[] | null;
  filterVoices: () => void;
  genders: GenderOptions[];
  setGenders: (genders: GenderOptions[]) => void;
  changeGender: (gender: GenderOptions) => void;
  voicesError: string | null;
  isMultilingual: boolean;
  updateVoiceStore: (key: string, value: any) => void;
  voiceProviders: string[];
}

export const getDefaultVoiceId = (
  gender: 'Male' | 'Female' | 'Other' = 'Male'
) =>
  gender === 'Female'
    ? 'en-US-JennyMultilingualV2Neural'
    : 'en-US-RyanMultilingualNeural';

export const voiceStore = create<VoiceStore>((set, get) => ({
  voices: [],
  isMultilingual: false,
  voiceProviders: ['Elevenlabs'],
  languages: [],
  updateVoiceStore: (key, value) => {
    set({ [key]: value });
  },
  selectVoiceModal: () => null,
  fetchVoices: async () => {
    set({ loading: true });
    try {
      const voices = await voiceApi.fetchVoices();
      const voicesByLang: IVoice[] = [];

      for (const voice of voices) {
        if (
          !voicesByLang.some((v) => v.language_code === voice.language_code)
        ) {
          voicesByLang.push(voice);
        }
      }

      let languages: Language[] = voicesByLang.map((v: IVoice) => {
        const countryCode = v.language_code.slice(3);
        const country = (countries as { [key: string]: TCountry })[countryCode];
        const isMultilingual = v.is_multilingual;

        return {
          language: v.language,
          country,
          isMultilingual
        };
      });

      const allLangs = {
        language: 'All languages',
        country: null,
        isMultilingual: true
      };

      const multi = {
        language: 'multilingual',
        country: { name: '', emoji: '🌎', image: '' },
        isMultilingual: true
      };

      const engLangs = languages
        .filter((el: any) => el.language.includes('English'))
        .reverse();
      const nonEngLangs = languages?.filter(
        (el: any) => !el.language.includes('English')
      );

      languages = [allLangs, multi, ...engLangs, ...nonEngLangs];

      const voiceProviders = [
        ...new Set([...voices.map((v: any) => v.provider)])
      ].sort();

      set({
        voices,
        languages,
        providers: voiceProviders,
        loading: false
      });
      get().filterVoices();
    } catch (err) {
      console.error(err);
    }
  },
  chooseVoice: (voice) => {
    set((state) => ({ selectedVoice: voice }));
  },
  selectedVoice: null,
  providers: null,
  loading: false,
  filters: {
    language: 'All languages',
    provider: 'All providers',
    isMultilingual: true
  },
  setFilters: (filter) => {
    set((state) => {
      return { filters: filter };
    });
    get().filterVoices();
  },
  filteredVoices: null,
  genders: [],
  setGenders: (genders: GenderOptions[]) => {
    set(() => ({ genders }));
    get().filterVoices();
  },
  changeGender: (value: GenderOptions) => {
    set((state) => {
      let newGenders: GenderOptions[];
      if (state.genders.includes(value)) {
        newGenders = state.genders.filter((g) => g !== value);
      } else {
        newGenders = [...state.genders, value];
      }
      return {
        genders: newGenders
      };
    });
    get().filterVoices();
  },
  filterVoices() {
    const voices = get().voices;
    const filter = get().filters;
    const genders = get().genders;
    if (!voices) return;
    const filtered = voices
      ?.filter((v) =>
        filter?.provider === 'All providers'
          ? true
          : filter?.provider === v.provider
      )
      .filter((v) =>
        filter?.language === 'All languages' ||
        filter?.language === 'multilingual'
          ? true
          : filter?.language === v.language
      )
      .filter((v) =>
        filter?.isMultilingual
          ? filter.isMultilingual === v.is_multilingual
          : true
      )
      .filter((v) => (genders?.length ? genders.includes(v.gender) : true));
    set((state) => ({ filteredVoices: filtered }));
  },
  voicesError: null
}));
